@import '../../styles/_variables.less';
.containerHomeNewsEntry {
  h1 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: 28px;
    margin-bottom: 8px;
    color: @textPrimary;
    word-break: break-word;
    @media (max-width: 767px) {
      margin-top: 28px;
      font-size: 20px;
      line-height: 1.2;
    }
    &.headerTopTitle {
      flex: 1;
      margin: 0;
      @media (max-width: 767px) {
        width: 100%;
      } 
    }
  }
  h2 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
    color: @StrongBlue;
  }
  h3 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: @StrongBlue;
  }
  h4 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: @infoGray;
  }
  p, div {
    font-family: Open Sans, Verdana;
    font-size: 14px !important;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  p {
   margin-top: 10px; 
  }
  li {
    font-family: Open Sans, Verdana;
    font-size: 14px;
    margin-top: 0;
  }
  .date{
    color: #8898aa;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 5px;
  }
  table {
    tr:last-child td {
      /*border-top: 4px solid #ddd;*/
    }
  }
  table {
    caption {
      color: #888;
      font-size: smaller;
      font-style: italic;
      padding: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  table, th, td {
      border: 1px solid #ddd;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
  }
  table tr:nth-child(even) {
      background-color: #f2f2f2;
  }

  table tr:hover {
      background-color: #ddd;
  }
  table thead th {
      padding-top: 12px;
      padding-bottom: 8px;
      text-align: left;
      background-color: #003459;
      color: white;
  }

  table tfoot th {
      text-align: left;
      border-top: 4px solid #ddd;
      background-color: #fff;
  }
}
.HomeNewsEntryHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0 !important;
  @media (max-width: 500px) { 
    display: block;
  }
  .navigation {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    flex: 1;
    .sharePrintBlock {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 0;
      }
      button {
        margin-right: 0;
      } 
    }
  }
}
.blockWithImagesOfSick {
  p {
    margin-bottom: 0px;
  }
  img {
    max-width: 100%;
    height: auto;
    @media (max-width: 767px) { 
      max-width: 100%;
      height: auto;
    }
  }
}

.contentDetailLeft {
  margin-top: 0;
}

.homeRight {
    padding-left: 45px;
    margin-right: -25px;

    @media (max-width: 1199px) {
        margin-top: 46px;
        padding-left: 0;
    }

    @media (max-width: 767px) {
        margin-top: 36px;
        margin-right: 0;
    }
    .ant-card-head-title h1 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        padding: 8px 0 0;
        margin-bottom: 4px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        color: #003459;

        @media (max-width: 767px) {
            padding: 8px 14px 0;
        }
    }
    .ant-card div{
      min-height:2px !important;
    }
    .HomeGraphGrid {
        margin-bottom: 25px;
        padding-right: 25px;

        @media (max-width: 767px) {
            padding-right: 0;
        }
        .antd-card{
          .ant-card-head {
            min-height:2px !important;
          }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin-bottom: 25px;
            margin-right: 25px;

            .customCardContainer .ant-card-head {
                padding: 0 24px;
            }
        }

        .HomeAddNewChartBtn {
            background: transparent;
            margin-bottom: 20px;
            width: 200px
        }
    }
}

.containerHomeNewsEntry {
  .header {
    font-style: normal;
    /*text-transform: capitalize;  */
    padding: 26px 22px;
    bottom: 0;
    width: 100%;
    word-break: break-word;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1;
    text-align: left;
    margin: 0;
    text-shadow: 2px 3px 3px #003459;
    @media (max-width: 767px) {
      font-size: 28px;
      display: block;
      font-weight: 700;
      padding: 22px;
    }
    /*&:after {
      content: "";
      background: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }*/
    mark {
      position: relative;
      z-index: 1;
      background: transparent;
      color: #fff;
      padding: 0;
      word-break: break-word;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
  }
}

.header {
  font-size: 32px;
  color: @textPrimary;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  word-break: break-word;
  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
  }
}
.headerTopTitle {
  font-size: 32px;
  font-weight: 700;
  color: @textPrimary;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  word-break: break-word;
  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
  }
}

.certainCategoryIcon svg {
  font-size: 20px;
  color: @infoGray !important;
}
.searchInput {
  width: 100%;
}
.searchInput input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.searchInputBlock {
  margin-bottom: 24px;
  position: relative;
  max-width: 900px;
  & .suggestionsList {
    position: absolute;
    width: 100%;
    height: 0;
    z-index: 2;
    transition: top 0.7s ease-in-out;
    & > div {
      background: rgba(255, 255, 255, 1) !important;
    }
  }
}
.menuItemBlock span{
  font-weight: 600;
  font-size: 14px;
}
.searchInput input {
  border: none;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: @textPrimary !important;
  font-size: 16px !important;
  .openSans-600-n();
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
  @media (max-width: 767px) {
    height: 44px !important;
    font-size: 14px !important
  }
  &::placeholder {
    .openSans-400-n();
    color: @infoGray !important;
  }
}
.searchInput input::-webkit-input-placeholder {
  .openSans-400-n();
  color: @infoGray !important;
}
.searchInput input::placeholder {
  color: @infoGray !important;
}
.searchInput input:-ms-input-placeholder {
  color: @infoGray !important;
}

.containerEncyclopedia,
.containerEncyclopediaEntry {
  max-width: 900px;
  font-size: 16px;
  color: @textPrimary;
}
p,
div {
  font-size: 16px;
  color: @textPrimary;
}
.cardHeaderBlock {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  & .cardHeader {
    color: @infoGray;
    font-size: 12px;
    .openSans-700-n();
    text-transform: uppercase;
  }
}
.tags {
  width: 500px;
}
.tag {
  height: 40px !important;
}
.paragraphInfo {
  color: @infoGray;
  min-height: 42px;
  .openSans-400-n();
  margin-bottom: 0;
}
.symptomInfoBlock {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.infoUser {
  display: flex;
  height: 70px;
  min-width: 320px;
  flex-shrink: 0;
  & .avatar {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: @lightGray;
      border-radius: 50%;
      margin-right: 16px;
      & svg {
          font-size: 70px;
      }
  }
}
.selectBlock {
  width: 234.55px;
    & .selectBlock1 {
        margin-bottom: 10px;
    }
}
.selectBlock2 {
  display: flex;
}
.selectBlock2 .selectWrap {
    margin-right: 10px;
}
.wrapCustomCard {
    margin-bottom: 25px;
}
.customSelect div{
  font-size: 14px;
}
.customSelect  > div {
    background: @lightGray;
    border: none;
}
.itemCause {
    display: flex;
    margin-bottom: 10px;
    border-radius: 14px;
    &:hover {
        background: @lightGray;
        cursor: pointer;
        & .nameCause {
          text-decoration: underline;
        }
    }
}
.percentBlock {
    width: 59px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 14px;
    font-weight: 700;
    margin-right: 20px;
}
 .nameCause {
    display: flex;
    align-items: center;
    font-weight: 600;
 }
 .header {
  color: @textPrimary;
}
.header .percentBlock + div {
  font-size: 14px;
}
.headerDrawer {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
  & p {
    margin: 0 10px;
  }
  & button {
    box-shadow: none;
    border: none;
    background: @lightGray;
    .openSans-600-n();
    color: @textPrimary;
    height: 24px;
    width: 24px;
    padding: 0;
  }
}
.headerCause {
  margin: 0;
  font-size: 22px;
}
.infoCause {
  margin: 24px 0;
}
.cardWrapperNewMetric {
  cursor: initial;
  padding-bottom: 24px;
  & .infoCausePercent {
    margin-top: 0;
  }
  & .headerInfo,
  & .headerInfoNoSelected {
    color: @textPrimary;
    font-size: 15px;
    .openSans-400-n();
    margin-bottom: 30px;
  }
  & .headerInfoNoSelected {
    background: #f4f6f7;
    padding: 10px 20px;
    border-radius: 14px;
  }
  & .selectedMetrics {
    margin-bottom: 38px;
  }
  & .selectedMetricHeader {
    color: @infoGray;
    font-size: 12px;
    .lato-900-n();
    text-transform: uppercase;
    margin-bottom: 24px;
  }
}
.btnBlock {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid rgb(232, 232, 232);
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 4px 4px;
  button {
    margin-left: 8px;
  }
}
.causeItem {
  display: flex;
  margin-bottom: 10px;
  & > div {
    color: @textPrimary !important;
    font-size: 16px !important;
    .openSans-600-n();
  }
  & svg {
    font-size: 24px;
    margin-right: 12px;
  }
}
.userInfoDrawer {
  margin-bottom: 40px;
}
.percentBlockDrawer {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .symptomInfoBlock {
    flex-direction: column-reverse;
  }
  .tags {
    width: 100%;
  }
}
.infoCategory {
  font-size: 12px;
  font-style: italic;
}
.imgAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.searchList {
  display: flex;
  margin-top: 24px;
  .searchListBtn {
    border: 1px solid @textPrimary;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: 600;
    color: @textPrimary;
    margin-right: 10px;
    background: transparent;
    padding: 0 12px;
    .searchListBtnIcon {
      font-size: 16px;
    }
  }
}
.sharePrintBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 767px) { 
    margin-bottom: 10px;
  }
  /*@media (max-width: 1199px) { 
    display: block;
  }*/
  & button {
    color: @textPrimary;
    background: transparent;
    border: none;
    /*text-transform: capitalize;*/
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    margin-bottom: 5px;
    @media (max-width: 767px) { 
      height: 35px;
      font-size: 12px;
    }
    @media (max-width: 500px) { 
      padding: 0 10px;
    }
    .homeNewslike_btn {
      margin-right: -2px;
    }
    & svg {
      font-size: 20px;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  h6 {
    font-size: 13px;
    margin: 0 0 5px 5px;
    color: #8898aa;
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 400;
  }
}

.newsList {
  display: block;
  max-width: 100%;
  margin-top: 22px;
  .newsListItem {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin-bottom: 25px;
    .newsListItemImage {
      position: relative;
      .contentDetails{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: absolute;
        bottom: 0;
        // padding: 5px 25px;
        width: 100%;

        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 1;
        text-align: left;
        padding: 0 25px 24px;
        margin: 0;
        text-shadow: 2px 3px 3px #003459;
        @media (max-width: 767px) { 
          display: block;
          font-weight: 700;
        }
        .title {
          word-break: break-word;
          margin-bottom: 0;
          font-size: 38px;
          font-weight: 700;          
          @media (max-width: 767px) { 
            font-size: 28px;
            line-height: 30px;
          }
          mark {
            background: transparent;
            color: #fff;
            line-height: 36px;
            position: relative;
            z-index: 1;
            word-break: break-word;
            @media (max-width: 767px) { 
              line-height: 30px;
            }
          }
        }
      }
    }
    img {
      display: block;
      width: 100%;
      /*max-height: 410px;
      object-fit: cover;*/
    }
    .contentDetailRight{
      margin-top: 15px;
      .title {
        font-size: 22px;
        line-height: 32px;
        color: #fff;
        font-weight: bold;
        word-break: break-word;
        @media (max-width: 767px) { 
          font-size: 20px;
          line-height: 30px;
        }
      }
      .icon {
        background: transparent;
        border: none;
        font-size: 14px;
        color: @textPrimary;
        font-weight: 600;
        padding: 0px 10px;
        margin-right: 5px;
        border-radius: 8px;
        box-shadow: none;
        i {
          display: inline-block;
          vertical-align: middle;
          font-size: 24px;
        }
        span {
          margin-left: 4px;
        }
        &:hover {
            background: #F4F6F7;
        }
      }  
    }
    .newsListItemContent {
      padding: 17px 25px 10px;
      > div {
        cursor: pointer;
      }
      .date {
        display: block;
        font-size: 14px;
        color: #75838B;
        line-height: 19px;
        margin-bottom: 7px;
      }
      .title {
        font-size: 22px;
        line-height: 32px;
        color: @textPrimary;
        font-weight: bold;
        word-break: break-word;
        @media (max-width: 767px) { 
          font-size: 20px;
          line-height: 30px;
        }
      }
      .dec {
        font-size: 16px;
        line-height: 26px;
        color: @textPrimary;
        font-weight: 600; 
        > div {
          font-weight: 400;
        }
        a {
          font-weight: 500;
          color: #1890ff;
        }
      }
      .tagsList {
        display: flex;
        .tag {
          display: block;
          border: 1px solid #E1E6E9;
          border-radius: 40px;
          text-transform: none;
          font-size: 13px;
          line-height: 20px;
          color: #75838B;
          font-weight: 400;
          margin-right: 5px;
          height: auto;
          padding: 3px 9px;
          &:hover {
            color: #507196;
            border-color: #507196;
          }
        }
      }
    }
  }
}
.selectedItemchart {
    height: auto;
    flex-direction: column;
    padding: 10px 24px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
    border-radius: 14px;

    @media (max-width: 767px) {
        padding: 10px 15px;
    }

    ;
    background-color: white;
}

.metricInfoBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
}

.nameOfMetric {
  /* font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 8px 0 0;
    margin-bottom: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    color: #003459;*/
 
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #003459;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    
    @media (max-width: 767px) {
        padding: 8px 14px 0;
    }
}

.chartItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 377px;

    & > div {
        width: 100%;
    }
}

.chartContainer {
    margin-bottom: 0px;
}
.nonEnClass{
  color: red;
}
.SingleNewspicture {
  margin-bottom: 0;
  position: relative;
  img {
    width: 100%;
    /*max-height: 780px;
    object-fit: cover;*/
    border-radius: 16px 16px 0px 0px;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 24px;
    margin-top: 24px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #003459;
    /*text-transform: capitalize;  */
    position: absolute;
    bottom: 30px;
    margin: 0 30px;
    /*background: #fff;*/
    line-height: 62px;
    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 8px;
      margin-top: 0;
      display: block;
      line-height: 48px;
    }
    @media (max-width: 375px) {
      margin: 0 15px;
      line-height: 36px;
      bottom: 5px;
    } 
    mark {
      /*line-height: 64px;*/
      color: #003459;
      background: #fff;
    }
  }
}
.SingleNewsContent {
  padding: 15px 22px !important;
}
@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;