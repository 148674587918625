.center{	
  margin: 0 auto;
  text-align: center;
}
.maxSize{
	max-width: 900px;
}
.header, .mainHeader {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #003459;
  /*text-transform: capitalize;  */
}
.main_page{
  @media (max-width: 767px) {
    display: none;
  }
}
h1 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    margin-top: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}
@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;