.sanitizeHtml{
	font-size: 14px;
	h1, h2, h3, h4, h5, h6{
		font-size: 22px;
	    font-weight: 700;
	    color: #003459;
	    margin-bottom: 24px;
	    margin-top: 24px;
	    /*text-transform: capitalize;*/
	    font-family: 'Open Sans', sans-serif;
	    font-style: normal;
	    font-weight: 900;
	}
	h2 {
		font-size: 18px;
	}
	h3 {
		font-size: 17px;
	}
	h4 {
		font-size: 16px;
	}
	h5	{
		font-size: 15px;
	}
	h6{
		font-size: 14px;
	}
	img{
		border-radius : 14px;
	}
	> div {
		font-size: 14px;
	}
	a{
		text-decoration: underline;
		font-weight: 500;
    	font-style: normal;
    	color: #1890ff;
		&:hover, &:focus {
			text-decoration: underline;
			font-weight: 500;
    		font-style: normal;
    		color: #1890ff;
		}
	}
	table {
	    width: 100% !important;
	    margin: 0 -5px;
	    td {
		    width: auto !important;
		    padding: 3px 5px;
		    vertical-align: top;
		    img {
		    	border-radius : 0px;
			    /*height: 13.333vw;*/
			    width: 100%;
		    }
		}
	}
}
@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;