@import '../../styles/_variables.less';
@import '../../styles/_fonts.less';
.signIn {
  .openSans-400-n();
  color: @textPrimary;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 15px;
  .formWrapper {
    width: 100%;
    max-width: 570px;
  }
}
.message {
  font-size: 32px;
  color: @textPrimary;
  .openSans-800-n();
  margin-bottom: 51px;
  @media (max-width: 767px) {
    font-size: 24px !important;
  }
}
.messageInfo {
  color: @textPrimary;
  .openSans-400-n();
  font-size: 14px;
  margin-bottom: 0;
}
label {
  color: @textPrimary !important;
  .openSans-600-n();
  font-size: 16px;
}
input {
  .openSans-600-n();
  color: @textPrimary !important;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: @errors !important;
}
.loginFormForgot {
  .openSans-600-n();
  color: @textPrimary;
  text-decoration: underline;
  font-size: 16px;
}
.forgotPasswordBlock {
  display: flex;
  justify-content: flex-end;
  margin: 9px 2px 0 0;
  & a {
    text-decoration: unset;
    font-size: 14px;
    color: @infoGray;
  }
}
.selectLanguageBlock{
  margin-top: 70px;
  text-align: center;
  @media (max-width: 767px) {
      margin-top: 35px;
  }
  button{
    margin-right: 4px;
    padding: 3px 10px 0px 10px;
    background: transparent;
    border: 1px solid transparent;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      background-color: #fff;
      border: 1px solid #d9d9d9;
    }
  }
}
.spinnerContainer {
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.spinnerContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  margin: auto;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.termsofuselink {
  text-align: center;
  a {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: pointer;
    padding: 16px 21px;
    display: inline-block;
    word-break: break-word;
  }
}
.open.termsofuselink {
  a {
    padding: 16px 10px;
  }
}
.toslink {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  display: inline-block;
  word-break: break-word;
}

.formBox {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 20px 45px 24px 37px;
  @media (max-width: 767px) {
    padding: 20px;
  }
}

.formHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .formHeaderLink {
    font-size: 18px;
    font-weight: 700;
    color: #8898aa;    
    margin-right: 32px;
    @media (max-width: 767px) {
      font-size: 16;
    }
    &.formHeaderLinkActive {
      color: #003459;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.btnBlock {
  margin-top: 27px;
  .loginFormButton {
    width: 235px;
    height: 51px;
    font-size: 16px;
    @media (max-width: 767px) {
      width: 100%;
      height: 44px;
      font-size: 14px;
    }
  }
}


@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;