@textPrimary: #003459;
@errors: #e54d4c;
@infoGray: #8898aa;
@lightGray: #f4f6f7;
@openSans: 'Open Sans', sans-serif;
@lato: 'Lato';
@StrongBlue: #1b88d6;
.openSans-300-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 300;
}
.openSans-400-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 400;
}
.openSans-600-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 600;
}
.openSans-700-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 700;
}
.openSans-800-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 800;
}
.openSans-900-n {
    font-family: @openSans;
    font-style: normal;
    font-weight: 900;
}
.lato-400-n {
    font-family: @lato;
    font-style: normal;
    font-weight: 400;
}
.lato-900-n {
    font-family: @lato;
    font-style: normal;
    font-weight: 900;
}
.lightGrayBtn {
    border-radius: 12px;
    background-color: @lightGray;
    .openSans-600-n();
    color: @textPrimary;
    border: none;
    height: 40px;
}