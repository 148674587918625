@import '~antd/dist/antd.less';
@import './styles/_fonts.less';
@import './styles/_variables.less';

html, body {
  -webkit-overflow-scrolling: touch;
  @media (max-width: 767px) {
     overflow-y: scroll;
  }
}
#root{
  height: 100%;
  #ref {
    height: 100%;
  }
}
body {
  font-family: Open Sans, Verdana;
  background: linear-gradient(to right, #f9fbfd, #fafcfd);
  overflow-y: hidden;
}
.ant-tooltip{
  max-width: 600px;
  .ant-tooltip-inner{
    max-width: 600px;
  }
}
.profilePage{
  .ant-tag {
    text-transform: none;
  }
}
p, div {
  &:focus {
    outline: none;
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
}
.containerTags{
  .ant-row{
    margin-bottom: 0;
  }
  .ant-tag{
    white-space: normal;
    /*display: flex;
    flex-direction: column;
    justify-content: space-between;*/
  }
}
.profile_custom_tooltip {
  @media (max-width: 767px) {
    display: none;
  }
}
.checkedTag {
  transition: all 0.5s;
  border: none !important;
  background: #335889 !important;
  color: @lightGray !important;
  padding: 10px 16px !important;
  cursor: pointer !important;
  text-transform: none;
}
.uncheckedTag {
  padding: 10px 16px !important;
  color: @textPrimary !important;
  border: none !important;
  transition: all 0.5s;
  background: @lightGray !important;
  text-transform: none;
  cursor: pointer !important;
}
.notAdded svg {
  transform: rotateZ(0deg);
  transition: all 1s;
}
.added svg {
  transform: rotateZ(135deg);
  transition: all 0.5s;
  color: @lightGray !important;
}
button {
  text-transform: uppercase;
}

h1 {
  color: #43425d26;
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0px;
}
.logo {
  margin: 24px;
  font-variant-caps: all-petite-caps;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 4px;
  font-weight: normal;
  font-size: large;
  color: white;
}


@media (max-width: 767px) {
  /*
  On small screens, the nav menu spans the full width of the screen.
  Leave a space for it by increasing the padding-top value below.
  */
  body {
    padding-top: 0px;
    #accountForm{
      div{
        &.customCardContainer {
          width:100%;
          .wrapaccount{
            display: block;
            .infoAccount{
              width: 100%;
              margin-top: 0;
            }
          }
        }
      }
    
    }
  }
  .ant-popover-inner{
    max-width: 400px;
  }
}

@media (max-width: 414px) {
  max-width: 300px;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 !important;
}

.tooltipError .ant-tooltip-inner {
  background-color: @errors;
}
.ant-tooltip-inner {
  min-height: 40px;
  min-width: 144px;
  max-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  text-align: left;
}

.tooltipError .ant-tooltip-arrow {
  border-right-color: @errors !important;
}
.account-form .ant-form-explain,
.change-pwd-form .ant-form-explain,
.login-form .ant-form-explain,
.change-form .ant-form-explain,
.forgot-form .ant-form-explain,
.signup-form .ant-form-explain {
  display: none;
}
.account-form .ant-form-item-with-help,
.change-pwd-form .ant-form-item-with-help,
.login-form .ant-form-item-with-help,
.change-form .ant-form-item-with-help,
.forgot-form .ant-form-item-with-help,
.signup-form .ant-form-item-with-help {
  margin-bottom: 24px !important;
}
.login-form .ant-form-item-with-help:last-child,
.login-form .ant-form-item:nth-child(2) {
  margin-bottom: 0px !important;
}
.forgot-form {
  margin-top: 20px;
}
.addMetricForm {
  position: fixed;
  z-index: 1;
  right: 20px;
  top: 40%;
  cursor: initial;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}
.profilePage .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-select-dropdown-menu-item-disabled {
  color: @infoGray !important;
  font-size: 12px !important;
  .lato-900-n() !important;
  text-transform: uppercase;
}
.ant-select-dropdown-menu-item-disabled:hover {
  cursor: unset !important;
}
.ant-select-dropdown-menu-item {
  .openSans-600-n();
  color: @textPrimary;
  border-radius: 14px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  height: 54px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

.infoUserSelect .ant-select-dropdown-menu-item {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
}
.autocompleteSymptomChecker ul,
.autocompleteEncyclopedia ul {
  max-height: calc(100vh - 255px);
  overflow-y: scroll;
}
@media print {
  button,
  aside,
  .ant-layout-sider {
    display: none !important;
  }
  .ant-layout {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
    page-break-after: always !important;
  }
}
.ant-tag {
  background-color: transparent;
  border-color: @textPrimary;
  color: @textPrimary;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  /*text-transform: capitalize;*/
  margin-bottom: 10px;
  @media (max-width: 767px){ 
    font-size: 14px;
  }
}
.ant-tag svg {
  font-size: 16px;
  color: @textPrimary;
  margin-left: 5px;
}

.ant-select-selection-selected-value {
  font-weight: 600;
}
.ant-select {
  width: 100%;
}
.ant-select svg {
  font-weight: 600;
  color: @textPrimary;
}
.ant-drawer-mask {
  // CUSTOMIZATION
  opacity: 0.8 !important;
  background-color: rgba(0, 0, 0, 1);
  animation: customAntdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
}
@keyframes customAntdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
.dropDownShareMenu {
  padding: 0;
}
.dropDownShareMenu li:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dropDownShareMenu li:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sisuWrapper,
.profilePage,
.wrapperModalChangePwd {
  & label {
    color: @textPrimary !important;
    .openSans-600-n();
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  & input {
    height: 50px !important;
    font-size: 16px !important;
    .openSans-600-n();
    color: @textPrimary !important;
    @media (max-width: 767px) {
      height: 40px !important;
      font-size: 14px !important;
    }
  }
  .ant-form-item {
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  .ant-select-selection-selected-value {
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
}
.sisuWrapper {
  input {
    margin-top: -3px;
    @media (min-width: 767px) {
      height: 58px !important;
    }
  }
}
.ant-select-dropdown-menu-item {
  @media (max-width: 767px) {
    font-size: 14px !important;
    height: 44px;
  }
}
#profileBasicInformationForm{
  .ant-input-number{
    height: 50px !important;
    font-size: 16px !important;
    .openSans-600-n();
    color: @textPrimary !important;
  }
  label{
    & .ant-form-item-label{
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  

}

#accountForm, .profileBasicInformationForm{

  .ant-select-selection--single{
    height: 50px;
    padding-top: 10px;
    @media (max-width: 767px) {
      height: 40px;
      padding-top: 5px;
    }
  }
}
.profilePage .therapiesCollapse {
  & input {
    height: 27px !important;
    font-size: 20px !important;
    padding: 0 !important;
    border: none;
    border-radius: 7px;
    max-width: 225px;
  }
  & .doseMed {
    & input {
      font-size: 16px !important;
    }
  }
  & .inputNewCondition {
    font-size: 16px !important;
  }
}
.colorPickerDropdown {
  & ul {
    max-height: 702px;
  }
  & li {
    border-radius: 0;
  }
}
.colorPickerSelect {
  height: 50px;
  @media (max-width: 767px){ 
    height: 40px;
  }
  & > div {
    height: 50px;
    background: transparent;
    border: unset;
    @media (max-width: 767px){ 
      height: 40px;
    }
  }
  & div {
    color: white;
  }
  & .ant-select-selection-selected-value {
    & i {
      display: none;
    }
  }
}

.ant-calendar-input-wrap {
  display: none;
}

.ant-calendar {
  margin-left: -10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-heigth: 767px) {
  .colorPickerDropdown {
    & ul {
      max-height: 400px !important;
    }
  }
}
.metricInfo > div {
  height: 40px;
  color: @textPrimary;
  .openSans-600-n;
  font-size: 14px;
  padding: 10px 40px 10px 0;
  border-radius: 14px;
  align-items: center;
  display: inline-flex;
  background-color: @lightGray;
  border: none;
  width: auto;
  min-width: 230px;
  width: 100%;
  & > div {
    width: 100%;
    & > div {
      display: flex !important;
      align-items: center;
      max-width: 100%;
      & span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100% - 19px);
      }
    }
  }
}
.metricInfoDropdown .ant-select-dropdown-menu-item {
  justify-content: flex-start;
}
.tabsChart.ant-tabs.ant-tabs-top .ant-tabs-top-bar .ant-tabs-tab-active {
  font-weight: 700 !important;
}
.tabsChart.ant-tabs.ant-tabs-card .ant-tabs-nav {
  width: 100%;
}

.tabsChart.ant-tabs.ant-tabs-top .ant-tabs-top-bar .ant-tabs-tab {
  width: calc(50% - 2px);
  text-align: center;
  font-size: 14px;
}
.recharts-legend-item {
  display: flex !important;
  align-items: center;
}
.recharts-legend-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 19px);
}
.profilePage {
  .customCardContainer {
    box-shadow: none;
  }
  .ProfileBasicInformation,
  .ProfileSettings,
  .ProfileGenetics,
  .ProfileConditionsAndTherapies,
  .ProfileFamilyHistory,
  .ProfileChronicConditions,
  .ProfileTherapyAndVaccines,
  .Activity {
    & > .customCardContainer {
      width: 100%;
      background: #f0f2f5 !important;
      border: none !important;
      padding: 0 !important;
      .customCardContainer {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      & > div {
        padding: 0;
      }
    }
    .wrappAccountActivity{
      width: 100%;
      padding:0;
    }
  }
  .ant-tabs-tab {
    font-weight: 700;
    background: transparent !important;
    border: none !important;
    color: @infoGray !important;
  }
  .ant-tabs-tab-active.ant-tabs-tab {
    color: @textPrimary !important;
  }
  .ant-select-selection{
    i{
      &.anticon{
        margin-right: 10px;
      }
    }
  }
}
.languages-select{
  .ant-select-dropdown-menu-item{
    padding-left: 10px;
    padding-right: 10px;
    justify-content: left;
    &:first-child {
      &:after {
        content: "";
        width: 97%;
        height: 1px;
        background: #d9d9d9;
        position: absolute;
        left: 10px;
        bottom: 0;
      }
    }
    i{
      margin-right: 12px;
    }
  }
}

.mainProfileBasicInformation {
  .monthInput {
    @media (min-width: 768px) and (max-width: 1199px){ 
      width: 100%;
    }
    .ant-select-dropdown {
      @media (max-width: 767px) {
        width: 140px !important;
      }
    }
  }
  .ageDayInput {
    @media (min-width: 768px) and (max-width: 1199px){ 
      width: 50%;
    }
    .ant-select-dropdown {
      @media (max-width: 767px) {
        width: 55px !important;
      }
    }
  }
  .YearInput {
    @media (min-width: 768px) and (max-width: 1199px){ 
      width: 50%;
    }
    .ant-select-dropdown {
      left: auto !important;
      right: 0;
    }
  }
  .ant-select-arrow {
    @media (max-width: 767px){ 
      right: 0px;
    }
  }
  .ant-select-selection__rendered {
    @media (max-width: 767px){ 
      margin-left: 9px;
    }
  }
}


.metricsTimeLineSection {
  .ant-empty-normal {
      margin: 15px 0;
  }
  .no-data-table {
    &.detailViewTable {
      .ant-table-scroll {
          overflow: auto !important;
          overflow-x: hidden !important;
      }
      .ant-table-header {
        overflow: auto !important;
      }
       thead tr th {
          padding: 5px 15px;
      }
    }
  }
}

.profileAvatarIcon {
  background-color: @lightGray;
  border-radius: 50%;
  max-width: 150px;
  width: 100%;
  height: 150px;
  background-size: cover;
  margin: 0 auto;
  @media (max-width: 1250px){ 
    max-width: 140px;
    height: 140px;
  }
  @media (max-width: 767px){ 
    max-width: 100px;
    height: 100px;
  }
  svg {
    max-width: 150px !important;
    width: 100%;
    height: 100% !important;
    @media (max-width: 767px){ 
      max-width: 100px important; 
    }
  }
}
.ant-upload-drag-icon {
  margin-bottom: 20px;
  max-width: 150px;
  max-height: 150px;
  margin: auto;
}
/* admin tool upload */
.adminTooluploadBlock {
  .ant-upload.ant-upload-drag {
    .ant-upload-drag-container {
        display: flex;
        align-items: center;
        padding: 13.5px 20px;
        @media (max-width: 500px){
          padding: 13.5px 15px;
          display: block;
        }
        i.anticon-plus {
          width: 84px;
          height: 105px;
          background: #fff;
          text-align: center;
          border-radius: 14px;
          border: 1px solid #F4F6F7;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 500px){
            margin: auto;
          }
          svg {
            font-size: 24px;
            color: #75838B;
            margin-bottom: 0;
          }
      }
      .ant-upload-drag-icon {
        &.mainadminToolItemImage {
          width: auto;
          margin: 0;
          @media (max-width: 500px){
            margin: auto;
          }
        }
      }
    }
  }
}

.ant-upload-drag-container .ant-progress-text {
  display: none;
}
.ant-upload-list-item {
  & .ant-progress-text, & i {
    display: none !important;
  }
  & .ant-upload-list-item-info {
    display: none !important;
  }
  & .ant-upload-list-item-progress {
    padding-left: 0 !important;
    & .ant-progress-bg {
      background-color:  #52c41a !important;
    }
  }
}
.ant-upload-list-item.ant-upload-list-item-error {
  display: none !important;
}
#accountForm{
  div{
    &.customCardContainer {
      width:100%;
      .wrapaccount{
        display: flex;
        .infoAccount{
          width: 70%;
          margin-top: -15px;
        }
      }
    }
  }

}
.cardLabelProfileSetting{
  color: @infoGray;
  font-size: 12px;
  .openSans-700-n();
  text-transform: uppercase;
  margin-bottom: 16px;
}
.cardHeaderProfile {
  color: @infoGray;
  font-size: 12px;
  .openSans-700-n();
  text-transform: uppercase;
  margin-bottom: 16px;
}
.has-error .ant-form-explain {
  padding-left: 5px;
}

.therapiesCollapse .ant-collapse-item-disabled > div {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: default !important;
}
.conditionsSelect > div > div {
  border: none;
  padding-left: 10px;
  & > div {
    margin: 0;
  }
} 


.ReactCrop__crop-selection {
  border-radius: 50%;
}
.adminToolCropper{
  .ReactCrop__crop-selection {
    border-radius: 0%;
  }
}
.profile-cropper{
  .ReactCrop__image {
    max-height: 300px !important;
  }  
  .ReactCrop {
    border-radius: 14px;
  }
}
.wrapperModal {
  .ant-modal-header {
    .ant-modal-title {
      color: @textPrimary;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      /*text-transform: capitalize;*/
    }
  }
}
.wrapperModalChangePwd {
  .ant-modal-body {
    padding: 0;
  }
}
.change-pwd-form {
  display: block;
  padding: 0 !important;
  & > div:first-child {
    padding: 24px !important;
  }
  & > div:nth-child(2) {
    width: 100% !important;
  }
}
.infoUserSelect {
  width: auto !important;
  .ant-select-dropdown-menu {
    max-height: unset;
  }
}

/* sss */

/*new design*/
.uploadSection {
  .ant-upload-drag{
    height: auto;
    background-color: transparent;
    background: transparent;
    span.ant-upload-btn{
      padding: 0;
    }
  }
  .ant-upload-list {
    .ant-upload-list-item {
      height: auto;
      padding: 0 0;
      .ant-upload-list-item-info {
        display: block !important;
        padding: 9px 10px;
        .ant-upload-list-item-name {
          font-size: 14px;
        }
      }
      i {
        display: block !important;
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        &.anticon-close {
          right: 10px;
        }
      }
      &:hover {
        .ant-upload-list-item-info {
          background-color: #335889;
        }
        .ant-upload-list-item-name {
          color: #fff;
        }
        i {
          color: #fff;
        }
      }
    }
  }
  .ant-upload {
    > input {
      &:focus {
        + .uploadContent {
            border-color: #335889;
        }
      }
    }
  }
}
.mainUploadSection {
    display: flex;
    .uploadBox {
      width: 100%;
    }
    @media (max-width: 767px) { 
      display: block;
    }
    .uploadContent {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        /*border: 1px dashed #d9d9d9;
        border-radius: 14px;*/
        padding: 13.5px 20px;
        position: relative;
        max-width: 100%;
        text-align: left;
        cursor: pointer;
        .ant-btn {
            width: 84px;
            height: 105px;
            background: #fff;
            text-align: center;
            border-radius: 14px;
            border: 1px solid #F4F6F7;
            padding: 0;
            display: block;
            i {
                font-size: 24px;
                color: #75838B;
                margin-bottom: 0;
                display: block;
                + span {
                    display: none;
                }
            }
            .uploadText {
                font-size: 14px;
                color: #75838B;
                font-weight: 100;
                /*text-transform: capitalize;*/
            }
        }
        p {
            padding: 0 30px;
            margin: 0 0;
            line-height: normal;
            font-weight: 100;
            color: #75838B;
            width: calc(100% - 84px);
            @media (max-width: 1199px){ 
              padding: 0 15px 0 30px;
            }
            @media (max-width: 992px){ 
              padding: 0 0 0 15px;
            }
        }
    }
  .generateBtn {
    width: auto;
    margin-left: 30px;
    @media (max-width: 767px) { 
      margin-left: 0;
      margin-top: 15px;
    }
    button{
      &.ant-btn{
        &.ant-btn-primary {
            height: 40px;
            font-size: 14px;
            font-weight: 700;
            padding: 5px 15px;
            @media (max-width: 767px) { 
              height: 35px;
              font-size: 12px;
           }
        }
      }
    }
  }
}
.adminToolMobileView {
  box-shadow: 0 1px 3px rgba(0,0,0,.15);
  border-radius: 14px;
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  .adminToolMobileView_head {
    position: relative;
    margin-bottom: 10px;
    label {
      span {
        &.ant-checkbox.ant-checkbox-indeterminate {
          span{
            &.ant-checkbox-inner{
              &:after {
                content: "";
                left: 51%;
                display: block;
              }
            }
          }
        }
      }
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        &:after {
          left: 31%;
          display: block;
        }
      }
    }
    > span {
      color: #75838b;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      margin-left: 10px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    i {
      font-size: 26px;
      transform: rotate(90deg);
      color: #75838b;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  h4 {
    color: #003459;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
  p {
    color: #75838b;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    word-break: break-all;
    &.responsiveLanguage {
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #003459;
      svg {
        width: 20px;
      }
    }
  }
}
.documentDetails {
  padding-top: 60px;  
  @media (max-width: 767px) { 
    padding-top: 30px;  
  }
  .adminToolsdocumentTabs {
    .documentTabs {
      .ant-spin-container {
        justify-content: space-between;
      }
      li {
        &.ant-list-item {
          @media (max-width: 414px) { 
            margin: 0 5px;
          }
          span{
            @media (max-width: 414px) { 
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .documentTabs{
    .ant-spin-container{
      display: flex;
    }
    li{
      &.ant-list-item {
        border: none !important;
        display: inline-block;
        margin: 0 15px;
        cursor: pointer;
        @media (max-width: 767px) { 
          margin: 0 10px;
        }
        span{
          font-weight: 700;
          font-size: 16px;
          /*color: #6D7278;*/
          color: #75838B;
          @media (max-width: 767px) { 
            font-size: 14px;
          }
          &.active {
            color: #003459;
          }
        }
      }
    }
  }
  .searchBox{
    margin: 10px 0;
    @media (max-width: 767px) { 
      margin: 10px 0 20px;
    }
    input{
      color: @textPrimary !important;
      font-size: 16px !important;
      .openSans-600-n();
      &.ant-input {
        width: 100%;
        height: 54px;
        background: #fff;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
        box-shadow: 0 2px 25px rgba(0,0,0,.0956465);
        border: none;
        padding: 0 15px 0 40px;
        @media (max-width: 767px) { 
          height: 44px;
          font-size: 14px !important;
        }
      }
      &:focus {
          box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
      }
      &::-webkit-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
      &:-ms-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
    }
    i{
      &.anticon{
        &.anticon-search{
          &.ant-input-search-icon {
            svg {
                font-size: 20px;
                color: #8898aa !important;
                @media (max-width: 767px) {
                  font-size: 18px;
                }
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        left: 12px;
        right: unset;
      }
    }
  }
  .userDetailsTable{
    overflow-x: auto;
    padding: 0 2.5px;
    table {
      padding: 0 5px;
    }
    span{
      &.ant-checkbox.ant-checkbox-indeterminate {
        span{
          &.ant-checkbox-inner{
            &:after {
                content: "";
                left: 51%;
                display: block;
            }
          }
        }
      }
    }
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      &:after {
        left: 31%;
        display: block;
      }
    }
    .ant-table-tbody {
      > tr{
        td {
          .date {
            white-space: nowrap;
          }
        }
        &:hover{
          &.ant-table-row-selected {
            > td{
              background: #fff;
            }
          }
          &:not(.ant-table-expanded-row){
            &:not(.ant-table-row-selected) {
              > td {
                  background: #fff;
              }
            }
          }
        }
      }
    }
    table {
      border-collapse: separate;
      border-spacing: 0px 20px;
    }
    thead {
      tr {
        th{
          background: transparent;
          padding: 0 15px;
          border: none;
          span {
              font-size: 12px;
              color: #8898AA;
              font-weight: bold;
              text-transform: uppercase;
              display: inline-block;
              cursor: pointer;
              &.ant-checkbox {
                  width: 24px;
                  height: 24px;
              }
            i{
              &.anticon {
                  color: #003459;
                  margin-left: 4px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        td {
            background: #fff;
            padding: 15px;
            border: none;
            color: #75838B;
            font-size: 14px;
            position: relative;
            font-weight: 400;
            &:first-child {
              border-radius: 14px 0 0 14px;
            }
            &:last-child {
              border-radius: 0 14px 14px 0;
            }
            span{
              &.nameContent{
                 display: inline-block;
                 padding: 0 40px 0 0;
              }
              h4 {
                  color: #003459;
                  font-size: 18px;
                  font-weight: 400;
                  margin: 0;
                  cursor: pointer;
                  &.no-cursor{
                    cursor: initial;
                  }
                  @media (max-width: 767px) {
                    font-size: 16px;
                  }
              }
              p {
                color: #75838B;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
              i{
                &.anticon-more {
                    font-size: 26px;
                    transform: rotate(90deg);
                    color: #75838B;
                    cursor: pointer;
                }
              }
            }
            .view_delete_popup {
              position: absolute;
              right: 20px;
              top: 0;
              bottom: 0;
              display: flex;
              align-items: center;
            }
        }
      }
    }
  }
}



/* admin tool */
.tabsection {
  .ant-tabs {
    overflow: initial;
  }
}
.adminToolDetails {
  padding-top: 24px;
  .adminToolcardHeader {
    /* background: #fff; */
    padding: 10px 15px;
    margin: 0;
    border-radius: 4px;
    color: #8898aa;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .userDetailsTable {
    thead {
      tr {
        th {
          span {
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td  {
          .title_description {
            display: inline-block;
            padding: 0 45px 0 0;
          }
          span {
            &.date_time {
              white-space: nowrap;
            }
            &.language {
              text-transform: uppercase;
              color: #003459;
              display: flex;
              height: 15px;
              align-items: center;
              svg {
                  width: 20px;
              }
            }
            &.title {
              color: #003459;
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              white-space: normal;
              @media (max-width: 1199px) { 
                white-space: normal;
              }
            }
            &.content_description {
              display: block;
              color: #75838b;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .documentTabs{
    .ant-spin-container{
      display: flex;
    }
    li{
      &.ant-list-item {
        border: none !important;
        display: inline-block;
        margin: 0 15px;
        cursor: pointer;
        @media (max-width: 767px) { 
          margin: 0 10px;
        }
        span{
          font-weight: 700;
          font-size: 16px;
          /*color: #6D7278;*/
          color: #75838B;
          @media (max-width: 767px) { 
            font-size: 14px;
          }
          &.active {
            color: #003459;
          }
        }
      }
    }
  }
  .searchBox{
    margin: 10px 0;
    @media (max-width: 767px) { 
      margin: 10px 0 20px;
    }
    input{
      color: @textPrimary !important;
      font-size: 16px !important;
      .openSans-600-n();
      &.ant-input {
        width: 100%;
        height: 54px;
        background: #fff;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
        box-shadow: 0 2px 25px rgba(0,0,0,.0956465);
        border: none;
        padding: 0 15px 0 40px;
        @media (max-width: 767px) { 
          height: 44px;
          font-size: 14px !important;
        }
      }
      &:focus {
          box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
      }
      &::-webkit-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
      &:-ms-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
    }
    i{
      &.anticon{
        &.anticon-search{
          &.ant-input-search-icon {
            svg {
                font-size: 20px;
                color: #8898aa !important;
                @media (max-width: 767px) {
                  font-size: 18px;
                }
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        left: 12px;
        right: unset;
      }
    }
  }
  .userDetailsTable{
    span{
      &.ant-checkbox.ant-checkbox-indeterminate {
        span{
          &.ant-checkbox-inner{
            &:after {
                content: "";
                left: 51%;
                display: block;
            }
          }
        }
      }
    }
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      &:after {
        left: 31%;
        display: block;
      }
    }
    .ant-table-tbody {
      > tr{
        &:hover{
          &.ant-table-row-selected {
            > td{
              background: #fff;
            }
          }
          &:not(.ant-table-expanded-row){
            &:not(.ant-table-row-selected) {
              > td {
                  background: #fff;
              }
            }
          }
        }
      }
    }
    table {
      border-collapse: separate;
      border-spacing: 0px 20px;
      @media (max-width: 479px) {
        overflow: hidden;
      }
    }
    thead {
      tr {
        th{
          background: transparent;
          padding: 0 15px;
          border: none;
          &.head_title_description {
            width: 100%;
          }
          span {
              font-size: 12px;
              color: #8898AA;
              font-weight: bold;
              text-transform: uppercase;
              display: inline-block;
              cursor: pointer;
              &.ant-checkbox {
                  width: 24px;
                  height: 24px;
              }
            i{
              &.anticon {
                  color: #003459;
                  margin-left: 4px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        td {
            background: #fff;
            padding: 15px;
            border: none;
            color: #75838B;
            font-size: 14px;
            position: relative;
            font-weight: 400;
            &:first-child {
              border-radius: 14px 0 0 14px;
            }
            &:last-child {
              border-radius: 0 14px 14px 0;
            }
            span{
              &.nameContent{
                 display: inline-block;
                 padding: 0 40px 0 0;
              }
              h4 {
                  color: #003459;
                  font-size: 18px;
                  font-weight: 400;
                  margin: 0;
                  cursor: pointer;
                  &.no-cursor{
                    cursor: initial;
                  }
                  @media (max-width: 767px) {
                    font-size: 16px;
                  }
              }
              p {
                color: #75838B;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
              i{
                &.anticon-more {
                    font-size: 26px;
                    transform: rotate(90deg);
                    color: #75838B;
                    cursor: pointer;
                }
              }
            }
            .delete_popup {
              position: absolute;
              right: 20px;
              bottom: 0;
              top: 0;
              display: flex;
              align-items: center;
            }
        }
      }
    }
  }
}

/* sss */
.ant-dropdown {
  .viewDeleteDropDown {
    &.adminToolDropDown {
      li {
          border-radius: 10px !important;
      }
    }
  }
}
/*Document view edit drop-down css*/
.ant-dropdown .viewDeleteDropDown {
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-dropdown .viewDeleteDropDown li{
  padding: 5px 20px;
  border-radius: 10px;
  margin:0;
}
.viewDeleteDropDown li:hover{
  background-color: #335889;
  color: #fff;
}
.viewDeleteDropDown li:hover a, .viewDeleteDropDown li:hover span, .viewDeleteDropDown li:hover i{
  color: #fff;
}
.viewDeleteDropDown li a, .viewDeleteDropDown li span{
  color: @textPrimary;
}
.viewDeleteDropDown li a i, .viewDeleteDropDown li span i{
  padding: 0 5px 0 0;
  color: @textPrimary;
}
.ant-dropdown .viewDeleteDropDown li:first-child{
  border-radius: 10px 10px 0px 0px;
  margin: 0;
  height: auto;
  line-height: 2;
}
.ant-dropdown .viewDeleteDropDown li:last-child {
  border-radius: 0px 0px 10px 10px;
  margin: 0;
  height: auto;
  line-height: 2;
}

.uploadDocumentModalForm .ant-select-selection__placeholder , .uploadDocumentModalForm .ant-select-selection-selected-value , .uploadDocumentModalForm input , .uploadDocumentModalForm textarea, .uploadDocumentModalForm label{
  font-size: 16px !important;
  font-weight: 600 !important;
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
}
.uploadDocumentModalForm input, .uploadDocumentModalForm .ant-select-selection--single{
  height: 50px;
  background-color: transparent;
  box-shadow: none;
  @media (max-width: 767px) {
    height: 40px; 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}


.uploadDocumentModalForm  .ant-select {
  border-radius: 15px;
}
.uploadDocumentModalForm {
  p.fileSize-text {
    text-align: right;
    padding: 0 10px 0 0;
    margin: -20px 0 0;
    font-size: 12px !important;
    color: #75838B; 
  }
}
.uploadDocumentModalForm .ant-select-selection__rendered{
  line-height: 48px;
  @media (max-width: 767px) {
    line-height: 38px;
  }
}
.uploadDocumentModalForm {
  .ant-form-item{
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    &.name_feild {
      @media (max-width: 767px) {
        margin-bottom: 22px;
      }
    }
  }
}

.uploadDocumentModalForm textarea{
  color: @textPrimary;
}
.uploadDocumentModalForm .ant-calendar-picker{
  width: 100%;
}

.selectItem {
  border-radius: 0;
}


.ant-btn {
  @media (max-width: 767px) {
    height: 32px;
    font-size: 12px;
  }
}

.read-more-collapse {
  .ant-collapse {
    border: none;
    background: none;
    .ant-collapse-item {
      border-radius: 0;
      border: none;
      .ant-collapse-header {
        border-radius: 0;
        padding: 0 0 0 20px;
        color: #68BE68;
        i {
          left: 0;
        }
      }
      .ant-collapse-content {
        border-radius: 0;
        border: none;
        padding-top: 10px;
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
}

li{
  &.ant-select-dropdown-menu-item {
      white-space: inherit;
  }
}

.newsAndAlertsPage {
  .ant-tabs {
    overflow: visible;
    .ant-tabs-tab {
      font-weight: 700;
      background: transparent !important;
      border: none !important;
      color: @infoGray !important;
      padding: 0 !important;
      min-width: 80px;
      &.ant-tabs-tab-active {
        color: @textPrimary !important;
      }  
    }   
  }  
  .count {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: #335889;
    color: #fff;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    margin-left: 8px;
    @media (max-width: 767px) { 
      width: 26px;
      height: 26px;
      font-size: 12px;
      line-height: 26px;
    }
  }
  .searchBox{
    margin: 10px 0;
    input{
      color: @textPrimary !important;
      font-size: 16px !important;
      .openSans-600-n();
      &.ant-input {
        width: 100%;
        height: 54px;
        background: #fff;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
        box-shadow: 0 2px 25px rgba(0,0,0,.0956465);
        border: none;
        padding: 0 15px 0 40px;
        @media (max-width: 767px) { 
          height: 44px;
          font-size: 14px !important;
        }
      }
      &:focus {
          box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
      }
      &::-webkit-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
      &:-ms-input-placeholder {
        .openSans-400-n();
        color: @infoGray !important;
      }
    }
    i{
      &.anticon{
        &.anticon-search{
          &.ant-input-search-icon {
            svg {
                font-size: 20px;
                color: #8898aa !important;
                @media (max-width: 767px) { 
                  font-size: 18px;
                }
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        left: 12px;
        right: unset;
      }
    }
  }
}

.metricsTimeLineChartSection {
    @media (max-width: 767px) {
        margin-top: 15px;
        font-size: 16px !important;
    }

    .ant-card-body {
        padding: 24px;
        zoom: 1;
        @media (max-width: 767px) {
          padding: 24px 10px;
        }
    }

}
.detailViewTable {
    .ant-table-placeholder {
        background-color: transparent;
        border: 0;
    }
    /* metrics table scroll css */
    .ant-table {
        overflow: auto !important;
    }

    .ant-table-scroll {
        overflow: unset !important;
        overflow-x: unset !important;
    }

    .ant-table-header {
        overflow: unset !important;
    }

    .ant-table-body {
        overflow: unset !important;
        margin: 0;
    }
}
.no-data-table {
  &.detailViewTable {
    .ant-table-placeholder {
      background-color: transparent;
  }
    .ant-table-header {
      overflow: auto !important;
    }
     thead tr th {
        padding: 0 15px 0;
    }
  }
}
.metricsTimeLineSection{
  margin-top:-24px;
  .detailViewTable{
    .ant-table-fixed-header .ant-table-content .ant-table-scroll .ant-table-body{
      background:transparent;
      position: relative;
    }
    span{
      &.ant-checkbox.ant-checkbox-indeterminate {
        span{
          &.ant-checkbox-inner{
            &:after {
                content: "";
                left: 51%;
                display: block;
            }
          }
        }
      }
    }
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      &:after {
        left: 31%;
        display: block;
      }
    }
    .ant-table-body {
      margin-top: 15px;
      /*@media (max-width: 767px) {
        margin-top: 0;
      }*/
    }
    .ant-table-row:hover .editable-row{
      display: none;
      background-color: transparent;
    }
    .ant-table-tbody {
      > tr{
        &:hover{
          &.ant-table-row-selected {
            > td{
              background-color: transparent;
              padding:0px 15px;
            }
          }
          &:not(.ant-table-expanded-row){
            &:not(.ant-table-row-selected) {
              > td {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    table {
      border-collapse: separate;
      border-spacing: 0;
      padding: 0 5px;
    }
    thead {
      tr {
        th{
          padding: 3.5px 15px 3.5px;
          border: none;
          span {
              font-size: 12px;
              color: #8898AA;
              font-weight: bold;
              text-transform: uppercase;
              display: inline-block;
              cursor: pointer;
              &.ant-checkbox {
                  width: 24px;
                  height: 24px;
              }
            i{
              &.anticon {
                  color: #003459;
                  margin-left: 4px;
              }
            }
          }
          &:last-child {
            width: 60px;
          }
        }
      }
    }
    tbody {
      tr {
       // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
       // border-radius: 14px;
        @media (max-width: 767px) {
          box-shadow: none;
        }
        
        td {
            padding:5px 15px;
            border: none;
            color: #75838B;
            font-size: 14px;
            position: relative;
            font-weight: 400;
            white-space: normal;
            vertical-align: top;
            &:first-child {
              //border-radius: 14px 0 0 14px;
              @media (max-width: 767px) {
                border-radius: 0;
              }
            }
            &:last-child {
              //border-radius: 0 14px 14px 0;
              width: 60px;
              @media (max-width: 1400px) {
                /*display: inline-block;*/
                padding: 9px 0;
              }
            }
            span{
              &.nameContent{
                 display: inline-block;
                 padding: 0 35px 0 0;
              }
              h4 {
                  color: #003459;
                  font-size: 18px;
                  font-weight: 400;
                  margin: 0;
                  cursor: pointer;
                  &.no-cursor{
                    cursor: initial;
                  }
              }
              p {
                color: #75838B;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
              i{
                &.anticon-more {
                    position: absolute;
                    right: 20px;
                    height: 20px;
                    top: calc(125px - 62.5px);
                    font-size: 26px;
                    transform: rotate(90deg);
                    color: #75838B;
                    cursor: pointer;
                }
              }
            }
        }
      }
    }
    .editable-row .ant-form-explain {
      position: absolute;
      font-size: 12px;
      margin-top: -4px;
    }
    .editable-cell-value-wrap {
      //padding: 0px 12px;
      cursor: pointer;
      /*display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;*/
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
    .editable-row:hover .editable-cell-value-wrap {
      box-sizing: border-box;
      margin: 0px;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      width: 100%;
      min-height: 30px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 1.5;
      background-image: none;
      border: 1px solid #507196;
      border-radius: 14px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .ant-row::before{
      display: table; 
      content: none;          
    }
    .ant-row::after{
      display: table;  
      content: none;          
    }
    .ant-form-item-control::before{
      display: table;  
      content: none;    
    }
    .ant-form-item-control::after{
      display: table;  
      content: none;    
    }
  }
  .action-btn{
    .lightGrayBtn();
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    margin-left: 8px;
    padding: 0;
    box-shadow: unset;
    background-color: transparent;
  }
}
.metricsChartSection{
  .ant-card-body {
    padding: 24px;
    zoom: 1;
    @media (max-width: 767px) {
      padding: 24px 10px;
    }
}
}
.metricsTabs {
  .ant-tabs {
    overflow: visible;
    .ant-tabs-tab {
      font-weight: 700;
      background: transparent !important;
      border: none !important;
      color: @infoGray !important;
      padding: 0 16px !important;
      min-width: 80px;
      &.ant-tabs-tab-active {
        color: @textPrimary !important;
      }  
    }   
  }
}

.metrics-ant-tooltip-inner {
  max-width: 600px;
  display: flex;
  justify-content: left;
  align-items: left;
  border-radius: 6px;
  text-align: left;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  p {
    color: #fff;
  }
  div {
    color: #fff;
  }
}

.autocompleteEncyclopedia {
  max-width: 900px;
  border-radius: 14px;
  overflow: hidden;
  max-height: calc(100vh - 255px);
  @media (max-width: 1270px) {
    max-width: calc(100% - 365px);
  }
  .ant-select-dropdown-menu-item {
    > span {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:first-child {
        border-radius: 14px 14px 0 0;
    }
  }
}

.symptomInfoBlock {
  .ant-row{
    width: 100%;
  }
}

.metricsNewChartDrawer, .metricsNewEntryDrawer, .drawerComponentSymptomChecker, .metricsNewEntryDrawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 485px;
    @media (max-width: 767px) {
      max-width: 320px;
    }
  }
}

.metricSetting {
  .ant-drawer-wrapper-body {
    overflow: hidden !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 400px;
    @media (max-width: 767px) {
      max-width: 320px;
    }
  }
}

.metricsNewEntryDrawer {
  .MainDatePickerDateContainer {
    @media (max-width: 767px) {
      display: block;
    }
    .InnerDatePickerDateContainer {
        @media (max-width: 767px) {
          width: 100%;
        }
    }
  }
  span {
    &.punctuation_mark{
      margin: 0 5px;
    }
  }
}

.nameRemovemetricInfoBlock {
  @media (max-width: 767px) {
    display: block;
  }
}
.profilePage {
  .ant-tabs-tab {
    @media (max-width: 767px) {
      font-size: 14px;
      padding: 0 10px !important;
    }
  }
}

.responsiveswitcherContainer {
  @media (max-width: 767px) { 
    margin-right: 35px;
  }
  @media (max-width: 345px) {
    margin-right: 25px;
  }
}
.MetricsTimeLineChartSection, .customCardContainer {
  .ant-card-extra {
     @media (max-width: 767px) { 
        margin-top: 30px;
      } 
      .cardHeaderTitle {
        max-width: calc(100% - 28px) !important;
        font-size: 12px !important;
      }
  }
  .ant-card-head {
    @media (max-width: 767px) { 
      padding: 0 10px;
    }
  }
  .ant-card-head-wrapper {
    li {
      @media (max-width: 767px) { 
        margin-right: 5px !important;
      }
      .ant-menu-submenu-title {
        padding: 0 5px 0 0 !important;
      } 
    }
  }
  /*.ant-card-head-title {
    @media (max-width: 767px) { 
      width: 100%;
      display: block;
    }
  }
  .ant-card-extra {
    @media (max-width: 767px) { 
      width: 100%;
      display: block;
    }
  }*/
  /*.recharts-legend-wrapper {
    @media (max-width: 767px) { 
      left: 0;
    }
  }*/
}
.responsiveHeader {
  &.ant-layout-header {
    @media (max-width: 767px) {
      background: #003459 !important;
      border-bottom: 1px solid #e8e8e8;
      padding: 0;
    }
  }
  .innerResponsiveHeader {
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      padding: 5px 15px;
    }
    h1 {
      @media (max-width: 767px) {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        margin: 0;
      }
    }
    a {
       @media (max-width: 767px) { 
        display: flex;
       }
    }
  }
}

.responsiveFooter {
  &.ant-layout-footer {
    @media (max-width: 767px) {
      background: #f6f9fc !important;
      border-top: 1px solid #e8e8e8;
      padding: 0;
      height: 64px;
      line-height: 64px;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 99;
    }
  }
  .innerResponsiveFooter {
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: 0 15px;
    }
    a {
      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        margin: 0 5px;
      }
      i {
        @media (max-width: 767px) {
          font-size: 26px;
          height: 26px;
          opacity: 0.6;
        }
      }
    }
  }
}

.ant-message{
  z-index: 999999 !important;
}
.ant-dropdown.ant-dropdown-placement-bottomCenter {
    z-index: 999;
}
.ant-dropdown.ant-dropdown-placement-bottomRight {
  @media (max-width: 767px) {
    z-index: 999;
  } 
}
.ant-calendar-picker-container-placement-bottomLeft {
  @media (max-width: 767px) {
    left: 32px !important;
  }  
}

.main_layout_main_inner {
    height: 100vh;
  @media (max-width: 767px) {
    overflow: auto;
    height: calc(100% - 128px);
    // flex: 1;
    padding: 24px 20px 70px;
    -webkit-overflow-scrolling: touch
  }
}


.video-embed-field-responsive-video {
  iframe {
    width: 100%;
    height: 25vw;
    max-width: 44.479vw;
    @media (max-width: 767px) { 
      width: 100%;
      height: 50vw;
      max-width: 100%;
    }
  }
}
.maincardWrapperNewMetric {
  height: calc(100vh - 150px);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 24px;
  @media (max-width: 767px) {
    -webkit-overflow-scrolling: touch
  }
}

.color_mertic_setting {
  height: calc(100vh - 150px);
  overflow: auto;
  padding: 0 15px;
  -webkit-overflow-scrolling: touch
}

.drawerComponentSymptomChecker {
  .ant-drawer-wrapper-body {
    overflow: hidden !important;
  }
  .ant-drawer-body {
    @media (max-width: 767px) {
      padding: 0;
    }    
  }
}

.accordion-header-secondary {
    outline: none;
}


.documentMobileView {
  box-shadow: 0 1px 3px rgba(0,0,0,.15);
  border-radius: 14px;
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  .documentMobileView_head {
    position: relative;
    margin-bottom: 10px;
    label {
      span {
        &.ant-checkbox.ant-checkbox-indeterminate {
          span{
            &.ant-checkbox-inner{
              &:after {
                content: "";
                left: 51%;
                display: block;
              }
            }
          }
        }
      }
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        &:after {
          left: 31%;
          display: block;
        }
      }
    }
    > span {
      color: #75838b;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      margin-left: 10px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    i {
      font-size: 26px;
      transform: rotate(90deg);
      color: #75838b;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .documentMobileView_content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      .typeBox {
        border-width: 2px;
        border-style: solid;
        border-radius: 12px;
        width: 84px;
        padding: 7px;
        height: 115px;
        position: relative;
        margin-right: 15px;
        text-align: center;
        svg {
          width: 60px;
          height: 60px;
          margin-top: 7px;
        }
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 0 0 7px 7px;
          margin: 0;
          font-size: 12px;
          color: #fff;
          text-align: center;
          padding: 5px 0;
          font-weight: 500;
          line-height: 1;
          min-height: 22px;
        }
    }
  }
  .fileText {
    width: calc(100% - 104px);
    word-break: break-all;
    h4 {
      color: #003459;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      cursor: pointer;
      display: inline-block;
      word-break: break-all;
    }
    p {
      color: #75838b;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      &.responsiveLanguage {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #003459;
      }
    }  
  }  
}


/* responsive add entry button */
.desktopViewMenuDash {
  display: inline-block !important;
  @media (max-width: 767px) { 
    display: none !important;
  }
}
.mobileViewMenuDash {
  display: none !important;
  @media (max-width: 767px) { 
    display: inline-block !important;
  }
}

.mainselectedBlock {
    padding-bottom: 0 !important;
}
.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.HideMobileTitle {
  @media (max-width: 767px) { 
    display: none
  }
}
.mainHomeSection {
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: 767px) { 
    display: block;
  }
  &.notLogin {
    .MainhomeRight {
      display: none;
    }
  }
  .MainhomeRight {
    width: 371px;
    max-width: 371px;
    @media (max-width: 1320px) {
      width: 100%;
      max-width: 235px;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
    .homeRight {
      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
      }
      .homeRightRow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding-left: 20px;
        @media (max-width: 1320px) {
          justify-content: center;
        }
        @media (max-width: 767px) {
          padding: 0;
          justify-content: flex-start;
        }
        .HomeGraphGrid {
          padding: 0 10px;
          margin-bottom: 20px;
          width: 33.33%;
          @media (min-width: 1920px) {
            width: 25%;
            max-width: 256px;
          }
          @media (max-width: 1610px) {
            width: 50%;
          }
          @media (max-width: 1450px) {
            width: 100%;
          }
          @media (max-width: 767px) {
            width: 33.33%;
          }
          @media (max-width: 480px) {
            width: 50%;
          }
          .customCardContainer {
            width: 100%;
            height: auto;
            padding: 10px 10px 2px 10px;
            .ant-card-head {
              padding: 0;
              min-height: 0px;
              .ant-card-head-title {
                padding: 0;
                h1 {
                  font-weight: 700;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  font-size: 16px;
                  color: #003459;
                  margin: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .ant-card-body {
              padding: 0;
            }
          }
        }
      }
      .homeRightButton {
        padding: 0 25px;
        @media (max-width: 1320px) {
          text-align: center;
        }
      }
    }
  }
  .MainhomeLeft {
    max-width: 100%;
    width: 100%;
    @media (max-width: 767px) {
      .searchBox {
        display: none;
      }
    }
  }
}


.EncyclopediaEntryCustomCard {
  .ant-card-body {
      padding: 10px 25px;
  }
}

.AboutCustomCard {
  .ant-tabs-bar {
      border: none;
      .ant-tabs-nav-container {
        height: 40px;
      }
  }
  .ant-tabs-nav-wrap {
      margin: 0;
      .ant-tabs-tab {
        margin: 0 2px 0 0;
        padding: 0 16px;
        height: 40px;
        line-height: 38px;
        @media (max-width: 767px) {
          padding: 0 10px;
        }
        span {
          margin-bottom: 0;
          a {
            font-weight: 700;
            background: transparent !important;
            border: none !important;
            color: #8898aa !important;
          }
          &.tabActive {
            a {
              color: #003459 !important;
            }
          }
        }
      }
      .ant-tabs-ink-bar {
          display: none !important;
      }
  }
  .ant-menu {
    margin: 0 0 16px 0;
    border-bottom: none;
    overflow: hidden;
    background: transparent;
    line-height: 39px;
    display: flex;
    height: auto;
    border: none;
    flex-wrap: wrap;
    li {
      border: none !important;
      padding: 0 16px !important;
      top: 0;
      margin-top: 0;
      width: auto;
      background: transparent !important;
      white-space: normal;
      display: inline-block;
      height: auto;
      line-height: 39px;
      margin-bottom: 0;
      &:after {
        display: none;
      }
      a {
        font-weight: 700;
        color: #8898aa;
        font-size: 16px;
        @media (max-width: 767px){
          font-size: 14px;
        }
        span {
          margin-bottom: 0;
        }
      }
      &:hover {
        a {
          color: #8898aa;
        }
      }
    }
    .ant-menu-item-selected {
      border: none !important;
      margin-right: 2px;
      margin-bottom: 0;
      a {
        color: #003459;
      }
      &:hover {
        a {
          color: #003459;
        }
      }
    }
  }
  .ant-card-body {
      padding: 10px 25px;
  }
  h1 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: 28px;
    margin-bottom: 8px;
    color: @textPrimary;
    @media (max-width: 767px) {
      margin-top: 28px;
      font-size: 20px;
      line-height: 1.2;
    }
  }
  h2 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
    color: @StrongBlue;
  }
  h3 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: @StrongBlue;
  }
  h4 {
    font-family: Open Sans, Verdana;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: @infoGray;
  }
  p {
    font-family: Open Sans, Verdana;
    font-size: 14px !important;
    margin-top: 0;
    margin-bottom: 10px;
  }
  li {
    font-family: Open Sans, Verdana;
    font-size: 14px;
    margin-top: 0;
  }
  table {
    caption {
      color: #888;
      font-size: smaller;
      font-style: italic;
      padding: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  table, th, td {
      border: 1px solid #ddd;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 14px;
  }
  table tr:nth-child(even) {
      background-color: #f2f2f2;
  }

  table tr:hover {
      background-color: #ddd;
  }
  table thead th {
      padding-top: 12px;
      padding-bottom: 8px;
      text-align: left;
      background-color: #003459;
      color: white;
  }

  table tfoot th {
      text-align: left;
      border-top: 4px solid #ddd;
      background-color: #fff;
  }
}


.adminToolItemImage{
 width: 125px;
 @media (max-width: 500px){
    margin: auto;
  }
  img {
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: contain;
  }
}

.maindragWrapper + .ant-upload-list.ant-upload-list-text {
    display: none;
}
.ant-upload-hint {
  &.desktopViewLabel {
    @media (max-width: 767px) { 
      display: none;
    }
  }
}

.react-loading-center{
  margin: 0 auto;
}

.adminToolForm{
  textarea {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #003459;
  }
}
.home-container {
  .ant-btn-primary:focus{
      background-color: transparent;
      border-color: transparent;
      color: #003459;
  } 
  .ant-btn-primary:hover{
      color: #fff;
      background-color: #507196;
      border-color: #507196;
  }  
}
.emailError {
    background-color: #e54d4c;
    color: #fff;
    border-radius: 6px;
    padding: 6px 8px;
    margin: -17px 0 24px 0;
    @media (max-width: 767px) {
      margin: -12px 0 15px 0;
    }
}
.ant-tooltip.tooltipError.ant-tooltip-placement-bottom {
    left: 0 !important;
    right: 0 !important;
    margin: 30px auto 0;
    max-width: 485px;
    padding-top: 18px;
    @media (max-width: 767px){
      left: 15px !important;
      right: 15px !important;
    }
}
.ant-tooltip.tooltipError.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 15px;
  top: 5.07106781px;
}
.tooltipError .ant-tooltip-inner span {
  white-space: pre-line;
}

.ant-select-dropdown {
  overflow: hidden;
}
.withoutLogin {
  opacity: 0.5;
}

.AdminToolsAnalyticsSection {
    display: flex;
    @media (max-width: 767px) { 
      flex-wrap: wrap;
    }
    .AdminToolsAnalyticsLeft {
      max-width: 100%;
      width: 100%;
      @media (max-width: 767px) { 
        order: 1;
      }
    }
    .mainChart {
      width: 100%;
      max-width: 199px;
      margin: 0 0 0 20px;
      @media (max-width: 1320px) {
        width: 100%;
        max-width: 200px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        order: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px;
        justify-content: space-between;
      }   
      .customCardContainer {
          padding: 0 0 0 !important;
          margin-bottom: 20px;
          width: 100%;
          margin: 0 0 20px;
          border-radius: 14px;
          box-shadow: 0 2px 25px rgba(0,0,0,.0956465);
          border: 1px solid #e8e8e8 !important;
          @media (max-width: 767px) {
            width: 31.33%;
            margin: 0 0 20px;
            padding: 0 10px !important;
          }
          @media (max-width: 480px) {
            width: 48%;
          }
          @media (max-width: 360px) {
            width: 100%;
          }
          .ant-card-head {
            margin-bottom: 0;
            padding: 0px 10px;
            min-height: auto;
            .ant-card-head-title {
                padding: 10px 0 0;
            }   
            h1 {
                color: #003459;
                display: inline-block;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
          }
          .ant-card-body {
             padding: 0px 10px 0;
          }
        }
    }
}
.adminToolTags span.ant-form-item-children {
  display: flex;
  flex-wrap: wrap;
}
.adminToolTags span.ant-form-item-children .ant-tag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 767px) {
    width: 230px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
.BasicInfoSecRow > div:nth-child(4) {
    width: 190px;
    display: inline-block;
    @media (max-width: 767px) {
      width: 50%;
    }
}
.BasicInfoSecRow > div:nth-child(5) {
    width: 190px;
    display: inline-block;
    @media (max-width: 767px) {
      width: 50%;
    }
}
.BasicInfoSecRow>div:nth-child(5) li.ant-select-dropdown-menu-item {
  word-break: break-all;
}
.bloodPressureDiastolicInput-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .bloodPressureDiastolicInput {
    width: 47%;
    input {
      background-color: #f4f6f7;
      height: 90px;
      border-radius: 14px;
      font-weight: bold;
      margin-top: 20px;
      font-size: 40px;
      border: none;
    }
  }
  &.active {
    display: block;
    .bloodPressureDiastolicInput {
      width: 100%;
      input {
        margin-top: 0;
      }
    }
  }
}
.ant-statistic-title {
  display: inline-block;
}
.customCardContainer {
  margin-bottom: 30px;
}
.languageDivider {
  margin-left: 4px;
  height: 25px;
  top: -4px;
  background: #d9d9d9;
}

.stress_buttons {
  margin-top: 15px;
  @media (max-width: 767px) {
    margin-top: 18px;
  }
  .stress_button {
    padding: 0 3px;
    border-radius: 0;
    box-shadow: none;
    border: none;
    width: 20%;
    &:after {
      display: none;
    }
    img {
      max-width: 100%;
      display: block;
      margin: auto;
      &.active {
        display: none;
      }
    }
    &:hover, &.active {
      img {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
}
.weight_units {
    padding-left: 20px;
    margin-bottom: 25px;
    &:first-child {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    @media (max-width: 767px) { 
      width: 50%;
      padding: 0 5px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 22px;
      color: #003459;
      font-weight: 700;
      margin: 0;
    }
    .weight_units_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -15px 0;
        @media (max-width: 767px) {
          margin: 0;
        }
        .weight_units_col {
            width: 100%;
            padding: 0 15px;
            @media (max-width: 1580px) {
              width: 100%;
              margin-bottom: 0;
            }
            @media (max-width: 767px) {
              margin-bottom: 0;
              padding: 0;
            }
            .weight_initial_state {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                border: 1px solid #e8e8e8;
                border-radius: 16px;
                padding: 10px;
                max-width: 100%;
                height: 155px;
                background: #fff;
                box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
                @media (max-width: 767px) {
                  max-width: 100%;
                  height: 135px;
                }
                .customCardContainer {
                  margin-bottom: 0;
                  border: none;
                  background: #fff !important;
                  box-shadow: none;
                  .ant-card-head-title {
                    padding: 0;
                    min-height: auto;
                    h1 {    
                      margin: 0;
                      font-size: 12px;
                      font-weight: 700;
                      color: #003459;
                      text-transform: uppercase;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                  .ant-card-head {
                    padding: 0;
                    min-height: auto;
                  }
                  .ant-card-body {
                    padding: 0;
                    .weight_form_button {
                      float: right;
                      margin-top: 5px;
                      @media (max-width: 767px) {
                        margin-top: 2px;
                        height: 30px;
                        font-size: 12px;
                        padding: 0 10px;
                      }
                    }
                  }
                }
                h4 {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 700;
                  color: #003459;
                  text-transform: uppercase;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: gray;
                    margin: 0;
                }
                .weight_form {
                    display: block;
                    margin-top: 25px;
                    text-align: right;
                    @media (max-width: 767px) {
                      flex-wrap: wrap;
                      flex-direction: column;
                      align-items: flex-end;
                      margin-top: 15px;
                    }
                    .ant-input-affix-wrapper {
                        display: flex;
                        align-items: center;
                        background: #f4f6f7;
                        border-radius: 14px;
                    }
                    .ant-input {
                        background: transparent;
                        border: none;
                        border-radius: 14px;
                        padding: 2px 45px 2px 10px;
                        height: 40px;
                        border: 1px solid #d9d9d9;
                        color: inherit !important;
                        z-index: 12;
                        &:focus {
                            border-color: inherit;
                            // border-color: #507196;
                            box-shadow: none;
                        }
                    }
                    .ant-input-suffix {
                        right: 10px;
                        font-size: 16px;
                        color: #d9d9d9;
                        font-weight: bold;
                        z-index: 1;
                    }                    
                }
                .weight_form_button {
                    margin-top: 10px;
                    margin-left: auto;
                    font-size: 15px;
                    font-weight: 600;
                    &:disabled{
                      color: #fff !important;
                      background-color: #E1E6E9 !important;
                      cursor: not-allowed !important;
                      border-color: #E1E6E9 !important;
                    }
                    @media (max-width: 767px) {
                      height: 30px;
                      padding: 0 10px;
                      margin-top: 10px;
                    }
                }
            }
            &.heartrate_col {
              .weight_initial_state {
                .customCardContainer {
                  .ant-card-body {
                    .weight_form_button {
                      background-color: #E54D4C;
                      border-color: #E54D4C;
                    }
                  }
                  .ant-card-head-title {
                    h1 {
                      color: #E54D4C;
                    }
                  }
                }
              }
              .weight_initial_state {
                h4 {
                    color: #E54D4C;
                }
                .weight_form {
                  // .ant-input {
                  //     color: #E54D4C !important;
                  //     &:focus {
                  //         border-color: #E54D4C;
                  //     }
                  // }
                  .weight_form_button {
                    &:enabled {
                        background-color: #E54D4C;
                        border-color: #E54D4C;
                    }
                  }
                }
              }
            }
        }
    }
}


@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall, .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall .recharts-wrapper, .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall .recharts-wrapper svg {
      height: 90px !important;
  }
}
.covidChartContaint {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
}

.covidChart {
    width: 70%;
    height: 70px;
}

.covidChartDetail {
    width: 30%;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state {
    height: 225px;
    @media (max-width: 767px) {
      height: 220px;
    }
}

.covidChartDetail .covidChartDetailBox {
    // margin-bottom: 5px;
}

.covidChartDetail .covidChartDetailBox span {
    font-size: 12px;
    line-height: 1;
}

.covidChartDetail .covidChartDetailBox h2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: inherit;
}

.covidChartDetail .covidChartDetailBox:nth-child(1) {
    color: #E54E4C;
}

.covidChartDetail .covidChartDetailBox:nth-child(2) {
    color: #68BE68;
}

.covidChartDetail .covidChartDetailBox:nth-child(3) {
    color: #595959;
}


.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper {
  flex-wrap: wrap;
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra {
  width: 50%;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0;    
  }
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-head-title {
    width: 50%;
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection {
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    border-radius: 0;
    border: none;
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection .ant-select-selection-selected-value {
    font-size: 14px;
    line-height: 1;
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection .ant-select-selection__rendered {
    line-height: 1;
}

.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection:focus {
    box-shadow: none;
}

.covidChartContainer {}

.covidChartContainer .covidChartInput {}

.covidChartContainer .covidChartInput input.ant-input {
    background: #f4f6f7 !important;
    border-radius: 0px;
    padding: 2px 30px 2px 10px!important;
    height: 40px !important;
    border: none;
    color: inherit !important;
    z-index: 12;
    box-shadow: none;
    font-size: 14px !important;
}

.covidChartContainer .covidChartInput  .ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    border-radius: 14px;
}

.covidChartContainer .covidChartInput .ant-input-suffix {
    z-index: 123;
    right: 10px;
}

.covidChart svg {
    width: 135%;
    height: 135%;
    margin: -11% 0% 0% -15%;
    @media (min-width: 1920px) {
      width: 125%;
      height: 125%;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      margin: 0;
    }
}
.itemState {
  pointer-events: none;
  color: gray;
}
.coronaCountryDropDown ul li:first-child {
    border-bottom: 1px dashed #003459;
}

.coronaCountryDropDown ul {
    // border: 1px solid #003459;
    // border-radius: 14px;
    width: calc(100% - -15px);
}
.autocompleteEncyclopedia ul li .stateName .itemState {
  position: relative;
  padding-left: 6px;
  margin-left: 3px;
}
.autocompleteEncyclopedia ul li .stateName .itemState:before {
  content: "";
  width: 3px;
  height: 3px;
  background: gray;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); 
  border-radius: 100%;
}
.covidChartInput {
  background: #f4f6f7 !important;
  border-radius: 14px;
  // padding: 2px 30px 2px 10px !important;
  height: 40px !important;
  border: 1px solid #d9d9d9;
  color: inherit !important;
  z-index: 12;
  box-shadow: none;
  font-size: 14px !important;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
}

.covidChartInput svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 123;
}

.coronaCountryDropDown > div {
  border: 1px solid #003459;
  border-radius: 14px;
  overflow: hidden !important;
}
.coronaCountryDropDown {
  max-width: 100% !important;
}

.covidChartInput .selectedInput {
  background: #f4f6f7;
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  z-index: 123;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  &.active {
    display: flex;
  }
}

.covidChartInput .selectedInput span {
  position: relative;
  padding-left: 6px;
  margin-left: 3px;
  color: gray;
  &:before {
    content: "";
    width: 3px;
    height: 3px;
    background: gray;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); 
    border-radius: 100%;
  }
}
.covidChartInput .selectedInput.active + .ant-select input.ant-input {
  opacity: 0;
}
@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;