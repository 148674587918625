.styles_openSans-300-n__LpRnY {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__1tQd7 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__2veVa {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__2Rm73 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__3AAgL {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__16Cbb {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__223lq {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__1s-xl {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__mP_Oz {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__10Bbw {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
}
@media (max-width: 767px) {
  .styles_header__10Bbw {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_certainCategoryIcon__2k-L9 svg {
  font-size: 20px;
  color: #8898aa !important;
}
.styles_searchInput__WHkjr {
  width: 100%;
}
.styles_searchInput__WHkjr input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__3hswG {
  margin-bottom: 24px;
  position: relative;
  max-width: 900px;
}
.styles_searchInputBlock__3hswG .styles_suggestionsList__1KBPN {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__3hswG .styles_suggestionsList__1KBPN > div {
  background: #ffffff !important;
}
.styles_searchInput__WHkjr input {
  border: unset;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
.styles_searchInput__WHkjr input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_containerEncyclopedia__3Dz3L,
.styles_containerEncyclopediaEntry__hWgTh {
  max-width: 900px;
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_cardHeaderBlock__3NcAT {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.styles_cardHeaderBlock__3NcAT .styles_cardHeader__3Z6iP {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_labelBlock__3snZl {
  padding: 0 30px;
  margin: 0 0;
  line-height: normal;
  font-weight: 100;
  color: #75838B;
  width: calc(100% - 84px);
  display: block;
  text-align: left;
}
@media (max-width: 500px) {
  .styles_labelBlock__3snZl {
    padding: 0;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
}
.styles_labelBlock__3snZl p {
  color: #75838B !important;
  font-size: 16px !important;
}
.styles_uploadButton__2W9Pf {
  width: 100% !important;
  max-width: 150px;
  background: #f4f6f7;
  border-radius: 14px;
  padding: 9px 0;
  font-size: 14px !important;
  margin: 7px auto !important;
  font-weight: 600;
  color: #003459 !important;
}
.styles_tags__1USXH {
  width: 500px;
}
.styles_tag__3lygN {
  height: 40px !important;
}
.styles_paragraphInfo__1We8x {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_symptomInfoBlock__1qqoZ {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.styles_infoUser__1yfJd {
  display: flex;
  height: 70px;
  min-width: 320px;
  flex-shrink: 0;
}
.styles_infoUser__1yfJd .styles_avatar__3n4Bs {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f6f7;
  border-radius: 50%;
  margin-right: 16px;
}
.styles_infoUser__1yfJd .styles_avatar__3n4Bs svg {
  font-size: 70px;
}
.styles_selectBlock__3ATvF {
  width: 234.55px;
}
.styles_selectBlock__3ATvF .styles_selectBlock1__1_pDi {
  margin-bottom: 10px;
}
.styles_selectBlock2__2tza5 {
  display: flex;
}
.styles_selectBlock2__2tza5 .styles_selectWrap__i5h1J {
  margin-right: 10px;
}
.styles_wrapCustomCard__2DND5 {
  margin-bottom: 25px;
}
.styles_customSelect__2eddn div {
  font-size: 14px;
}
.styles_customSelect__2eddn > div {
  background: #f4f6f7;
  border: unset;
}
.styles_itemCause__2pXqD {
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
}
.styles_itemCause__2pXqD:hover {
  background: #f4f6f7;
  cursor: pointer;
}
.styles_itemCause__2pXqD:hover .styles_nameCause__D9GiL {
  text-decoration: underline;
}
.styles_percentBlock__2-0dU {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
}
.styles_nameCause__D9GiL {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.styles_header__10Bbw {
  color: #003459;
}
.styles_header__10Bbw .styles_percentBlock__2-0dU + div {
  font-size: 14px;
}
.styles_headerDrawer__eIFKN {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
}
.styles_headerDrawer__eIFKN p {
  margin: 0 10px;
}
.styles_headerDrawer__eIFKN button {
  box-shadow: unset;
  border: unset;
  background: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 24px;
  width: 24px;
  padding: 0;
}
.styles_headerCause__1KYDc {
  margin: 0;
  font-size: 22px;
}
.styles_infoCause__3SPxJ {
  margin: 24px 0;
}
.styles_cardWrapperNewMetric__3bWVp {
  cursor: auto;
  cursor: initial;
  padding-bottom: 24px;
}
.styles_cardWrapperNewMetric__3bWVp .styles_infoCausePercent__3hlzc {
  margin-top: 0;
}
.styles_cardWrapperNewMetric__3bWVp .styles_headerInfo__19oad,
.styles_cardWrapperNewMetric__3bWVp .styles_headerInfoNoSelected__1Z_Vp {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__3bWVp .styles_headerInfoNoSelected__1Z_Vp {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__3bWVp .styles_selectedMetrics__1nNRQ {
  margin-bottom: 38px;
}
.styles_cardWrapperNewMetric__3bWVp .styles_selectedMetricHeader__2DSXW {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_btnBlock__24btp {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
}
.styles_btnBlock__24btp button {
  margin-left: 8px;
}
.styles_causeItem__36iTL {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__36iTL > div {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_causeItem__36iTL svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_userInfoDrawer__V1py6 {
  margin-bottom: 40px;
}
.styles_percentBlockDrawer__2CN1o {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .styles_symptomInfoBlock__1qqoZ {
    flex-direction: column-reverse;
  }
  .styles_tags__1USXH {
    width: 100%;
  }
}
.styles_infoCategory__1gKth {
  font-size: 12px;
  font-style: italic;
}
.styles_imgAvatar__3cRWG {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.styles_cropWrapper__2BPRq {
  display: flex;
  justify-content: center;
}
.styles_croppedImageContainer__cXPTr {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.styles_croppedImageRotate__2m3Ue {
  margin-top: 18px !important;
  margin-left: 0 !important;
  text-align: center;
}
.styles_croppedImageRotate__2m3Ue button {
  margin-bottom: 20px;
}
.styles_croppedImageRotate__2m3Ue button:first-child {
  margin-right: 15px;
}
.styles_dragWrapper__2XfNy {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 14px;
  cursor: pointer;
  transition: border-color 0.3s;
  min-height: 166px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.styles_dragWrapper__2XfNy i {
  color: #606669 !important;
  font-size: 40px !important;
}
.styles_selectLanguageBlock__1WHjo {
  margin-bottom: 24px;
  text-align: left;
}
.styles_selectLanguageBlock__1WHjo button {
  margin-right: 4px;
  padding: 3px 10px 0px 10px;
  background: transparent;
  border: 1px solid transparent;
}
.styles_selectLanguageBlock__1WHjo button:last-child {
  margin-right: 0;
}
.styles_selectLanguageBlock__1WHjo button:hover {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.styles_adminToolEmail__R4_cL {
  margin-bottom: 24px;
}
.styles_adminToolForm__37eph textarea {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
}
.styles_email_list__1B-Dm {
  display: block;
}
.styles_email_list__1B-Dm.styles_active__29L3J {
  font-weight: bold;
}
.styles_languageSelector__G5WoQ {
  display: flex !important;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .styles_languageSelector__G5WoQ {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .styles_languageSelector__G5WoQ span {
    width: 100%;
  }
}

.styles_openSans-300-n__ZwF5J {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__2maeG {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__dPi9i {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__hsrXe {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__32RTj {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__2BGEp {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__64YBL {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__2E4lr {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__33mmN {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_dateLabel__3qUIr {
  position: absolute;
  bottom: -10px;
  margin-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #000;
}
.styles_switcherContainer__LmXdI {
  width: 48px;
  height: 24px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  margin-right: 18px;
  background: #F4F6F7;
  font-size: 16px !important;
}
.styles_pointer__1xfEd {
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  position: absolute;
  background: #FFFFFF;
  transition: left 0.3s ease-out;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}
.styles_icon__IDmqw {
  top: 5px;
  z-index: 9;
  position: absolute;
  transition: color 0.3s ease-out;
}
.styles_cardContainer__pyY2j {
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
}
.styles_legendLabel__tGIQU {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #003459;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 19px);
}
.styles_legendIcon__3GKIP {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border: 3px solid;
  border-radius: 50%;
}
.styles_legendContainer__3Ouws {
  display: flex;
  cursor: pointer;
  max-width: 130px;
  align-items: center;
}
.styles_metricName__OfRxT {
  color: #003459;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_metricVal__3h9yH {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_dropDownLink__1J2f0 {
  border-radius: 14px;
  background-color: #f4f6f7;
  color: #003459;
  line-height: 1.43;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.styles_dropDownLink__1J2f0:last-child {
  margin-left: 7px;
}
@media (max-width: 767px) {
  .styles_dropDownLink__1J2f0 {
    width: 33.33%;
    margin: 10px auto;
  }
}
.styles_dropDownItem__74K2g {
  text-align: center;
}
.styles_DatePickerContainer__3iVMw {
  display: flex;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
}
.styles_DatePickerDateContainer__3ws_L {
  width: 250px;
  height: 40px;
  display: flex;
  cursor: pointer;
  border-radius: 14px;
  align-items: center;
  background-color: #f4f6f7;
  justify-content: space-between;
}
.styles_DatePickerChangeDaysBtn__LKKli {
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_DatePickerValue__3iggs {
  width: 190px;
  padding: 5px;
  font-size: 14px;
  color: #003459;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  display: inline-block;
}
.styles_WrapResponsiveContainer__1t_KP {
  border-radius: 0px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  margin: 0;
  padding: 0;
}
.styles_timeBlock__1wMBe {
  display: flex;
  align-items: center;
}
.styles_customButtonTooltipActivated__3CM00 {
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
}
.styles_CommonAlertMsg__1VK63 .styles_title__1TC8T span {
  font-size: 18px;
  line-height: 26px;
  color: #003459;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}
.styles_CommonAlertMsg__1VK63 .styles_descriptionHeader__3VzHi {
  margin: 15px 0 0;
}
.styles_CommonAlertMsg__1VK63 .styles_descriptionHeader__3VzHi span {
  font-size: 14px;
  color: #003459;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block;
}
.styles_CommonAlertMsg__1VK63 .styles_descriptionContent__1Mv-Y {
  margin: 0 0 15px;
}
.styles_CommonAlertMsg__1VK63 .styles_descriptionContent__1Mv-Y span {
  font-size: 14px;
  color: #003459;
  font-weight: 400;
}
.styles_CommonAlertMsg__1VK63 .styles_CommonAlertButton__3oJch {
  margin-bottom: 0;
  border-color: #335889;
  background: #335889;
  color: #fff;
  height: 44px;
  font-size: 15px;
  font-weight: 700;
  min-width: 234px;
}
.styles_CommonAlertMsg__1VK63 .styles_CommonAlertButton__3oJch:hover {
  background: #507196;
  border-color: #507196;
  color: #fff;
}
.styles_CommonAlertMsg__1VK63 {
  padding: 15px !important;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  margin-bottom: 30px;
  border-radius: 14px;
}
@media (max-width: 767px) {
  .styles_TermsOffUse__3R5x8 .styles_title__1TC8T {
    display: none;
  }
}
.styles_TermsOffUse__3R5x8 .styles_title__1TC8T h1 {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #003459;
}
.styles_TermsOffUse__3R5x8 h2 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 15px;
}
.styles_TermsOffUse__3R5x8 span {
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
}
.styles_chartItem__3QKpv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .styles_chartItem__3QKpv {
    width: 100% !important;
  }
}
.styles_chartItem__3QKpv > div {
  width: 100%;
}
.styles_chartContainer__3Wl0n {
  margin-bottom: 0px;
}

.styles_openSans-300-n__3yjFV {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__1eadM {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__1mKM6 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__1lcGj {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__16VPn {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__XC99n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__1XAWj {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__2y-9Z {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__29Jul {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300.76b56857.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/static/media/open-sans-v15-latin-300.76b56857.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300.60c86674.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300.521d17bc.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300.177cc92d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300.505d96bd.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300italic.687596af.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(/static/media/open-sans-v15-latin-300italic.687596af.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300italic.06bbd318.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300italic.8a648ff3.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300italic.c03f38da.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300italic.b5065b4f.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-regular.cffb686d.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-regular.bf2d0783.woff) format('woff'),  url(/static/media/open-sans-v15-latin-regular.c045b73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-regular.ddaaf490.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-italic.987032ea.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-italic.db70d0b9.woff) format('woff'),  url(/static/media/open-sans-v15-latin-italic.f42df73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-italic.42762880.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600.7728dd9f.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(/static/media/open-sans-v15-latin-600.7728dd9f.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600.c062d7d1.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600.b1249dd5.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600italic.4950a720.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600italic.318ea1ad.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600italic.b5d6ad58.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600italic.adf9d003.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700.148a6749.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/static/media/open-sans-v15-latin-700.148a6749.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700.d08c09f2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700.623e3205.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700.7e08cc65.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700.9f5a7745.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700italic.437e5048.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/static/media/open-sans-v15-latin-700italic.437e5048.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700italic.c02f5da6.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700italic.72e19cbb.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700italic.cffddf70.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700italic.e8ebd85a.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800.dba530a6.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(/static/media/open-sans-v15-latin-800.dba530a6.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800.aaeffaf2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800.c6aa0c4a.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800.04ee5b60.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800.45c95bf9.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800italic.6b3973ff.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800italic.79b58175.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800italic.9855a8fd.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800italic.130bf843.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/static/media/lato-v14-latin-100.b859abc8.eot);
  /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'), url(/static/media/lato-v14-latin-100.b859abc8.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-100.f8028e61.woff2) format('woff2'),  url(/static/media/lato-v14-latin-100.752c2022.woff) format('woff'),  url(/static/media/lato-v14-latin-100.4d89307c.ttf) format('truetype'),  url(/static/media/lato-v14-latin-100.66c26530.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/lato-v14-latin-300.fc1bb181.eot);
  /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'), url(/static/media/lato-v14-latin-300.fc1bb181.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-300.bbbd26ae.woff2) format('woff2'),  url(/static/media/lato-v14-latin-300.ba2452bd.woff) format('woff'),  url(/static/media/lato-v14-latin-300.49d84f82.ttf) format('truetype'),  url(/static/media/lato-v14-latin-300.a3fb8786.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/lato-v14-latin-700.5bfc01b7.eot);
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'), url(/static/media/lato-v14-latin-700.5bfc01b7.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-700.7fbbfd16.woff2) format('woff2'),  url(/static/media/lato-v14-latin-700.96759e32.woff) format('woff'),  url(/static/media/lato-v14-latin-700.f467f2d0.ttf) format('truetype'),  url(/static/media/lato-v14-latin-700.7470c61a.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/lato-v14-latin-900.dec6ce78.eot);
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'), url(/static/media/lato-v14-latin-900.dec6ce78.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-900.f2de2c6e.woff2) format('woff2'),  url(/static/media/lato-v14-latin-900.d79dd066.woff) format('woff'),  url(/static/media/lato-v14-latin-900.281e5e87.ttf) format('truetype'),  url(/static/media/lato-v14-latin-900.c4c24d2a.svg#Lato) format('svg');
  /* Legacy iOS */
}
.styles_buttonsContainer__24khz {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
}
.styles_chartContainer__2Vgmy {
  margin-bottom: 20px;
}
.styles_chartContainer__2Vgmy .styles_btn__EEqfp {
  width: 157px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .styles_chartContainer__2Vgmy .styles_btn__EEqfp {
    width: auto;
    height: 35px;
    border-radius: 11px;
    padding: 0 25px;
    line-height: 26px;
  }
}
@media (max-width: 359px) {
  .styles_chartContainer__2Vgmy .styles_btn__EEqfp {
    padding: 0 15px;
  }
}
.styles_menu__1CPjB {
  border-bottom: none !important;
  line-height: normal !important;
  line-height: initial !important;
}
.styles_menu__1CPjB .styles_menuItem__2N2OJ {
  height: 21px;
  padding: 0px;
  font-size: 11px;
  font-weight: 600;
  margin-right: 15px;
  letter-spacing: 0.5px;
}
.styles_buttonClass__24Pgb {
  border: none !important;
  font-size: 12px !important;
}
.styles_rightBtnContainer__3u5L5 {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .styles_rightBtnContainer__3u5L5 {
    align-items: center;
  }
}
.styles_cardHeader__2tuvl {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding-top: 8px;
  margin-bottom: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: calc(100% - 48px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.styles_a__doSL9 {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
}
.styles_header__1CLS2 {
  color: #003459;
  /*text-transform: capitalize;*/
}
.styles_cardWrapperNewMetric__1fhES,
.styles_cardWrapperNewEntry__1N_-U {
  cursor: auto;
  cursor: initial;
}
.styles_cardWrapperNewMetric__1fhES .styles_headerInfo__1e4-e,
.styles_cardWrapperNewEntry__1N_-U .styles_headerInfo__1e4-e,
.styles_cardWrapperNewMetric__1fhES .styles_headerInfoNoSelected__20n-p,
.styles_cardWrapperNewEntry__1N_-U .styles_headerInfoNoSelected__20n-p {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__1fhES .styles_headerInfoNoSelected__20n-p,
.styles_cardWrapperNewEntry__1N_-U .styles_headerInfoNoSelected__20n-p {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__1fhES .styles_selectedMetrics__uUBig,
.styles_cardWrapperNewEntry__1N_-U .styles_selectedMetrics__uUBig {
  margin-bottom: 38px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 70px;
}
.styles_cardWrapperNewMetric__1fhES .styles_selectedMetricHeader__3qE4T,
.styles_cardWrapperNewEntry__1N_-U .styles_selectedMetricHeader__3qE4T {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_selectedItemchart__T2q_N {
  height: auto;
  flex-direction: column;
  padding: 10px 24px;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  border-radius: 14px;
}
@media (max-width: 767px) {
  .styles_selectedItemchart__T2q_N {
    padding: 10px 15px;
  }
}
.styles_availableItem__35E-l,
.styles_availableItemChart__2erBG {
  border-radius: 14px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.styles_availableItem__35E-l .styles_addBtn__BKxap,
.styles_availableItemChart__2erBG .styles_addBtn__BKxap {
  display: block;
  height: 30px;
  /*text-transform: capitalize;*/
}
.styles_availableItem__35E-l:hover,
.styles_availableItemChart__2erBG:hover {
  background: #f4f6f7;
}
@media (max-width: 767px) {
  .styles_availableItem__35E-l,
  .styles_availableItemChart__2erBG {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.styles_availableItem__35E-l .styles_leftSideAvailable__21vbl,
.styles_availableItemChart__2erBG .styles_leftSideAvailable__21vbl {
  display: flex;
  max-width: calc(100% - 108px);
}
.styles_availableItem__35E-l .styles_leftSideAvailable__21vbl > div,
.styles_availableItemChart__2erBG .styles_leftSideAvailable__21vbl > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.styles_menuBlock__1eWIZ {
  display: flex;
  justify-content: center;
  background: white;
  padding-top: 10px;
}
.styles_availableItemChart__2erBG {
  height: auto;
  flex-direction: column;
  padding: 10px 24px;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
}
.styles_metricInfoBlock__N8593 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
}
.styles_chartItem__1Nm8t {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 377px;
}
.styles_chartItem__1Nm8t > div {
  width: 100%;
}
.styles_chartDesc__3QcqX {
  font-weight: 500;
  font-size: 14px;
  display: block;
  display: -webkit-box;
  max-width: 400px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px;
}
.styles_selectedItem__gvwei {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .styles_selectedItem__gvwei {
    height: auto;
    margin-bottom: 15px;
  }
}
.styles_LeftSide__KEKQs {
  display: flex;
  max-width: calc(100% - 108px);
}
.styles_LeftSide__KEKQs .styles_nameOfMetric__1M4Rw {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.styles_LeftSideNewMetric___TzGc {
  max-width: calc(100% - 158px) !important;
}
@media (max-width: 767px) {
  .styles_LeftSideNewMetric___TzGc {
    max-width: 100% !important;
    margin-bottom: 10px;
  }
}
.styles_RightSide__2mRRK .styles_RightSideBtn__7T_de {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  height: 30px;
  /*text-transform: capitalize;*/
  margin-left: 8px;
}
@media (max-width: 767px) {
  .styles_RightSide__2mRRK .styles_RightSideBtn__7T_de:first-child {
    margin-left: 0;
  }
}
div .styles_tooltipBtn__F762F {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  padding: 0;
  flex-shrink: 0;
}
.styles_icon__9r-vH {
  margin-right: 16px;
}
@media (max-width: 767px) {
  .styles_icon__9r-vH {
    margin-right: 5px;
  }
}
.styles_icon__9r-vH div {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.styles_iconSearch__2IST6 svg {
  width: 24px;
  height: 24px;
  color: #8898aa;
}
@media (max-width: 767px) {
  .styles_iconSearch__2IST6 svg {
    width: 20px;
    height: 20px;
  }
}
.styles_availableItemsChart__MbWYq {
  max-height: calc(100vh - 317px);
  overflow-y: auto;
}
.styles_availableItems__-u3h3,
.styles_availableItemsChart__MbWYq {
  margin-top: 16px;
  margin-bottom: 61px;
}
.styles_searchInput__1sqxS input {
  border: none;
  background-color: #f4f6f7;
  padding-left: 40px !important;
  color: #8898aa;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 50px;
}
@media (max-width: 767px) {
  .styles_searchInput__1sqxS input {
    height: 40px;
  }
}
.styles_btnBlock__aY9QE {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  z-index: 2;
}
.styles_btnBlock__aY9QE button {
  margin-left: 8px;
}
.styles_spinnerContainer__25tcb {
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
.styles_goalBlock__15Xqh {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
  background: #3daeff;
}
.styles_item__F4ztP {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_item__F4ztP > div {
  margin-bottom: 25px;
}
.styles_InputNumber__HHIFF {
  height: 50px !important;
  width: 200px !important;
}
@media (max-width: 767px) {
  .styles_InputNumber__HHIFF {
    height: 40px !important;
  }
}
.styles_InputNumber__HHIFF input {
  height: 50px;
  width: 200px;
}
@media (max-width: 767px) {
  .styles_InputNumber__HHIFF input {
    height: 40px;
  }
}
.styles_range__2taMS {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.styles_range__2taMS .styles_rangeItem__21joN {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_range__2taMS .styles_rangeItem__21joN > div:first-child {
  margin-bottom: 10px;
}
.styles_dividerHeader__1c1A7 {
  font-size: 14px;
  font-weight: 700;
}
.styles_metricsValue__2HGsF {
  height: 90px;
  font-size: 40px;
  border-radius: 14px;
  font-weight: bold;
  color: #003459;
  margin-top: 20px !important;
}
@media (max-width: 767px) {
  .styles_metricsValue__2HGsF {
    height: 60px;
  }
}
.styles_metricsValue__2HGsF .styles_suffix__22-cV {
  font-size: 40px;
  color: #e1e6e9;
  font-weight: bold;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .styles_metricsValue__2HGsF .styles_suffix__22-cV {
    font-size: 22px;
  }
}
.styles_metricsValue__2HGsF input {
  padding-right: 150px !important;
  background-color: #f4f6f7;
  font-size: 40px;
  border: none;
}
@media (max-width: 767px) {
  .styles_metricsValue__2HGsF input {
    font-size: 22px;
    padding-right: 100px !important;
  }
}
.styles_metricInfo__1cQ2p {
  height: 40px;
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 14px;
  align-items: center;
  display: inline-flex;
  background-color: #f4f6f7;
  max-width: 100%;
}
.styles_commentAreaContainer__NSQmT .styles_commentArea__3J7G9 {
  height: 70px;
  resize: none;
  object-fit: contain;
  border-radius: 14px;
  background-color: #f4f6f7;
  color: #003459;
  border: none;
  margin-top: 20px;
}
.styles_commentAreaContainer__NSQmT .styles_commentArea__3J7G9 textarea {
  background-color: #f4f6f7;
  font-size: 40px;
  border: none;
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: normal;
}
.styles_quantityAvailable__GGLkq {
  font-size: 14px;
  color: #8898aa;
  text-align: right;
  padding: 5px 10px;
}
.styles_nameOfSetting__C1Vbz {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;
  color: #003459;
}
.styles_itemSetting__3v8Rv {
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .styles_itemSetting__3v8Rv {
    margin-bottom: 14px;
  }
}
.styles_headerChange__2bX0T {
  display: flex;
  align-items: center;
}
.styles_headerChange__2bX0T p {
  padding-top: 4px;
}
@media (max-width: 767px) {
  .styles_headerChange__2bX0T p {
    max-width: calc(100%);
    font-size: 16px !important;
    letter-spacing: 0.5px;
  }
}
.styles_headerChange__2bX0T button {
  border: none;
  box-shadow: unset;
  padding: 0 5px;
}
.styles_headerChange__2bX0T input {
  width: calc(100% - 60px);
  background-color: #fff;
  color: #003459;
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f4f6f7 !important;
}
.styles_headerChange__2bX0T input:focus {
  border-color: #003459 !important;
  outline: 0;
  box-shadow: unset;
}
.styles_blockOfSelects__P3BI1 {
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.styles_blockOfSelects__P3BI1 > div:first-child {
  margin-bottom: 20px;
  max-width: 100%;
}
.styles_btnControls__2fX6u {
  height: 32px !important;
}
.styles_paragraphInfo__1xFEp {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}

.styles_sanitizeHtml__qL6Qr {
  font-size: 14px;
}
.styles_sanitizeHtml__qL6Qr h1,
.styles_sanitizeHtml__qL6Qr h2,
.styles_sanitizeHtml__qL6Qr h3,
.styles_sanitizeHtml__qL6Qr h4,
.styles_sanitizeHtml__qL6Qr h5,
.styles_sanitizeHtml__qL6Qr h6 {
  font-size: 22px;
  font-weight: 700;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_sanitizeHtml__qL6Qr h2 {
  font-size: 18px;
}
.styles_sanitizeHtml__qL6Qr h3 {
  font-size: 17px;
}
.styles_sanitizeHtml__qL6Qr h4 {
  font-size: 16px;
}
.styles_sanitizeHtml__qL6Qr h5 {
  font-size: 15px;
}
.styles_sanitizeHtml__qL6Qr h6 {
  font-size: 14px;
}
.styles_sanitizeHtml__qL6Qr img {
  border-radius: 14px;
}
.styles_sanitizeHtml__qL6Qr > div {
  font-size: 14px;
}
.styles_sanitizeHtml__qL6Qr a {
  text-decoration: underline;
  font-weight: 500;
  font-style: normal;
  color: #1890ff;
}
.styles_sanitizeHtml__qL6Qr a:hover,
.styles_sanitizeHtml__qL6Qr a:focus {
  text-decoration: underline;
  font-weight: 500;
  font-style: normal;
  color: #1890ff;
}
.styles_sanitizeHtml__qL6Qr table {
  width: 100% !important;
  margin: 0 -5px;
}
.styles_sanitizeHtml__qL6Qr table td {
  width: auto !important;
  padding: 3px 5px;
  vertical-align: top;
}
.styles_sanitizeHtml__qL6Qr table td img {
  border-radius: 0px;
  /*height: 13.333vw;*/
  width: 100%;
}

.styles_openSans-300-n__ABZF5 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__2jl1A {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__2SofA {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__3ZNPP {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__3vS3I {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__1aGK4 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__2_Nyp {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__2uGIo {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__BZ1DW {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300.76b56857.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/static/media/open-sans-v15-latin-300.76b56857.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300.60c86674.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300.521d17bc.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300.177cc92d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300.505d96bd.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300italic.687596af.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(/static/media/open-sans-v15-latin-300italic.687596af.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300italic.06bbd318.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300italic.8a648ff3.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300italic.c03f38da.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300italic.b5065b4f.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-regular.cffb686d.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-regular.bf2d0783.woff) format('woff'),  url(/static/media/open-sans-v15-latin-regular.c045b73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-regular.ddaaf490.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-italic.987032ea.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-italic.db70d0b9.woff) format('woff'),  url(/static/media/open-sans-v15-latin-italic.f42df73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-italic.42762880.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600.7728dd9f.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(/static/media/open-sans-v15-latin-600.7728dd9f.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600.c062d7d1.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600.b1249dd5.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600italic.4950a720.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600italic.318ea1ad.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600italic.b5d6ad58.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600italic.adf9d003.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700.148a6749.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/static/media/open-sans-v15-latin-700.148a6749.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700.d08c09f2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700.623e3205.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700.7e08cc65.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700.9f5a7745.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700italic.437e5048.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/static/media/open-sans-v15-latin-700italic.437e5048.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700italic.c02f5da6.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700italic.72e19cbb.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700italic.cffddf70.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700italic.e8ebd85a.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800.dba530a6.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(/static/media/open-sans-v15-latin-800.dba530a6.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800.aaeffaf2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800.c6aa0c4a.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800.04ee5b60.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800.45c95bf9.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800italic.6b3973ff.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800italic.79b58175.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800italic.9855a8fd.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800italic.130bf843.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/static/media/lato-v14-latin-100.b859abc8.eot);
  /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'), url(/static/media/lato-v14-latin-100.b859abc8.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-100.f8028e61.woff2) format('woff2'),  url(/static/media/lato-v14-latin-100.752c2022.woff) format('woff'),  url(/static/media/lato-v14-latin-100.4d89307c.ttf) format('truetype'),  url(/static/media/lato-v14-latin-100.66c26530.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/lato-v14-latin-300.fc1bb181.eot);
  /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'), url(/static/media/lato-v14-latin-300.fc1bb181.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-300.bbbd26ae.woff2) format('woff2'),  url(/static/media/lato-v14-latin-300.ba2452bd.woff) format('woff'),  url(/static/media/lato-v14-latin-300.49d84f82.ttf) format('truetype'),  url(/static/media/lato-v14-latin-300.a3fb8786.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/lato-v14-latin-700.5bfc01b7.eot);
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'), url(/static/media/lato-v14-latin-700.5bfc01b7.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-700.7fbbfd16.woff2) format('woff2'),  url(/static/media/lato-v14-latin-700.96759e32.woff) format('woff'),  url(/static/media/lato-v14-latin-700.f467f2d0.ttf) format('truetype'),  url(/static/media/lato-v14-latin-700.7470c61a.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/lato-v14-latin-900.dec6ce78.eot);
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'), url(/static/media/lato-v14-latin-900.dec6ce78.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-900.f2de2c6e.woff2) format('woff2'),  url(/static/media/lato-v14-latin-900.d79dd066.woff) format('woff'),  url(/static/media/lato-v14-latin-900.281e5e87.ttf) format('truetype'),  url(/static/media/lato-v14-latin-900.c4c24d2a.svg#Lato) format('svg');
  /* Legacy iOS */
}
.styles_headerNoSelected__1-T0v {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding-top: 8px;
  margin-bottom: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.styles_chartContainer__3iNLa {
  margin-bottom: 20px;
}
.styles_chartContainer__3iNLa > div > div:nth-child(2) {
  padding-top: 0;
}
.styles_addOne__1_G1F {
  padding-bottom: 24px;
}
.styles_paragraphInfo__2_7kF {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_spinnerContainer__1uMkJ {
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
.styles_Metricsheader__16ypW {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  color: #003459;
}
@media (max-width: 767px) {
  .styles_Metricsheader__16ypW {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 8px;
    display: none;
  }
}
.styles_MainMetricsHeader__1KkWQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles_AddNewChartBtn__2ylnz {
  background-color: transparent !important;
}
@media (max-width: 767px) {
  .styles_AddNewChartBtn__2ylnz {
    margin-bottom: 8px;
  }
}

.styles_openSans-300-n__jJV7o {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__3JCxp {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n___T5i7 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__27Pou {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__20Eox {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__YF6AD {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__2jmGH {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__Mp_KA {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__NSNjP {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__Yy_v1 {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
}
@media (max-width: 767px) {
  .styles_header__Yy_v1 {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_certainCategoryIcon__39BFr svg {
  font-size: 20px;
  color: #8898aa !important;
}
.styles_searchInput__zL6y9 {
  width: 100%;
}
.styles_searchInput__zL6y9 input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__375Ou {
  margin-bottom: 24px;
  position: relative;
  max-width: 900px;
}
.styles_searchInputBlock__375Ou .styles_suggestionsList__hGFo0 {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__375Ou .styles_suggestionsList__hGFo0 > div {
  background: #ffffff !important;
}
.styles_searchInput__zL6y9 input {
  border: unset;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
.styles_searchInput__zL6y9 input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_containerEncyclopedia__35MdG,
.styles_containerEncyclopediaEntry__3EET- {
  max-width: 900px;
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_cardHeaderBlock__7p71f {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.styles_cardHeaderBlock__7p71f .styles_cardHeader__1CrP5 {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_tags__2tKfy {
  width: 500px;
}
.styles_tag__3Vq0S {
  height: 40px !important;
}
.styles_paragraphInfo__3zLkA {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_symptomInfoBlock__145zJ {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.styles_infoUser__3huQn {
  display: flex;
  height: 70px;
  min-width: 320px;
  flex-shrink: 0;
}
.styles_infoUser__3huQn .styles_avatar__3wT_8 {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f6f7;
  border-radius: 50%;
  margin-right: 16px;
}
.styles_infoUser__3huQn .styles_avatar__3wT_8 svg {
  font-size: 70px;
}
.styles_selectBlock__1yhOP {
  width: 234.55px;
}
.styles_selectBlock__1yhOP .styles_selectBlock1__1w2Ve {
  margin-bottom: 10px;
}
.styles_selectBlock2__2Ys1n {
  display: flex;
}
.styles_selectBlock2__2Ys1n .styles_selectWrap__2mMy- {
  margin-right: 10px;
}
.styles_wrapCustomCard__1vxvp {
  margin-bottom: 25px;
}
.styles_customSelect__3wbPk div {
  font-size: 14px;
}
.styles_customSelect__3wbPk > div {
  background: #f4f6f7;
  border: unset;
}
.styles_itemCause__2sgmM {
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
}
.styles_itemCause__2sgmM:hover {
  background: #f4f6f7;
  cursor: pointer;
}
.styles_itemCause__2sgmM:hover .styles_nameCause__2bcU4 {
  text-decoration: underline;
}
.styles_percentBlock__2jVxK {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
}
.styles_nameCause__2bcU4 {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.styles_header__Yy_v1 {
  color: #003459;
}
.styles_header__Yy_v1 .styles_percentBlock__2jVxK + div {
  font-size: 14px;
}
.styles_headerDrawer__fmoxm {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
}
.styles_headerDrawer__fmoxm p {
  margin: 0 10px;
}
.styles_headerDrawer__fmoxm button {
  box-shadow: unset;
  border: unset;
  background: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 24px;
  width: 24px;
  padding: 0;
}
.styles_headerCause__MqktE {
  margin: 0;
  font-size: 22px;
}
.styles_infoCause__1nrZM {
  margin: 24px 0;
}
.styles_cardWrapperNewMetric__15pT8 {
  cursor: auto;
  cursor: initial;
  padding-bottom: 24px;
}
.styles_cardWrapperNewMetric__15pT8 .styles_infoCausePercent__2kuBp {
  margin-top: 0;
}
.styles_cardWrapperNewMetric__15pT8 .styles_headerInfo__16aRM,
.styles_cardWrapperNewMetric__15pT8 .styles_headerInfoNoSelected__3SgAd {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__15pT8 .styles_headerInfoNoSelected__3SgAd {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__15pT8 .styles_selectedMetrics__3ggGy {
  margin-bottom: 38px;
}
.styles_cardWrapperNewMetric__15pT8 .styles_selectedMetricHeader__lFyPS {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_btnBlock__2hR7d {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
}
.styles_btnBlock__2hR7d button {
  margin-left: 8px;
}
.styles_causeItem__3he0v {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__3he0v > div {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_causeItem__3he0v svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_userInfoDrawer__2ieds {
  margin-bottom: 40px;
}
.styles_percentBlockDrawer__1L29P {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .styles_symptomInfoBlock__145zJ {
    flex-direction: column-reverse;
  }
  .styles_tags__2tKfy {
    width: 100%;
  }
}
.styles_infoCategory__1GFg_ {
  font-size: 12px;
  font-style: italic;
}
.styles_imgAvatar__lqN1U {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.styles_openSans-300-n__1fS6- {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__1M2g6 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__2lNEG {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__138k8 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__2Rql9 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__Jk9ns {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__UHQ48 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__25G2_ {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__q0p93 {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_mainDocuments__4007r {
  width: 100%;
  /*max-width: 894px;*/
}
.styles_navigation__17Igj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.styles_navigationBreadCump__3T0rN {
  color: #003459 !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  cursor: pointer;
}
.styles_navigationBreadCump__3T0rN span:last-child a {
  color: #003459 !important;
}
.styles_typeBox__224Oq {
  border: 2px solid #457F46;
  border-radius: 12px;
  width: 84px;
  padding: 7px;
  height: 115px;
  position: relative;
  text-align: center;
}
@media (max-width: 1087px) {
  .styles_typeBox__224Oq {
    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    width: 84px;
    padding: 7px;
    height: 115px;
    position: relative;
    margin-right: 15px;
    text-align: center;
  }
}
.styles_typeBox__224Oq h3 {
  font-size: 14px;
  line-height: normal;
  margin: 0;
  color: #457F46;
  font-weight: 600;
  text-transform: uppercase;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_typeBox__224Oq h3 {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: #457f46;
    font-weight: 600;
    text-transform: uppercase;
    word-break: break-word;
  }
}
.styles_typeBox__224Oq span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 7px 7px;
  margin: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-weight: 500;
  line-height: 1;
  min-height: 22px;
}
@media (max-width: 767px) {
  .styles_typeBox__224Oq span {
    font-size: 12px;
  }
}
.styles_typeBox__224Oq svg {
  width: 60px;
  height: 60px;
  margin-top: 7px;
  fill: #457F46;
}
.styles_uploadBtn__1iu2t {
  text-align: right;
  margin-bottom: 50px;
}
.styles_uploadBtn__1iu2t button {
  max-width: 140px;
  width: 100%;
}
.styles_header__3Zemh {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
}
@media (max-width: 767px) {
  .styles_header__3Zemh {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_certainCategoryIcon__3x22P svg {
  font-size: 20px;
  color: #8898aa !important;
}
.styles_searchInput__Q7Es4 {
  width: 100%;
}
.styles_searchInput__Q7Es4 input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__2m9qa {
  margin-bottom: 24px;
  position: relative;
  max-width: 900px;
}
.styles_searchInputBlock__2m9qa .styles_suggestionsList__1mrru {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__2m9qa .styles_suggestionsList__1mrru > div {
  background: #ffffff !important;
}
.styles_searchInput__Q7Es4 input {
  border: unset;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
.styles_searchInput__Q7Es4 input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__Q7Es4 input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_containerEncyclopedia__2w2rc,
.styles_containerEncyclopediaEntry__1oc3M {
  max-width: 900px;
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_cardHeaderBlock__2DHEm {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.styles_cardHeaderBlock__2DHEm .styles_cardHeader__1muU5 {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_tags__3xxLy {
  width: 500px;
}
.styles_tag__1UrBp {
  height: 40px !important;
}
.styles_paragraphInfo__3ooEX {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_symptomInfoBlock__2os0E {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.styles_infoUser__1tyh9 {
  display: flex;
  height: 70px;
  min-width: 320px;
  flex-shrink: 0;
}
.styles_infoUser__1tyh9 .styles_avatar__15xmX {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f6f7;
  border-radius: 50%;
  margin-right: 16px;
}
.styles_infoUser__1tyh9 .styles_avatar__15xmX svg {
  font-size: 70px;
}
.styles_selectBlock__1bfJ- {
  width: 234.55px;
}
.styles_selectBlock__1bfJ- .styles_selectBlock1__2gfsJ {
  margin-bottom: 10px;
}
.styles_selectBlock2__3culY {
  display: flex;
}
.styles_selectBlock2__3culY .styles_selectWrap__GtbOa {
  margin-right: 10px;
}
.styles_wrapCustomCard__30Wh7 {
  margin-bottom: 25px;
}
.styles_customSelect__1kXsP div {
  font-size: 14px;
}
.styles_customSelect__1kXsP > div {
  background: #f4f6f7;
  border: unset;
}
.styles_itemCause__yUKq- {
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
}
.styles_itemCause__yUKq-:hover {
  background: #f4f6f7;
  cursor: pointer;
}
.styles_itemCause__yUKq-:hover .styles_nameCause__2LgF0 {
  text-decoration: underline;
}
.styles_percentBlock__3eL3e {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
}
.styles_nameCause__2LgF0 {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.styles_header__3Zemh {
  color: #003459;
}
.styles_header__3Zemh .styles_percentBlock__3eL3e + div {
  font-size: 14px;
}
.styles_headerDrawer__CCsY6 {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
}
.styles_headerDrawer__CCsY6 p {
  margin: 0 10px;
}
.styles_headerDrawer__CCsY6 button {
  box-shadow: unset;
  border: unset;
  background: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 24px;
  width: 24px;
  padding: 0;
}
.styles_headerCause__14K-q {
  margin: 0;
  font-size: 22px;
}
.styles_infoCause__15qjx {
  margin: 24px 0;
}
.styles_cardWrapperNewMetric__32E5- {
  cursor: auto;
  cursor: initial;
  padding-bottom: 24px;
}
.styles_cardWrapperNewMetric__32E5- .styles_infoCausePercent__3Swq2 {
  margin-top: 0;
}
.styles_cardWrapperNewMetric__32E5- .styles_headerInfo__1wzZS,
.styles_cardWrapperNewMetric__32E5- .styles_headerInfoNoSelected__AvgD2 {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__32E5- .styles_headerInfoNoSelected__AvgD2 {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__32E5- .styles_selectedMetrics__2AtMk {
  margin-bottom: 38px;
}
.styles_cardWrapperNewMetric__32E5- .styles_selectedMetricHeader__3GSKE {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_btnBlock__3vcwP {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
}
.styles_btnBlock__3vcwP button {
  margin-left: 8px;
}
.styles_causeItem__28O7P {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__28O7P > div {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_causeItem__28O7P svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_userInfoDrawer__1TgSr {
  margin-bottom: 40px;
}
.styles_percentBlockDrawer__3iRu9 {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .styles_symptomInfoBlock__2os0E {
    flex-direction: column-reverse;
  }
  .styles_tags__3xxLy {
    width: 100%;
  }
}
.styles_infoCategory__4aYKb {
  font-size: 12px;
  font-style: italic;
}
.styles_imgAvatar__1gZdn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.styles_medicalDiagnosis__3hj9D {
  background: #FEC0C0;
}
.styles_medicalImage__3UCmU {
  background: #cc58c7;
}
.styles_medicalTest__3jnSU {
  background: #FBE5C4;
}
.styles_healthReport__1f3Ry {
  background: #3cb73e;
}
.styles_prescription__1qNut {
  background: #23b7b1;
}
.styles_insurancereport__Ium6E {
  background: #D7D3FF;
}
.styles_consentandassentform__amza5 {
  background: #5fa3ff;
}
.styles_other__xBx3z {
  background: #9c9fa0;
}
.styles_typeBox__224Oq.styles_medicalDiagnosis__3hj9D {
  background: rgba(253, 130, 129, 0.5);
  border-color: #FD8281;
}
.styles_typeBox__224Oq.styles_medicalDiagnosis__3hj9D span {
  background: #FD8281;
}
.styles_typeBox__224Oq.styles_medicalDiagnosis__3hj9D svg {
  fill: #FD8281;
}
.styles_typeBox__224Oq.styles_medicalImage__3UCmU {
  background: rgba(204, 88, 199, 0.5);
  border-color: #cc58c7;
}
.styles_typeBox__224Oq.styles_medicalImage__3UCmU span {
  background: #cc58c7;
}
.styles_typeBox__224Oq.styles_medicalImage__3UCmU svg {
  fill: #cc58c7;
}
.styles_typeBox__224Oq.styles_medicalTest__3jnSU {
  background: rgba(248, 204, 137, 0.5);
  border-color: #F8CC89;
}
.styles_typeBox__224Oq.styles_medicalTest__3jnSU span {
  background: #F8CC89;
}
.styles_typeBox__224Oq.styles_medicalTest__3jnSU svg {
  fill: #F8CC89;
}
.styles_typeBox__224Oq.styles_healthReport__1f3Ry {
  background: rgba(60, 183, 62, 0.5);
  border-color: #3cb73e;
}
.styles_typeBox__224Oq.styles_healthReport__1f3Ry span {
  background: #3cb73e;
}
.styles_typeBox__224Oq.styles_healthReport__1f3Ry svg {
  fill: #3cb73e;
}
.styles_typeBox__224Oq.styles_prescription__1qNut {
  background: rgba(35, 183, 177, 0.5);
  border-color: #23b7b1;
}
.styles_typeBox__224Oq.styles_prescription__1qNut span {
  background: #23b7b1;
}
.styles_typeBox__224Oq.styles_prescription__1qNut svg {
  fill: #23b7b1;
}
.styles_typeBox__224Oq.styles_insurancereport__Ium6E {
  background: rgba(175, 168, 255, 0.5);
  border-color: #AFA8FF;
}
.styles_typeBox__224Oq.styles_insurancereport__Ium6E span {
  background: #AFA8FF;
}
.styles_typeBox__224Oq.styles_insurancereport__Ium6E svg {
  fill: #AFA8FF;
}
.styles_typeBox__224Oq.styles_consentandassentform__amza5 {
  background: rgba(95, 163, 255, 0.5);
  border-color: #5fa3ff;
}
.styles_typeBox__224Oq.styles_consentandassentform__amza5 span {
  background: #5fa3ff;
}
.styles_typeBox__224Oq.styles_consentandassentform__amza5 svg {
  fill: #5fa3ff;
}
.styles_typeBox__224Oq.styles_other__xBx3z {
  background: rgba(156, 159, 160, 0.5);
  border-color: #9c9fa0;
}
.styles_typeBox__224Oq.styles_other__xBx3z span {
  background: #9c9fa0;
}
.styles_typeBox__224Oq.styles_other__xBx3z svg {
  fill: #9c9fa0;
}
.styles_menuItemBlock__3tiHm span {
  font-weight: 600;
  font-size: 14px;
}
.styles_sharePrintBlock__2R0fS {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.styles_sharePrintBlock__2R0fS button {
  color: #003459;
  background: transparent;
  border: unset;
  /*text-transform: capitalize;*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__2R0fS button {
    height: 35px;
    font-size: 12px;
  }
}
.styles_sharePrintBlock__2R0fS button svg {
  font-size: 20px;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__2R0fS button svg {
    font-size: 16px;
  }
}
.styles_center__20-uK {
  text-align: center;
  margin: 0 auto;
}
.styles_modalFooter__3L-3N {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
  margin: 48px -24px -24px;
}
.styles_modalFooter__3L-3N button + button {
  margin-left: 8px;
}

.styles_openSans-300-n__2Q2u_ {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__12ZX5 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__1rM1W {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__11Gva {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__1Avpq {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__2TiW5 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__1oBYo {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__2lu_Y {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__2sgp- {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
/*  CUSTOMIZATION  */
.styles_containerEncyclopediaEntry__2W4MW h1 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
  color: #003459;
}
@media (max-width: 767px) {
  .styles_containerEncyclopediaEntry__2W4MW h1 {
    margin-top: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}
.styles_containerEncyclopediaEntry__2W4MW h2 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.styles_containerEncyclopediaEntry__2W4MW h3 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.styles_containerEncyclopediaEntry__2W4MW h4 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #8898aa;
}
.styles_containerEncyclopediaEntry__2W4MW p {
  font-family: Open Sans, Verdana;
  font-size: 14px !important;
  margin-top: 0;
  margin-bottom: 10px;
}
.styles_containerEncyclopediaEntry__2W4MW li {
  font-family: Open Sans, Verdana;
  font-size: 14px;
  margin-top: 0;
}
.styles_containerEncyclopediaEntry__2W4MW table caption {
  color: #888;
  font-size: smaller;
  font-style: italic;
  padding: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.styles_containerEncyclopediaEntry__2W4MW table,
.styles_containerEncyclopediaEntry__2W4MW th,
.styles_containerEncyclopediaEntry__2W4MW td {
  border: 1px solid #ddd;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
}
.styles_containerEncyclopediaEntry__2W4MW table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.styles_containerEncyclopediaEntry__2W4MW table tr:hover {
  background-color: #ddd;
}
.styles_containerEncyclopediaEntry__2W4MW table thead th {
  padding-top: 12px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #003459;
  color: white;
}
.styles_containerEncyclopediaEntry__2W4MW table tfoot th {
  text-align: left;
  border-top: 4px solid #ddd;
  background-color: #fff;
}
/*  CUSTOMIZATION  */
.styles_containerEncyclopediaEntry__2W4MW .styles_header__2tCsT {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #003459;
  /*text-transform: capitalize;  */
}
@media (max-width: 767px) {
  .styles_containerEncyclopediaEntry__2W4MW .styles_header__2tCsT {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_header__2tCsT,
.styles_mainHeader__27jKq {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #003459;
  /*text-transform: capitalize;  */
}
@media (max-width: 767px) {
  .styles_main_page__1s8k3 {
    display: none;
  }
}
.styles_mainHeader__27jKq.styles_header__2tCsT {
  font-size: 32px;
}
.styles_certainCategoryIcon__1D3Dy svg {
  font-size: 20px;
  color: #8898aa !important;
}
@media (max-width: 767px) {
  .styles_certainCategoryIcon__1D3Dy svg {
    font-size: 18px;
  }
}
.styles_searchInput__18T2T {
  width: 100%;
}
.styles_searchInput__18T2T input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__Pxdg_ {
  margin-bottom: 24px;
  position: relative;
  /*max-width: 900px; */
}
.styles_searchInputBlock__Pxdg_ .styles_suggestionsList__3DoMG {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__Pxdg_ .styles_suggestionsList__3DoMG > div {
  background: #ffffff !important;
}
.styles_searchInput__18T2T input {
  border: none;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
@media (max-width: 767px) {
  .styles_searchInput__18T2T input {
    height: 44px !important;
    font-size: 14px !important;
  }
}
.styles_searchInput__18T2T input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__18T2T input::-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__18T2T input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__18T2T input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_CustomCard__2PJ-f {
  margin-top: 32px;
}
.styles_showSuggestions__15JDb {
  top: 54px;
}
.styles_hideSuggestions__1bEeJ {
  top: -800px;
}
.styles_availableItem__29apC {
  /*border-radius: 14px;*/
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  /*height: 54px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
}
@media (max-width: 767px) {
  .styles_availableItem__29apC {
    padding: 15px 0;
  }
}
.styles_availableItem__29apC .styles_addBtn__DaZqi {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}
.styles_availableItem__29apC:hover {
  background: #f4f6f7;
  text-decoration: underline;
  cursor: pointer;
}
.styles_availableItem__29apC:hover .styles_addBtn__DaZqi {
  display: block;
}
.styles_availableItem__29apC .styles_tooltipBtn__1A-mg {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  padding: 0;
}
.styles_availableItem__29apC .styles_leftSideAvailable__1s3NM {
  display: flex;
  width: 100%;
}
.styles_noItems__IU7Oq {
  color: gray;
  font-style: italic;
  font-weight: 400;
}
.styles_cardHeaderBlock__2uIL0 {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .styles_cardHeaderBlock__2uIL0 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.styles_cardHeaderBlock__2uIL0 .styles_cardHeader__3jl57 {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_cardHeaderBlock__2uIL0 .styles_deleteBtn__1AaCU {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  height: 30px;
  /*text-transform: capitalize;*/
  margin-left: 8px;
}
@media (max-width: 767px) {
  .styles_cardHeaderBlock__2uIL0 .styles_deleteBtn__1AaCU {
    position: none;
    margin: 0;
  }
}
.styles_iconClose__3SSBJ {
  width: 14px;
  height: 14px;
}
.styles_showMore__3NPez {
  margin-top: 16px;
  cursor: pointer;
  text-align: right;
}
.styles_showMore__3NPez p {
  display: inline-block;
  font-size: 13px;
  color: #8898aa;
  font-weight: 600;
}
.styles_showMore__3NPez p:hover {
  color: #507196;
}
.styles_navigation__3wqWH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.styles_sharePrintBlock__2-a3y {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__2-a3y {
    margin-top: 0;
    margin-bottom: 8px;
  }
}
.styles_sharePrintBlock__2-a3y button {
  color: #003459;
  background: transparent;
  border: none;
  /*text-transform: capitalize;*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}
.styles_sharePrintBlock__2-a3y button svg {
  font-size: 20px;
}
.styles_navigationBreadCump__3j7Y2 {
  color: #003459 !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  cursor: pointer;
}
.styles_navigationBreadCump__3j7Y2 span:last-child a {
  color: #003459 !important;
}
.styles_containerEncyclopedia__2evyO,
.styles_containerEncyclopediaEntry__2W4MW {
  /*max-width: 900px;*/
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_blockWithImagesOfSick__2mitT {
  display: flex;
}
.styles_blockWithImagesOfSick__2mitT p {
  margin-right: 20px;
}
.styles_blockWithImagesOfSick__2mitT img {
  max-width: 100%;
}
@media (max-width: 767px) {
  .styles_blockWithImagesOfSick__2mitT img {
    max-width: 100%;
    height: auto;
  }
}
.styles_imageBlock__hN5CS,
.styles_spinnerContainer__J8hhD {
  float: right;
  margin-left: 54px;
}
.styles_spinnerContainer__J8hhD {
  width: 320px;
  height: 320px;
}
.styles_imageBlock__hN5CS,
.styles_imageBlock__hN5CS img {
  border-radius: 14px;
  max-width: 320px;
  max-height: 320px;
}
.styles_imageBlock__hN5CS {
  max-height: 360px;
}
.styles_show__1mnO3 {
  display: block;
}
.styles_hide__31fPv {
  display: none;
}
.styles_ModelBlock__1tIEu {
  max-width: 320px;
  max-height: 320px;
  cursor: move;
}
.styles_ModelBlock__1tIEu img {
  max-width: 320px;
  max-height: 320px;
}
.styles_controlPanel__2tWTX {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.styles_controlPanel__2tWTX .styles_slider__2ZY41 {
  width: 260px;
}
.styles_controlPanel__2tWTX button {
  border: none;
  box-shadow: none;
  max-width: 25px;
}
.styles_controlPanel__2tWTX button:focus {
  color: none;
  border-color: none;
  outline: none !important;
}
.styles_controlPanel__2tWTX div.styles_icons__2qVYH {
  display: flex;
  align-items: center;
}
.styles_controlPanel__2tWTX div.styles_icons__2qVYH svg {
  font-size: 25px;
  cursor: pointer;
}
.styles_EncyclopediaHeader__3DtQ6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .styles_EncyclopediaHeader__3DtQ6 {
    display: block;
  }
}
.styles_spinnerContainer__J8hhD {
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
.styles_menuItemBlock__zbN8b span {
  font-weight: 600;
  font-size: 14px;
}
.styles_center__1SyHi {
  margin: 0 auto;
  text-align: center;
}
.styles_maxWidth__1RXY_ {
  max-width: 100%;
}
.styles_description__3uU1- {
  margin-top: 0px;
}
.styles_footerClass__3YfEi {
  margin-bottom: 0px;
}
.styles_searchDetail__2hZHk {
  display: block;
  text-align: right;
  padding-right: 5px;
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
}
.styles_containerEncyclopediaEntry__2W4MW .styles_customText__3AV3y {
  color: #8898aa !important;
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  margin-top: 10px;
}
.styles_showMoreResult__2LEsy {
  background: #335889;
  color: #fff !important;
  border-radius: 0 !important;
}
.styles_causeItem__2g52s {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__2g52s svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_info__3LNg2 {
  display: flex;
  align-items: center;
}
.styles_info__3LNg2 span {
  margin-right: 5px;
}
.styles_antdDropDown__3KdxP {
  height: calc(100vh - 100px);
}

.styles_center__2QwAS {
  margin: 0 auto;
  text-align: center;
}
.styles_maxSize__1w9WH {
  max-width: 900px;
}
.styles_header__3vKGh,
.styles_mainHeader__2PkNr {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #003459;
  /*text-transform: capitalize;  */
}
@media (max-width: 767px) {
  .styles_main_page__3sbLR {
    display: none;
  }
}
h1 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  h1 {
    margin-top: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}

.styles_openSans-300-n__ICp7b {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__36ZCU {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__1C2I1 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__2kmgC {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__1_INS {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__le718 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__btzvN {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__1RMAv {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__1w3r0 {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_containerHomeNewsEntry__2MozE h1 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
  color: #003459;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_containerHomeNewsEntry__2MozE h1 {
    margin-top: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}
.styles_containerHomeNewsEntry__2MozE h1.styles_headerTopTitle__wz57- {
  flex: 1 1;
  margin: 0;
}
@media (max-width: 767px) {
  .styles_containerHomeNewsEntry__2MozE h1.styles_headerTopTitle__wz57- {
    width: 100%;
  }
}
.styles_containerHomeNewsEntry__2MozE h2 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.styles_containerHomeNewsEntry__2MozE h3 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.styles_containerHomeNewsEntry__2MozE h4 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #8898aa;
}
.styles_containerHomeNewsEntry__2MozE p,
.styles_containerHomeNewsEntry__2MozE div {
  font-family: Open Sans, Verdana;
  font-size: 14px !important;
  padding: 0;
}
.styles_containerHomeNewsEntry__2MozE p:focus,
.styles_containerHomeNewsEntry__2MozE div:focus {
  outline: none;
}
.styles_containerHomeNewsEntry__2MozE p {
  margin-top: 10px;
}
.styles_containerHomeNewsEntry__2MozE li {
  font-family: Open Sans, Verdana;
  font-size: 14px;
  margin-top: 0;
}
.styles_containerHomeNewsEntry__2MozE .styles_date__3bJbb {
  color: #8898aa;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 5px;
}
.styles_containerHomeNewsEntry__2MozE table tr:last-child td {
  /*border-top: 4px solid #ddd;*/
}
.styles_containerHomeNewsEntry__2MozE table caption {
  color: #888;
  font-size: smaller;
  font-style: italic;
  padding: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.styles_containerHomeNewsEntry__2MozE table,
.styles_containerHomeNewsEntry__2MozE th,
.styles_containerHomeNewsEntry__2MozE td {
  border: 1px solid #ddd;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.styles_containerHomeNewsEntry__2MozE table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.styles_containerHomeNewsEntry__2MozE table tr:hover {
  background-color: #ddd;
}
.styles_containerHomeNewsEntry__2MozE table thead th {
  padding-top: 12px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #003459;
  color: white;
}
.styles_containerHomeNewsEntry__2MozE table tfoot th {
  text-align: left;
  border-top: 4px solid #ddd;
  background-color: #fff;
}
.styles_HomeNewsEntryHeader__2DLcm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0 !important;
}
@media (max-width: 500px) {
  .styles_HomeNewsEntryHeader__2DLcm {
    display: block;
  }
}
.styles_HomeNewsEntryHeader__2DLcm .styles_navigation__-pzwM {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  flex: 1 1;
}
.styles_HomeNewsEntryHeader__2DLcm .styles_navigation__-pzwM .styles_sharePrintBlock__37QoC {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .styles_HomeNewsEntryHeader__2DLcm .styles_navigation__-pzwM .styles_sharePrintBlock__37QoC {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.styles_HomeNewsEntryHeader__2DLcm .styles_navigation__-pzwM .styles_sharePrintBlock__37QoC button {
  margin-right: 0;
}
.styles_blockWithImagesOfSick__2rno6 p {
  margin-bottom: 0px;
}
.styles_blockWithImagesOfSick__2rno6 img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .styles_blockWithImagesOfSick__2rno6 img {
    max-width: 100%;
    height: auto;
  }
}
.styles_contentDetailLeft__2kVAc {
  margin-top: 0;
}
.styles_homeRight__3aN1y {
  padding-left: 45px;
  margin-right: -25px;
}
@media (max-width: 1199px) {
  .styles_homeRight__3aN1y {
    margin-top: 46px;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .styles_homeRight__3aN1y {
    margin-top: 36px;
    margin-right: 0;
  }
}
.styles_homeRight__3aN1y .styles_ant-card-head-title__26Kpm h1 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding: 8px 0 0;
  margin-bottom: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: #003459;
}
@media (max-width: 767px) {
  .styles_homeRight__3aN1y .styles_ant-card-head-title__26Kpm h1 {
    padding: 8px 14px 0;
  }
}
.styles_homeRight__3aN1y .styles_ant-card__1zLMn div {
  min-height: 2px !important;
}
.styles_homeRight__3aN1y .styles_HomeGraphGrid__1OjCj {
  margin-bottom: 25px;
  padding-right: 25px;
}
@media (max-width: 767px) {
  .styles_homeRight__3aN1y .styles_HomeGraphGrid__1OjCj {
    padding-right: 0;
  }
}
.styles_homeRight__3aN1y .styles_HomeGraphGrid__1OjCj .styles_antd-card__oMTRB .styles_ant-card-head__M3kir {
  min-height: 2px !important;
}
.styles_homeRight__3aN1y ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.styles_homeRight__3aN1y ul li {
  margin-bottom: 25px;
  margin-right: 25px;
}
.styles_homeRight__3aN1y ul li .styles_customCardContainer__3yUiM .styles_ant-card-head__M3kir {
  padding: 0 24px;
}
.styles_homeRight__3aN1y ul .styles_HomeAddNewChartBtn__2VM-V {
  background: transparent;
  margin-bottom: 20px;
  width: 200px;
}
.styles_containerHomeNewsEntry__2MozE .styles_header__3hWO9 {
  font-style: normal;
  /*text-transform: capitalize;  */
  padding: 26px 22px;
  bottom: 0;
  width: 100%;
  word-break: break-word;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  margin: 0;
  text-shadow: 2px 3px 3px #003459;
  /*&:after {
      content: "";
      background: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }*/
}
@media (max-width: 767px) {
  .styles_containerHomeNewsEntry__2MozE .styles_header__3hWO9 {
    font-size: 28px;
    display: block;
    font-weight: 700;
    padding: 22px;
  }
}
.styles_containerHomeNewsEntry__2MozE .styles_header__3hWO9 mark {
  position: relative;
  z-index: 1;
  background: transparent;
  color: #fff;
  padding: 0;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_containerHomeNewsEntry__2MozE .styles_header__3hWO9 mark {
    padding: 0;
  }
}
.styles_header__3hWO9 {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_header__3hWO9 {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
  }
}
.styles_headerTopTitle__wz57- {
  font-size: 32px;
  font-weight: 700;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_headerTopTitle__wz57- {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
  }
}
.styles_certainCategoryIcon__3xSeP svg {
  font-size: 20px;
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 {
  width: 100%;
}
.styles_searchInput__2Lxp7 input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__gFzEA {
  margin-bottom: 24px;
  position: relative;
  max-width: 900px;
}
.styles_searchInputBlock__gFzEA .styles_suggestionsList__13FvD {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__gFzEA .styles_suggestionsList__13FvD > div {
  background: #ffffff !important;
}
.styles_menuItemBlock__3xu32 span {
  font-weight: 600;
  font-size: 14px;
}
.styles_searchInput__2Lxp7 input {
  border: none;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
@media (max-width: 767px) {
  .styles_searchInput__2Lxp7 input {
    height: 44px !important;
    font-size: 14px !important;
  }
}
.styles_searchInput__2Lxp7 input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input::-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input::-ms-input-placeholder {
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input::placeholder {
  color: #8898aa !important;
}
.styles_searchInput__2Lxp7 input:-ms-input-placeholder {
  color: #8898aa !important;
}
.styles_containerEncyclopedia__2TbVT,
.styles_containerEncyclopediaEntry__2rQzF {
  max-width: 900px;
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_cardHeaderBlock__1xNHC {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.styles_cardHeaderBlock__1xNHC .styles_cardHeader__2YSdC {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_tags__19OfP {
  width: 500px;
}
.styles_tag__xVmdj {
  height: 40px !important;
}
.styles_paragraphInfo__2Lhbg {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_symptomInfoBlock__rCxys {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.styles_infoUser__3TNAO {
  display: flex;
  height: 70px;
  min-width: 320px;
  flex-shrink: 0;
}
.styles_infoUser__3TNAO .styles_avatar__20NZg {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f6f7;
  border-radius: 50%;
  margin-right: 16px;
}
.styles_infoUser__3TNAO .styles_avatar__20NZg svg {
  font-size: 70px;
}
.styles_selectBlock__3BRu5 {
  width: 234.55px;
}
.styles_selectBlock__3BRu5 .styles_selectBlock1__2w4EJ {
  margin-bottom: 10px;
}
.styles_selectBlock2__yaewP {
  display: flex;
}
.styles_selectBlock2__yaewP .styles_selectWrap__2ARkN {
  margin-right: 10px;
}
.styles_wrapCustomCard__3UXIK {
  margin-bottom: 25px;
}
.styles_customSelect__3BaNH div {
  font-size: 14px;
}
.styles_customSelect__3BaNH > div {
  background: #f4f6f7;
  border: none;
}
.styles_itemCause__1cdqP {
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
}
.styles_itemCause__1cdqP:hover {
  background: #f4f6f7;
  cursor: pointer;
}
.styles_itemCause__1cdqP:hover .styles_nameCause__3AmGP {
  text-decoration: underline;
}
.styles_percentBlock__Z-wjh {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
}
.styles_nameCause__3AmGP {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.styles_header__3hWO9 {
  color: #003459;
}
.styles_header__3hWO9 .styles_percentBlock__Z-wjh + div {
  font-size: 14px;
}
.styles_headerDrawer__1GJFn {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
}
.styles_headerDrawer__1GJFn p {
  margin: 0 10px;
}
.styles_headerDrawer__1GJFn button {
  box-shadow: none;
  border: none;
  background: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 24px;
  width: 24px;
  padding: 0;
}
.styles_headerCause__2cW8r {
  margin: 0;
  font-size: 22px;
}
.styles_infoCause__37hpp {
  margin: 24px 0;
}
.styles_cardWrapperNewMetric__3U9mW {
  cursor: auto;
  cursor: initial;
  padding-bottom: 24px;
}
.styles_cardWrapperNewMetric__3U9mW .styles_infoCausePercent__E_Dvc {
  margin-top: 0;
}
.styles_cardWrapperNewMetric__3U9mW .styles_headerInfo__19M07,
.styles_cardWrapperNewMetric__3U9mW .styles_headerInfoNoSelected__hVgV6 {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__3U9mW .styles_headerInfoNoSelected__hVgV6 {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__3U9mW .styles_selectedMetrics__1iGMH {
  margin-bottom: 38px;
}
.styles_cardWrapperNewMetric__3U9mW .styles_selectedMetricHeader__scSEd {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_btnBlock__2ovfm {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
}
.styles_btnBlock__2ovfm button {
  margin-left: 8px;
}
.styles_causeItem__EYVdE {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__EYVdE > div {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_causeItem__EYVdE svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_userInfoDrawer__SmfE- {
  margin-bottom: 40px;
}
.styles_percentBlockDrawer__kRvv5 {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .styles_symptomInfoBlock__rCxys {
    flex-direction: column-reverse;
  }
  .styles_tags__19OfP {
    width: 100%;
  }
}
.styles_infoCategory__2kyuG {
  font-size: 12px;
  font-style: italic;
}
.styles_imgAvatar__2O4Nm {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.styles_searchList__3xJ1M {
  display: flex;
  margin-top: 24px;
}
.styles_searchList__3xJ1M .styles_searchListBtn__1-LIA {
  border: 1px solid #003459;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: 600;
  color: #003459;
  margin-right: 10px;
  background: transparent;
  padding: 0 12px;
}
.styles_searchList__3xJ1M .styles_searchListBtn__1-LIA .styles_searchListBtnIcon__2wRJL {
  font-size: 16px;
}
.styles_sharePrintBlock__37QoC {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /*@media (max-width: 1199px) { 
    display: block;
  }*/
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__37QoC {
    margin-bottom: 10px;
  }
}
.styles_sharePrintBlock__37QoC button {
  color: #003459;
  background: transparent;
  border: none;
  /*text-transform: capitalize;*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__37QoC button {
    height: 35px;
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .styles_sharePrintBlock__37QoC button {
    padding: 0 10px;
  }
}
.styles_sharePrintBlock__37QoC button .styles_homeNewslike_btn__2Mlot {
  margin-right: -2px;
}
.styles_sharePrintBlock__37QoC button svg {
  font-size: 20px;
}
@media (max-width: 500px) {
  .styles_sharePrintBlock__37QoC button svg {
    font-size: 16px;
  }
}
.styles_sharePrintBlock__37QoC h6 {
  font-size: 13px;
  margin: 0 0 5px 5px;
  color: #8898aa;
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 400;
}
.styles_newsList__CWVfZ {
  display: block;
  max-width: 100%;
  margin-top: 22px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 25px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX {
  position: relative;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  padding: 0 25px 24px;
  margin: 0;
  text-shadow: 2px 3px 3px #003459;
}
@media (max-width: 767px) {
  .styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA {
    display: block;
    font-weight: 700;
  }
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA .styles_title__2Gkn3 {
  word-break: break-word;
  margin-bottom: 0;
  font-size: 38px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA .styles_title__2Gkn3 {
    font-size: 28px;
    line-height: 30px;
  }
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA .styles_title__2Gkn3 mark {
  background: transparent;
  color: #fff;
  line-height: 36px;
  position: relative;
  z-index: 1;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemImage__2AuSX .styles_contentDetails__1fUmA .styles_title__2Gkn3 mark {
    line-height: 30px;
  }
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb img {
  display: block;
  width: 100%;
  /*max-height: 410px;
      object-fit: cover;*/
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ {
  margin-top: 15px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_title__2Gkn3 {
  font-size: 22px;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_title__2Gkn3 {
    font-size: 20px;
    line-height: 30px;
  }
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_icon__3bOnD {
  background: transparent;
  border: none;
  font-size: 14px;
  color: #003459;
  font-weight: 600;
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 8px;
  box-shadow: none;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_icon__3bOnD i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_icon__3bOnD span {
  margin-left: 4px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_contentDetailRight__2_8uZ .styles_icon__3bOnD:hover {
  background: #F4F6F7;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE {
  padding: 17px 25px 10px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE > div {
  cursor: pointer;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_date__3bJbb {
  display: block;
  font-size: 14px;
  color: #75838B;
  line-height: 19px;
  margin-bottom: 7px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_title__2Gkn3 {
  font-size: 22px;
  line-height: 32px;
  color: #003459;
  font-weight: bold;
  word-break: break-word;
}
@media (max-width: 767px) {
  .styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_title__2Gkn3 {
    font-size: 20px;
    line-height: 30px;
  }
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_dec__39wVK {
  font-size: 16px;
  line-height: 26px;
  color: #003459;
  font-weight: 600;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_dec__39wVK > div {
  font-weight: 400;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_dec__39wVK a {
  font-weight: 500;
  color: #1890ff;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_tagsList__2oW-x {
  display: flex;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_tagsList__2oW-x .styles_tag__xVmdj {
  display: block;
  border: 1px solid #E1E6E9;
  border-radius: 40px;
  text-transform: none;
  font-size: 13px;
  line-height: 20px;
  color: #75838B;
  font-weight: 400;
  margin-right: 5px;
  height: auto;
  padding: 3px 9px;
}
.styles_newsList__CWVfZ .styles_newsListItem__bFErb .styles_newsListItemContent__ZY1aE .styles_tagsList__2oW-x .styles_tag__xVmdj:hover {
  color: #507196;
  border-color: #507196;
}
.styles_selectedItemchart__2D3oC {
  height: auto;
  flex-direction: column;
  padding: 10px 24px;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  border-radius: 14px;
  background-color: white;
}
@media (max-width: 767px) {
  .styles_selectedItemchart__2D3oC {
    padding: 10px 15px;
  }
}
.styles_metricInfoBlock__w3zfl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
}
.styles_nameOfMetric__BB9li {
  /* font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 8px 0 0;
    margin-bottom: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    color: #003459;*/
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .styles_nameOfMetric__BB9li {
    padding: 8px 14px 0;
  }
}
.styles_chartItem__1epzj {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 377px;
}
.styles_chartItem__1epzj > div {
  width: 100%;
}
.styles_chartContainer__3Nlsv {
  margin-bottom: 0px;
}
.styles_nonEnClass__3VwTY {
  color: red;
}
.styles_SingleNewspicture__3MU0E {
  margin-bottom: 0;
  position: relative;
}
.styles_SingleNewspicture__3MU0E img {
  width: 100%;
  /*max-height: 780px;
    object-fit: cover;*/
  border-radius: 16px 16px 0px 0px;
}
.styles_SingleNewspicture__3MU0E h1 {
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #003459;
  /*text-transform: capitalize;  */
  position: absolute;
  bottom: 30px;
  margin: 0 30px;
  /*background: #fff;*/
  line-height: 62px;
}
@media (max-width: 767px) {
  .styles_SingleNewspicture__3MU0E h1 {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: block;
    line-height: 48px;
  }
}
@media (max-width: 375px) {
  .styles_SingleNewspicture__3MU0E h1 {
    margin: 0 15px;
    line-height: 36px;
    bottom: 5px;
  }
}
.styles_SingleNewspicture__3MU0E h1 mark {
  /*line-height: 64px;*/
  color: #003459;
  background: #fff;
}
.styles_SingleNewsContent__1Je6u {
  padding: 15px 22px !important;
}

.styles_openSans-300-n__2J0kI {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__3KaLj {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__2jpWE {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__PgiJz {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__1SToX {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__rcSAu {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__312jv {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__pNXQ8 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__1aE8a {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_menuContainer__1xeYe {
  margin: 34px 0;
  min-height: calc(100% - 136px);
}
.styles_menuContainer__1xeYe .styles_logo__1auJD {
  color: #003459;
  font-weight: 800;
  margin-top: 0;
  font-size: 24px;
  letter-spacing: 0px;
  font-feature-settings: initial;
  font-variant-caps: initial;
  overflow: hidden;
  min-width: 27px;
  margin: 20px;
  max-height: 27px;
  transition: width 10s ease-out;
}
@media (max-width: 500px) {
  .styles_menuContainer__1xeYe .styles_logo__1auJD {
    padding-left: 2.5px;
  }
}
.styles_menuContainer__1xeYe .styles_menu__3IjmU {
  width: auto;
  height: 100%;
  display: flex;
  padding-top: 5px;
  align-items: center;
  flex-direction: column;
  border-right: none!important;
  background: transparent!important;
}
@media (max-width: 500px) {
  .styles_menuContainer__1xeYe .styles_menu__3IjmU li {
    height: 46px !important;
    padding: 0 5px !important;
  }
}
.styles_menuContainer__1xeYe .styles_menu__3IjmU li a span {
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 500px) {
  .styles_menuContainer__1xeYe .styles_menu__3IjmU li a {
    height: 46px;
  }
}
@media (max-width: 500px) {
  .styles_menuContainer__1xeYe .styles_menu__3IjmU li a i {
    font-size: 24px !important;
  }
}
.styles_contentContainer__2Z7Id {
  margin: 0;
  padding: 24px 45px;
  min-height: auto!important;
}
@media (max-width: 767px) {
  .styles_contentContainer__2Z7Id {
    padding: 0 !important;
    background: transparent;
  }
}
@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .styles_contentContainer__2Z7Id {
    padding: 24px 20px;
  }
}
.styles_menuContainer__1xeYe .styles_linkClass__1yW3g {
  height: 66px;
  font-weight: 600;
  color: #003459;
  display: flex;
  align-items: center;
}
.styles_MainresponsiveFooter__3r_12 .styles_activeLink__QIqcz {
  position: relative;
}
.styles_MainresponsiveFooter__3r_12 .styles_activeLink__QIqcz:before {
  content: "";
  position: absolute;
  left: -12.5px;
  right: 0;
  top: 0;
  bottom: 0;
  border-top: 5px solid;
  width: 50px;
  margin: 0 auto;
}
.styles_MainresponsiveFooter__3r_12 .styles_activeLink__QIqcz i {
  opacity: 1 !important;
}
.styles_menuContainer__1xeYe .styles_activeLink__QIqcz,
.styles_menuContainer__1xeYe .styles_linkClass__1yW3g:hover {
  background: #335889;
  border-radius: 12px;
  color: #fff;
  height: 100%;
}
.styles_linkClass__1yW3g[disabled] {
  opacity: 0.5;
}
.styles_sideBar__23QT0 {
  display: flex;
}
.styles_menuContainer__1xeYe .styles_navItem__1JTdc {
  height: 66px!important;
  font-weight: 900;
  padding: 0!important;
  margin: 2px 0 0 !important;
}
.styles_menuContainer__1xeYe .styles_navItem__1JTdc:active {
  background: none;
}
.styles_triggerContainer__2FS6O {
  top: 35px;
  z-index: 9;
  right: -15px;
  position: absolute;
  transition: transform 0.3s ease-out;
}
.styles_triggerBtn__2IW9S {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  color: #003459;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}
.styles_triggerBtn__2IW9S:hover {
  color: #fff;
  background: #335889;
}
.styles_rotateContainer__1CeMk {
  transform: rotate(180deg);
}
.styles_avatar__2-MXp {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 16px 21px !important;
  min-width: 34px!important;
  border: 2px solid #335889;
}
@media (max-width: 767px) {
  .styles_avatar__2-MXp {
    width: 30px;
    height: 30px;
    margin: 16px 0 !important;
    min-width: 30px!important;
    border-color: #fff;
  }
}
.styles_sidebar_class__2AJRG {
  padding: 0 20px;
  background: linear-gradient(to right, #f4f6f7, #f3f5f8) !important;
}
@media (max-width: 767px) {
  .styles_sidebar_class__2AJRG {
    display: none;
  }
}
@media (max-width: 500px) {
  .styles_sidebar_class__2AJRG {
    padding: 0px;
  }
}
.styles_layout_main__1nvO_ {
  height: 100%;
}
.styles_backButton__ZkTDh {
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
}
.styles_backButton__ZkTDh i {
  color: #0e3459;
}
.styles_width__2QEaI {
  width: 30px;
}
.styles_mobileViewHeaderTitle__17WBZ {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 0 auto 0 15px !important;
  text-align: center;
}
.styles_termsofuselink__2yitQ {
  text-align: center;
}
.styles_termsofuselink__2yitQ a {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  padding: 16px 21px;
  display: inline-block;
  word-break: break-word;
}
.styles_termsofuselink__2yitQ a:hover {
  color: #507196;
}
.styles_termsofuselink__2yitQ .styles_toslink__36WqB {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  word-break: break-word;
  padding: 16px 5px;
}
.styles_termsofuselink__2yitQ .styles_toslink__36WqB:hover {
  color: #507196;
}
.styles_open__vghD8.styles_termsofuselink__2yitQ {
  text-align: center;
}
.styles_open__vghD8.styles_termsofuselink__2yitQ a {
  padding: 16px 5px;
}

.styles_openSans-300-n__1jqho {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__1_cRW {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__3Ae2x {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__2jjjS {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__2tB-w {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__2AuTi {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__39zog {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__3h2Td {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__Pi_3z {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__3M5sR {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
@media (max-width: 767px) {
  .styles_header__3M5sR {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_searchList__nVMIw {
  display: flex;
  margin-top: 24px;
}
.styles_searchList__nVMIw .styles_searchListBtn__UOr9F {
  border: 1px solid #003459;
  font-size: 16px;
  line-height: 24px;
  text-transform: unset;
  font-weight: 600;
  color: #003459;
  margin-right: 10px;
  background: transparent;
  padding: 0 12px;
}
.styles_searchList__nVMIw .styles_searchListBtn__UOr9F .styles_searchListBtnIcon__WFhJc {
  font-size: 16px;
}
.styles_sharePrintBlock__3-L5o {
  display: flex;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__3-L5o {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .styles_sharePrintBlock__3-L5o {
    display: block;
  }
}
.styles_sharePrintBlock__3-L5o button {
  color: #003459;
  background: transparent;
  border: unset;
  /*text-transform: capitalize;*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .styles_sharePrintBlock__3-L5o button {
    height: 35px;
    font-size: 12px;
  }
}
.styles_sharePrintBlock__3-L5o button svg {
  font-size: 20px;
}
.styles_newsList__KHLnO {
  display: block;
  max-width: 100%;
  margin-top: 22px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 25px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b img {
  display: block;
  width: 100%;
  max-height: 410px;
  object-fit: cover;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE {
  padding: 25px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc {
    display: block;
  }
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc .styles_contentDetailRight__14xFI {
  margin-top: 15px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc .styles_contentDetailRight__14xFI .styles_icon__3jJlW {
  background: transparent;
  border: none;
  font-size: 14px;
  color: #003459;
  font-weight: 600;
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 8px;
  box-shadow: none;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc .styles_contentDetailRight__14xFI .styles_icon__3jJlW i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc .styles_contentDetailRight__14xFI .styles_icon__3jJlW span {
  margin-left: 4px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_contentDetails__1onvc .styles_contentDetailRight__14xFI .styles_icon__3jJlW:hover {
  background: #F4F6F7;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_date__3IALc {
  display: block;
  font-size: 14px;
  color: #75838B;
  line-height: 19px;
  margin-bottom: 7px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_title__2CXCE {
  font-size: 22px;
  line-height: 32px;
  color: #003459;
  font-weight: bold;
}
@media (max-width: 767px) {
  .styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_title__2CXCE {
    font-size: 20px;
    line-height: 30px;
  }
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_dec__2UjaU {
  font-size: 16px;
  line-height: 26px;
  color: #003459;
  font-weight: 400;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_dec__2UjaU a {
  font-weight: 600;
  color: #003459;
  display: block;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_tagsList__1BuQk {
  display: flex;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_tagsList__1BuQk .styles_tag__1L-eb {
  display: block;
  border: 1px solid #E1E6E9;
  border-radius: 40px;
  text-transform: unset;
  font-size: 13px;
  line-height: 20px;
  color: #75838B;
  font-weight: 400;
  margin-right: 5px;
  height: auto;
  padding: 3px 9px;
}
.styles_newsList__KHLnO .styles_newsListItem__1Sf4b .styles_newsListItemContent__30GEE .styles_tagsList__1BuQk .styles_tag__1L-eb:hover {
  color: #507196;
  border-color: #507196;
}
.styles_alertList__2hAHD {
  max-width: 100%;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr {
  border: 1px solid #000;
  padding: 25px;
  border-radius: 14px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr {
    display: block;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertImg__3lZBH {
  max-width: 242px;
  margin-right: 24px;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertImg__3lZBH {
    max-width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertImg__3lZBH img {
  max-width: 100%;
  width: 242px;
  height: 234px;
  object-fit: cover;
  border-radius: 14px;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertImg__3lZBH img {
    width: 100%;
    height: auto;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK {
  flex: 1 1;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK {
    margin-top: 15px;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_closeBtn__1GqSJ {
  padding: 0;
  line-height: 1;
  height: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  color: #707070;
  position: absolute;
  right: 20px;
  top: 20px;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_closeBtn__1GqSJ .styles_closeBtnIcon__2BFJ5 {
  display: block;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK > span {
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #75838B;
  margin-bottom: 5px;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_title__2CXCE {
  display: block;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_title__2CXCE {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_dec__2UjaU {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 26px;
  color: #335889;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_scheduleBtn__2Z6QA {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_scheduleBtn__2Z6QA:hover {
  opacity: 0.9;
}
@media (max-width: 767px) {
  .styles_alertList__2hAHD .styles_alertListItem__2pgSr .styles_alertContent__IfamK .styles_scheduleBtn__2Z6QA {
    height: 35px;
  }
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_blueAlert__X1b90 {
  background: #EBF3FF;
  border-color: #335889;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_blueAlert__X1b90 .styles_alertContent__IfamK .styles_title__2CXCE {
  color: #335889;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_blueAlert__X1b90 .styles_alertContent__IfamK .styles_scheduleBtn__2Z6QA {
  background: #335889;
  border-color: #335889;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_redAlert__2z-y5 {
  border-color: #E54D4C;
  background-color: #F7EDED;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_redAlert__2z-y5 .styles_alertContent__IfamK .styles_title__2CXCE {
  color: #E54D4C;
}
.styles_alertList__2hAHD .styles_alertListItem__2pgSr.styles_redAlert__2z-y5 .styles_alertContent__IfamK .styles_scheduleBtn__2Z6QA {
  background: #E54D4C;
  border-color: #E54D4C;
}
.styles_navigationBreadCump__2I4g9 {
  color: #003459 !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  cursor: pointer;
}
.styles_navigationBreadCump__2I4g9 span:last-child a {
  color: #003459 !important;
}

.styles_openSans-300-n__2HS0R {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__bcukc {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__3WC8h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__1nhcY {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__AC_bI {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__2HLxM {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__1eqVO {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__1p8lg {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__3wyF2 {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__2_RFz {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
}
@media (max-width: 767px) {
  .styles_header__2_RFz {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_alertList__2cDdr {
  max-width: 100%;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj {
  border: 1px solid #000;
  padding: 25px;
  border-radius: 14px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj {
    display: block;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertImg__1ISvV {
  max-width: 242px;
  margin-right: 24px;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertImg__1ISvV {
    max-width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertImg__1ISvV img {
  max-width: 100%;
  width: 242px;
  height: 234px;
  object-fit: cover;
  border-radius: 14px;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertImg__1ISvV img {
    width: 100%;
    height: auto;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI {
  flex: 1 1;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI {
    margin-top: 15px;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_closeBtn__j3oEt {
  padding: 0;
  line-height: 1;
  height: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  color: #707070;
  position: absolute;
  right: 20px;
  top: 20px;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_closeBtn__j3oEt .styles_closeBtnIcon__36NuU {
  display: block;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI > span {
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #75838B;
  margin-bottom: 5px;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_title__2QKyr {
  display: block;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_title__2QKyr {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_dec__3qEC8 {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 26px;
  color: #335889;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_scheduleBtn__3jsib {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_scheduleBtn__3jsib:hover {
  opacity: 0.9;
}
@media (max-width: 767px) {
  .styles_alertList__2cDdr .styles_alertListItem__1XEQj .styles_alertContent__2HBmI .styles_scheduleBtn__3jsib {
    height: 35px;
  }
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_blueAlert__2Kp2- {
  background: #EBF3FF;
  border-color: #335889;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_blueAlert__2Kp2- .styles_alertContent__2HBmI .styles_title__2QKyr {
  color: #335889;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_blueAlert__2Kp2- .styles_alertContent__2HBmI .styles_scheduleBtn__3jsib {
  background: #335889;
  border-color: #335889;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_redAlert__3Cjf4 {
  border-color: #E54D4C;
  background-color: #F7EDED;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_redAlert__3Cjf4 .styles_alertContent__2HBmI .styles_title__2QKyr {
  color: #E54D4C;
}
.styles_alertList__2cDdr .styles_alertListItem__1XEQj.styles_redAlert__3Cjf4 .styles_alertContent__2HBmI .styles_scheduleBtn__3jsib {
  background: #E54D4C;
  border-color: #E54D4C;
}

.styles_openSans-300-n__2G9Y6 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__14OrF {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__1dULO {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__1wRsH {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__I3_0P {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__2CEbY {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__3j427 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__1TJb9 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__3mMFD {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__1ko3A {
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 32px;
}
@media (max-width: 767px) {
  .styles_header__1ko3A {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_bottomPanel__geneticsForm__3TQpX,
.styles_bottomPanel__activityForm__2REvL,
.styles_bottomPanel__conditionsForm__120_p {
  height: 73px;
  padding-right: 20px;
}
.styles_form__2eMhg {
  display: flex;
}
.styles_form__2eMhg > div:last-child {
  width: 70%;
  padding: 12px 24px;
}
.styles_uploadBlock__2kcrh {
  text-align: center;
  padding-top: 15px;
}
.styles_dragWrapper__3st_1 > div:first-child {
  background: unset;
  padding: 15px;
}
.styles_labelBlock__1Zlgn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_labelBlock__1Zlgn p:nth-child(2) {
  width: 140px !important;
}
.styles_uploadButton__bbyWO {
  width: 100% !important;
  max-width: 150px;
  background: #f4f6f7;
  border-radius: 14px;
  padding: 9px 0;
  font-size: 14px !important;
  margin: 7px auto !important;
  font-weight: 600;
  color: #003459 !important;
}
@media (max-width: 767px) {
  .styles_uploadButton__bbyWO {
    margin: 7px auto 0 !important;
  }
}
.styles_profileSelect__xd_9w,
.styles_profileSelect__xd_9w > div {
  height: 50px !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459 !important;
}
@media (max-width: 767px) {
  .styles_profileSelect__xd_9w,
  .styles_profileSelect__xd_9w > div {
    height: 40px !important;
    font-size: 14px !important;
  }
}
.styles_profileSelect__xd_9w > div {
  padding-top: 8px;
}
@media (max-width: 767px) {
  .styles_profileSelect__xd_9w > div {
    padding-top: 4px;
  }
}
.styles_cardLabelProfile__rf_sR {
  color: #003459;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}
.styles_sexGenderWrapper__1xfJB {
  padding-right: 15px;
  flex-shrink: 0;
}
.styles_sexGenderWrapper__1xfJB > div {
  width: 100%;
}
.styles_historyWrapper__33oDT,
.styles_activityWrapper__hUdxU,
.styles_lifeStyleWrapper__1t74f {
  display: flex;
  flex-direction: column;
}
.styles_historyWrapper__33oDT > div,
.styles_activityWrapper__hUdxU > div,
.styles_lifeStyleWrapper__1t74f > div {
  padding-top: 20px;
  border-bottom: solid 1px #e8e8e8;
}
.styles_historyWrapper__33oDT > div:first-child,
.styles_activityWrapper__hUdxU > div:first-child,
.styles_lifeStyleWrapper__1t74f > div:first-child {
  padding-top: 0;
}
.styles_historyWrapper__33oDT > div:last-child,
.styles_activityWrapper__hUdxU > div:last-child,
.styles_lifeStyleWrapper__1t74f > div:last-child {
  border-bottom: none;
}
.styles_noData__2Nttl {
  color: #8898aa;
  margin-bottom: 0;
}
.styles_iconSearch__9V6kz svg {
  width: 24px;
  height: 24px;
  color: #8898aa;
}
.styles_searchInput__KlyD_ input {
  border: unset;
  background-color: #f4f6f7;
  padding-left: 40px !important;
  color: #8898aa;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 50px;
}
.styles_groupCheckboxes__zBAa3 {
  margin-top: 24px;
  height: 418px;
  overflow-y: auto;
}
.styles_groupCheckboxes__zBAa3 > div {
  margin-top: 10px;
}
.styles_groupCheckboxes__zBAa3 > div > label > span {
  width: 25px !important;
  height: 25px !important;
}
.styles_groupCheckboxes__zBAa3 > div > label > span > span {
  background-color: transparent !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 6px;
}
.styles_groupCheckboxes__zBAa3 > div > label > span > span::after {
  border: 2px solid #335889 !important;
  width: 8px !important;
  height: 15px !important;
  border-top: none !important;
  border-left: none !important;
}
.styles_therapiesCollapse__blvd7 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
}
.styles_therapiesCollapse__blvd7 > div {
  border: 1px solid #e8e8e8 !important;
  margin-bottom: 24px;
  border-radius: 16px !important;
  overflow: hidden;
}
.styles_therapiesCollapse__blvd7 > div > div {
  color: #003459 !important;
  line-height: 38px !important;
}
.styles_therapiesCollapse__blvd7 > div > div:nth-child(2) {
  background: #f4f6f7 !important;
  border-top: 1px solid #e8e8e8 !important;
}
.styles_lightGrayBtn__3mMFD {
  border-radius: 12px !important;
  background-color: #f4f6f7 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #003459 !important;
  border: none !important;
  height: 40px !important;
  /*text-transform: capitalize;*/
  /*width: 74px;*/
  height: 32px !important;
}
.styles_collapseLabel__2pqg1 {
  color: #8898aa;
  font-size: 14px;
  text-transform: uppercase;
}
.styles_medicationWrapper__yX66s {
  display: flex;
  margin-bottom: 15px;
}
.styles_medicationWrapper__yX66s > .styles_datePickerMedication__3TKCY {
  border: 1px solid #e8e8e8 !important;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 14px;
  line-height: normal;
  line-height: initial;
  justify-content: space-between;
  width: 300px;
  margin-right: 10px;
}
.styles_medicationInfo__82fF2.styles_medicationInfo__82fF2.styles_medicationInfo__82fF2 > input {
  font-size: 20px !important;
  padding: 0;
  border: none;
  height: 27px !important;
}
.styles_medicationInfo__82fF2 > div {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.styles_medicationInfo__82fF2 > div:first-child {
  font-size: 20px;
}
.styles_RangePicker__2Itxa {
  width: 40px;
  display: flex;
  cursor: pointer !important;
}
.styles_RangePicker__2Itxa > span {
  border: none;
}
.styles_RangePicker__2Itxa > span > input,
.styles_RangePicker__2Itxa > span > span {
  display: none;
}
.styles_doseMedication__3AdzL {
  border: 1px solid #e8e8e8 !important;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 14px;
  line-height: normal;
  line-height: initial;
  width: calc(100% - 336px);
}
.styles_removeMedication__2HjW0 {
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #8898aa;
  font-size: 14px;
}
.styles_removeMedication__2HjW0 i {
  cursor: pointer;
}
.styles_removeMedication__2HjW0 button {
  background-color: unset !important;
  border: none !important;
  padding: 0;
}
.styles_lightGrayBtnDisabled__2hLf0 {
  color: #8898aa !important;
}
.styles_plusDisabled__3dLsR {
  color: gainsboro !important;
  cursor: not-allowed !important;
}
.styles_plusEnabled__2YvJj {
  color: #8898aa;
}
.styles_activityDescription__ofi-F {
  margin-bottom: 20px;
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
}
.styles_activityWrapper__hUdxU > div {
  display: flex;
  padding: 10px 0;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .styles_activityWrapper__hUdxU > div {
    display: block;
  }
}
.styles_activityWrapper__hUdxU > div > div:first-child {
  width: 250px;
}
@media (max-width: 767px) {
  .styles_activityWrapper__hUdxU > div > div:first-child {
    width: 100%;
  }
}
.styles_activityWrapper__hUdxU > div > div:nth-child(2) {
  width: calc(100% - 350px);
}
.styles_activityWrapper__hUdxU > div > div:nth-child(3) {
  width: 10px;
}
.styles_whatHappens__3FTWP {
  color: #003459;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
  max-width: 290px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 72px;
}
.styles_note__3WXMi,
.styles_addNote__3L3EM {
  color: #8898aa;
  font-size: 14px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.styles_addNote__3L3EM button {
  font-weight: 500;
  color: #8898aa;
  border: none;
  padding: 0;
  /*text-transform: capitalize;*/
  box-shadow: unset;
  height: auto;
}
.styles_changeItem__2IKTC {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
}
.styles_changeItem__2IKTC:last-child {
  border-bottom: none;
}
.styles_locationOfChange__1o3Rl {
  /*text-transform: capitalize;*/
}
.styles_cropWrapper__3qwZ_ {
  display: flex;
  justify-content: center;
}
.styles_croppedImageContainer__3ql2M {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.styles_croppedImageContainer__3ql2M img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.styles_croppedImageRotate__3tXez {
  margin-top: 18px !important;
  margin-left: 0 !important;
  text-align: center;
}
.styles_croppedImageRotate__3tXez button {
  margin-bottom: 20px;
}
.styles_croppedImageRotate__3tXez button:first-child {
  margin-right: 15px;
}
.styles_croppedImageRotate__3tXez span {
  margin-right: 10px;
}
.styles_spinnerContainer__2fZZR {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  margin: auto;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}
.styles_ProfileActivity__3Im6N {
  margin-bottom: 25px;
}
.styles_cardAge__3_rbl {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: lowercase;
}
.styles_currentPregnant__2_O3i {
  width: 95%;
}
.styles_currentPregnant__2_O3i .styles_selectOptions__1Dpqv {
  padding-left: 60px;
}
.styles_currentPregnant__2_O3i .styles_datepicker__JmXr_ {
  margin-top: 20px;
  text-align: right;
}
.styles_ageLabel__GpUTE {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .styles_ageLabel__GpUTE .styles_cardLabelProfile__rf_sR {
    margin-bottom: 0;
  }
}
.styles_ageLabel__GpUTE .styles_cardLabelProfile__rf_sR .styles_customLabel__1Ci6S > div {
  padding: 0;
}
@media (max-width: 767px) {
  .styles_ageLabel__GpUTE .styles_cardAge__3_rbl {
    min-height: 25px;
  }
}
.styles_customLabel__1Ci6S {
  margin: 0 !important;
  line-height: 1.2 !important;
}
.styles_customLabel__1Ci6S > div {
  line-height: 1.2 !important;
}
.styles_leftbasicInfo__3sLIQ {
  padding-right: 10px;
}
.styles_rightbasicInfo__2hfs0 {
  padding-left: 10px;
}
.styles_profileSelectLevel1__1lGTq {
  padding-left: 40px;
  text-align: right;
  padding-right: 15px;
}
.styles_profileSelectLevel2__1WgGw {
  padding-left: 60px;
  text-align: right;
  padding-right: 15px;
}
.styles_profileSelectLevel3__2Wy0W {
  padding-left: 80px;
  text-align: right;
  padding-right: 15px;
}
.styles_profileSelectLevel3__2Wy0W .styles_selectOptions__1Dpqv {
  margin: 15px 0;
}
.styles_etnicWrapper__3gNmw {
  margin-top: 0;
}
.styles_historyfamilyWrapper__2uDl5 .styles_ant-tag__3DeQX {
  text-transform: none;
}
.styles_chronicConditionsWrapper__1L57Y .styles_ant-tag__3DeQX {
  text-transform: none;
}
.styles_chronicConditionsWrapper__1L57Y .styles_ant-row__VhJ2K {
  margin-bottom: 0 !important;
}
.styles_groupAccountButton__2QP-m {
  padding-top: 16px;
  margin-left: -8px;
}
@media (max-width: 767px) {
  .styles_groupAccountButton__2QP-m {
    margin-left: 0;
  }
}
.styles_groupAccountButton__2QP-m button {
  margin-top: 15px;
  margin-left: 8px;
}
@media (max-width: 767px) {
  .styles_accountBtn__Pjm2p {
    font-size: 12px !important;
    width: 100%;
    margin: 0 0 15px 0 !important;
  }
}

.styles_openSans-300-n__1sU2x {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__y7Zfa {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__3DOrg {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__32_95 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__1XKB_ {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__1AF0Z {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__1rL1E {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__3AFoq {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__2rc7m {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
.styles_header__3KWLh {
  font-size: 32px;
  color: #003459;
  margin-bottom: 24px;
  margin-top: 24px;
  /*text-transform: capitalize;*/
}
@media (max-width: 767px) {
  .styles_header__3KWLh {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
    display: none;
  }
}
.styles_certainCategoryIcon__2YG5B svg {
  font-size: 20px;
  color: #8898aa !important;
}
@media (max-width: 767px) {
  .styles_certainCategoryIcon__2YG5B svg {
    font-size: 18px;
  }
}
.styles_searchInput__1dfH5 {
  width: 100%;
}
.styles_searchInput__1dfH5 input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.styles_searchInputBlock__30bha {
  margin-bottom: 24px;
  position: relative;
  /*max-width: 900px;*/
}
.styles_searchInputBlock__30bha .styles_suggestionsList__2q9xv {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
  transition: top 0.7s ease-in-out;
}
.styles_searchInputBlock__30bha .styles_suggestionsList__2q9xv > div {
  background: #ffffff !important;
}
.styles_searchInput__1dfH5 input {
  border: none;
  background-color: #fff !important;
  padding-left: 40px !important;
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
  height: 54px !important;
}
@media (max-width: 767px) {
  .styles_searchInput__1dfH5 input {
    height: 44px !important;
    font-size: 14px !important;
  }
}
.styles_searchInput__1dfH5 input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_searchInput__1dfH5 input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.styles_containerEncyclopedia__225au,
.styles_containerEncyclopediaEntry__3_9vV {
  /*max-width: 900px;*/
  font-size: 16px;
  color: #003459;
}
p,
div {
  font-size: 16px;
  color: #003459;
}
.styles_cardHeaderBlock__1XBvx {
  position: relative;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.styles_cardHeaderBlock__1XBvx .styles_cardHeader__3jLSm {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.styles_tags__2DgUG {
  /*width: 500px;*/
  margin-right: 10px;
}
.styles_tag__2Nrvy {
  height: 40px !important;
}
.styles_paragraphInfo__1JjGk {
  color: #8898aa;
  min-height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.styles_symptomInfoBlock__uY_Hb {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: 94px;
  align-items: flex-start;
}
.styles_symptomInfoBlock__uY_Hb .styles_ant-row__3LHFU {
  width: 100% !important;
}
.styles_infoUser__Hnl8B {
  display: flex;
  justify-content: flex-end;
  height: 70px;
  /*min-width: 320px;*/
  flex-shrink: 0;
}
@media (max-width: 1199px) {
  .styles_infoUser__Hnl8B {
    height: auto;
  }
}
@media (max-width: 767px) {
  .styles_infoUser__Hnl8B {
    display: block;
  }
}
.styles_infoUser__Hnl8B .styles_avatar__34ehY {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f6f7;
  border-radius: 50%;
  margin-right: 16px;
}
@media (max-width: 767px) {
  .styles_infoUser__Hnl8B .styles_avatar__34ehY {
    margin: 0 auto 10px;
  }
}
.styles_infoUser__Hnl8B .styles_avatar__34ehY svg {
  font-size: 70px;
}
@media (max-width: 767px) {
  .styles_infoUser__Hnl8B {
    margin-top: 20px;
  }
}
.styles_selectBlock__2hyFN {
  width: 234.55px;
}
@media (max-width: 1060px) {
  .styles_selectBlock__2hyFN {
    width: auto;
  }
}
@media (max-width: 767px) {
  .styles_selectBlock__2hyFN {
    width: 100%;
  }
}
.styles_selectBlock__2hyFN .styles_selectBlock1__2Jj-D {
  margin-bottom: 10px;
}
.styles_selectBlock2__A7wv3 {
  display: flex;
}
@media (max-width: 1199px) {
  .styles_selectBlock2__A7wv3 {
    display: block;
  }
}
.styles_selectBlock2__A7wv3 .styles_selectWrap__12z92 {
  margin-right: 10px;
}
.styles_wrapCustomCard__2wD2Q {
  margin-bottom: 25px;
}
@media (max-width: 1199px) {
  .styles_femaleSelected__1zAuF {
    margin-top: 10px !important;
  }
}
.styles_customSelect__1NaRG div {
  font-size: 14px;
}
.styles_customSelect__1NaRG > div {
  background: #f4f6f7;
  border: none;
}
.styles_itemCause__2Wgbu {
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
}
.styles_itemCause__2Wgbu:hover {
  background: #f4f6f7;
  cursor: pointer;
}
.styles_itemCause__2Wgbu:hover .styles_nameCause__1wr0b {
  text-decoration: underline;
}
.styles_percentBlock__3hYYP {
  width: 59px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 14px;
  font-weight: 700;
  margin-right: 20px;
}
.styles_nameCause__1wr0b {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.styles_header__3KWLh {
  color: #003459;
}
.styles_header__3KWLh .styles_percentBlock__3hYYP + div {
  font-size: 14px;
}
.styles_headerDrawer__27t2O {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
}
.styles_headerDrawer__27t2O p {
  margin: 0 10px;
}
.styles_headerDrawer__27t2O button {
  box-shadow: none;
  border: none;
  background: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  height: 24px;
  width: 24px;
  padding: 0;
}
.styles_header__3KWLh.styles_headerCause__OsmYg {
  margin: 0;
  font-size: 22px !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
}
.styles_infoCause__1rXWC {
  margin: 24px 0;
}
.styles_cardWrapperNewMetric__F2RwE {
  cursor: auto;
  cursor: initial;
  padding-bottom: 24px;
}
@media (max-width: 767px) {
  .styles_cardWrapperNewMetric__F2RwE {
    max-height: calc(100vh - 153px);
    overflow: auto;
    padding: 24px;
  }
}
.styles_cardWrapperNewMetric__F2RwE .styles_infoCausePercent__2Ylg3 {
  margin-top: 0;
}
.styles_cardWrapperNewMetric__F2RwE .styles_headerInfo__2LzMG,
.styles_cardWrapperNewMetric__F2RwE .styles_headerInfoNoSelected__1g300 {
  color: #003459;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}
.styles_cardWrapperNewMetric__F2RwE .styles_headerInfoNoSelected__1g300 {
  background: #f4f6f7;
  padding: 10px 20px;
  border-radius: 14px;
}
.styles_cardWrapperNewMetric__F2RwE .styles_selectedMetrics__2sB4C {
  margin-bottom: 38px;
}
.styles_cardWrapperNewMetric__F2RwE .styles_selectedMetricHeader__1Ltow {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.styles_btnBlock__170OU {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
}
.styles_btnBlock__170OU button {
  margin-left: 8px;
}
.styles_causeItem__3JaD2 {
  display: flex;
  margin-bottom: 10px;
}
.styles_causeItem__3JaD2 > div {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_causeItem__3JaD2 svg {
  font-size: 24px;
  margin-right: 12px;
}
.styles_userInfoDrawer__39Grw {
  margin-bottom: 40px;
}
.styles_percentBlockDrawer__1Dmm8 {
  height: 48px;
  width: 57px;
  margin: 8px 20px 8px 0;
}
@media (max-width: 768px) {
  .styles_symptomInfoBlock__uY_Hb {
    flex-direction: column-reverse;
  }
  .styles_tags__2DgUG {
    width: 100%;
  }
}
.styles_infoCategory__1nOfB {
  font-size: 12px;
  font-style: italic;
}
.styles_imgAvatar__1MZ9A {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.styles_center__3BbF2 {
  display: flex;
  justify-content: center;
}
.styles_symptomMobileUserIcon__1HbCB {
  display: block;
}
.styles_symptomMobileSelection__3E6Ps {
  display: block;
}

.styles_openSans-300-n__3BztB {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.styles_openSans-400-n__200-7 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.styles_openSans-600-n__3k1wu {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.styles_openSans-700-n__27Tv_ {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.styles_openSans-800-n__2Dam7 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.styles_openSans-900-n__9D-UE {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.styles_lato-400-n__3kLGE {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.styles_lato-900-n__Npt_l {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.styles_lightGrayBtn__2BcO7 {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300.76b56857.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/static/media/open-sans-v15-latin-300.76b56857.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300.60c86674.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300.521d17bc.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300.177cc92d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300.505d96bd.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300italic.687596af.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(/static/media/open-sans-v15-latin-300italic.687596af.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300italic.06bbd318.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300italic.8a648ff3.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300italic.c03f38da.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300italic.b5065b4f.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-regular.cffb686d.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-regular.bf2d0783.woff) format('woff'),  url(/static/media/open-sans-v15-latin-regular.c045b73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-regular.ddaaf490.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-italic.987032ea.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-italic.db70d0b9.woff) format('woff'),  url(/static/media/open-sans-v15-latin-italic.f42df73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-italic.42762880.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600.7728dd9f.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(/static/media/open-sans-v15-latin-600.7728dd9f.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600.c062d7d1.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600.b1249dd5.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600italic.4950a720.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600italic.318ea1ad.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600italic.b5d6ad58.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600italic.adf9d003.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700.148a6749.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/static/media/open-sans-v15-latin-700.148a6749.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700.d08c09f2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700.623e3205.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700.7e08cc65.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700.9f5a7745.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700italic.437e5048.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/static/media/open-sans-v15-latin-700italic.437e5048.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700italic.c02f5da6.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700italic.72e19cbb.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700italic.cffddf70.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700italic.e8ebd85a.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800.dba530a6.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(/static/media/open-sans-v15-latin-800.dba530a6.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800.aaeffaf2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800.c6aa0c4a.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800.04ee5b60.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800.45c95bf9.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800italic.6b3973ff.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800italic.79b58175.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800italic.9855a8fd.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800italic.130bf843.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/static/media/lato-v14-latin-100.b859abc8.eot);
  /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'), url(/static/media/lato-v14-latin-100.b859abc8.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-100.f8028e61.woff2) format('woff2'),  url(/static/media/lato-v14-latin-100.752c2022.woff) format('woff'),  url(/static/media/lato-v14-latin-100.4d89307c.ttf) format('truetype'),  url(/static/media/lato-v14-latin-100.66c26530.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/lato-v14-latin-300.fc1bb181.eot);
  /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'), url(/static/media/lato-v14-latin-300.fc1bb181.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-300.bbbd26ae.woff2) format('woff2'),  url(/static/media/lato-v14-latin-300.ba2452bd.woff) format('woff'),  url(/static/media/lato-v14-latin-300.49d84f82.ttf) format('truetype'),  url(/static/media/lato-v14-latin-300.a3fb8786.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/lato-v14-latin-700.5bfc01b7.eot);
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'), url(/static/media/lato-v14-latin-700.5bfc01b7.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-700.7fbbfd16.woff2) format('woff2'),  url(/static/media/lato-v14-latin-700.96759e32.woff) format('woff'),  url(/static/media/lato-v14-latin-700.f467f2d0.ttf) format('truetype'),  url(/static/media/lato-v14-latin-700.7470c61a.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/lato-v14-latin-900.dec6ce78.eot);
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'), url(/static/media/lato-v14-latin-900.dec6ce78.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-900.f2de2c6e.woff2) format('woff2'),  url(/static/media/lato-v14-latin-900.d79dd066.woff) format('woff'),  url(/static/media/lato-v14-latin-900.281e5e87.ttf) format('truetype'),  url(/static/media/lato-v14-latin-900.c4c24d2a.svg#Lato) format('svg');
  /* Legacy iOS */
}
.styles_signIn__3No1s {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #003459;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 15px;
}
.styles_signIn__3No1s .styles_formWrapper__NTdG2 {
  width: 100%;
  max-width: 570px;
}
.styles_message__RIsEL {
  font-size: 32px;
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 51px;
}
@media (max-width: 767px) {
  .styles_message__RIsEL {
    font-size: 24px !important;
  }
}
.styles_messageInfo__rytuZ {
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}
label {
  color: #003459 !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
input {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459 !important;
}
.styles_has-error__2wMQy .styles_ant-input__3O64c,
.styles_has-error__2wMQy .styles_ant-input__3O64c:hover {
  border-color: #e54d4c !important;
}
.styles_loginFormForgot__19QZH {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  text-decoration: underline;
  font-size: 16px;
}
.styles_forgotPasswordBlock__1xdM- {
  display: flex;
  justify-content: flex-end;
  margin: 9px 2px 0 0;
}
.styles_forgotPasswordBlock__1xdM- a {
  text-decoration: unset;
  font-size: 14px;
  color: #8898aa;
}
.styles_selectLanguageBlock__2hAsN {
  margin-top: 70px;
  text-align: center;
}
@media (max-width: 767px) {
  .styles_selectLanguageBlock__2hAsN {
    margin-top: 35px;
  }
}
.styles_selectLanguageBlock__2hAsN button {
  margin-right: 4px;
  padding: 3px 10px 0px 10px;
  background: transparent;
  border: 1px solid transparent;
}
.styles_selectLanguageBlock__2hAsN button:last-child {
  margin-right: 0;
}
.styles_selectLanguageBlock__2hAsN button:hover {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.styles_spinnerContainer__1DO8J {
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.styles_spinnerContainer__1DO8J {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  margin: auto;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}
.styles_termsofuselink__1qK62 {
  text-align: center;
}
.styles_termsofuselink__1qK62 a {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  padding: 16px 21px;
  display: inline-block;
  word-break: break-word;
}
.styles_open__3hf9o.styles_termsofuselink__1qK62 a {
  padding: 16px 10px;
}
.styles_toslink__1gBDH {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  display: inline-block;
  word-break: break-word;
}
.styles_formBox__ZhEUD {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 20px 45px 24px 37px;
}
@media (max-width: 767px) {
  .styles_formBox__ZhEUD {
    padding: 20px;
  }
}
.styles_formHeader__3hi4E {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.styles_formHeader__3hi4E .styles_formHeaderLink__21swa {
  font-size: 18px;
  font-weight: 700;
  color: #8898aa;
  margin-right: 32px;
}
@media (max-width: 767px) {
  .styles_formHeader__3hi4E .styles_formHeaderLink__21swa {
    font-size: 16;
  }
}
.styles_formHeader__3hi4E .styles_formHeaderLink__21swa.styles_formHeaderLinkActive__wydMw {
  color: #003459;
}
.styles_formHeader__3hi4E .styles_formHeaderLink__21swa:last-child {
  margin-right: 0;
}
.styles_btnBlock__3Qpml {
  margin-top: 27px;
}
.styles_btnBlock__3Qpml .styles_loginFormButton__1bdw1 {
  width: 235px;
  height: 51px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .styles_btnBlock__3Qpml .styles_loginFormButton__1bdw1 {
    width: 100%;
    height: 44px;
    font-size: 14px;
  }
}

.styles_sisuContainer__1TFDU {
  display: flex;
  flex-shrink: 0;
  height: 100vh;
}
.styles_sisuContainer__1TFDU .styles_sisuContainerInner__fDDqU {
  width: 100%;
  height: 100%;
  background-color: #EDF0F3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 50px;
  overflow: auto;
}
@media (max-width: 767px) {
  .styles_sisuContainer__1TFDU .styles_sisuContainerInner__fDDqU {
    padding-top: 25px;
    padding-bottom: 80px;
  }
}

.styles_spinnerContainer__1rUjS {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  margin: auto;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #335889;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #507196;
}
a:active {
  color: #203b63;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #335889;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #335889;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #335889;
  box-shadow: 0 0 0 0 #335889;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #335889;
    box-shadow: 0 0 0 6px #335889;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #335889;
    box-shadow: 0 0 0 6px #335889;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
          animation-name: antSwingIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 14px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert.ant-alert-closable {
  padding-right: 30px;
}
.ant-alert-icon {
  position: absolute;
  top: 11.5px;
  left: 16px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border-radius: 14px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-closing {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert-slide-up-leave {
  -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@-webkit-keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #335889;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #335889;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: 32px;
  line-height: 1.5;
  background: transparent;
  border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 14px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.ant-badge-count {
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #f5222d;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #507196;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 14px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 14px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 14px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #507196;
  background-color: #fff;
  border-color: #507196;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #203b63;
  background-color: #fff;
  border-color: #203b63;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #335889;
  border-color: #335889;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #507196;
  border-color: #507196;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #203b63;
  border-color: #203b63;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #507196;
  border-left-color: #507196;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #507196;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #507196;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #507196;
  background-color: transparent;
  border-color: #507196;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #203b63;
  background-color: transparent;
  border-color: #203b63;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #507196;
  background-color: #fff;
  border-color: #507196;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #203b63;
  background-color: #fff;
  border-color: #203b63;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #335889;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #507196;
  background-color: transparent;
  border-color: #507196;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #203b63;
  background-color: transparent;
  border-color: #203b63;
}
.ant-btn-link:active > a:only-child,
.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  border-radius: 14px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 14px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 14px;
}
.ant-btn-icon-only > i {
  vertical-align: middle;
}
.ant-btn-round {
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 40px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group > .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 14px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 14px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 14px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 14px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #335889;
  background-color: transparent;
  border-color: #335889;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #507196;
  background-color: transparent;
  border-color: #507196;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #203b63;
  background-color: transparent;
  border-color: #203b63;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background-color: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9363e;
  background-color: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link {
  color: #335889;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: #507196;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: #203b63;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 38px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid #d9d9d9;
  outline: none;
}
.ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
.ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  color: #fff;
  background: #335889;
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #335889 inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #335889;
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 32px;
  line-height: 30px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #b1b8bd;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #335889;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #bdc5c9;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #335889;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 16px;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 16px 16px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 16px 16px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #335889;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #335889;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 16px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '\2190';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '\2192';
}
.ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 14px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  transition: transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  transform: rotate(180deg);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 14px 0 0 14px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 14px 14px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 14px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #bdc5c9;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #335889;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #335889;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #335889;
  border-color: #335889;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #335889;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 14px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 14px 14px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 14px 14px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-comment {
  position: relative;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #507196;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-input-disabled + .ant-calendar-picker-icon {
  cursor: not-allowed;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color: #507196;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #507196;
}
.ant-calendar-today .ant-calendar-date {
  color: #335889;
  font-weight: bold;
  border-color: #335889;
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #b1b8bd;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: '';
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 0 15px;
  color: #fff;
  background-color: #335889;
  border-color: #335889;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 14px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 14px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 14px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #507196;
  border-color: #507196;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #203b63;
  border-color: #203b63;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  transform: translateX(-50%);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: #335889;
  background: #b1b8bd;
  border-color: #335889;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #335889;
  border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #335889;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  line-height: 24px;
  border: 0;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #bdc5c9;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #bdc5c9;
}
.ant-calendar-time-picker-select li:focus {
  color: #335889;
  font-weight: 600;
  outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 14px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-month-panel-header a:hover {
  color: #507196;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
  flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #335889;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #335889;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 14px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-year-panel-header a:hover {
  color: #507196;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
  flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #335889;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #335889;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 14px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-decade-panel-header a:hover {
  color: #507196;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
  flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #bdc5c9;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #335889;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #335889;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #bdc5c9;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #b1b8bd;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}
.ant-descriptions-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 14px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
}
.ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ' ';
}
.ant-descriptions-item-colon::after {
  content: ':';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-descriptions-item {
  padding-bottom: 0;
}
.ant-descriptions-item > span {
  display: inline-block;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #e8e8e8;
}
.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-color: #e8e8e8;
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top.ant-drawer-open.no-mask,
.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 14px 14px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-drawer-wrapper-body {
  height: 100%;
  overflow: auto;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #335889;
  background-color: #bdc5c9;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #bdc5c9;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #335889;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #335889;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 30px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px;
}
form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  display: inline-block;
  vertical-align: middle;
}
form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
form:not(.ant-form-vertical) .ant-input-group-wrapper {
  position: relative;
  top: -1px;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #faad14;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f5222d;
}
.has-error .ant-input-prefix {
  color: #f5222d;
}
.has-error .ant-input-group-addon {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .has-feedback {
  color: #f5222d;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #f5222d;
}
.has-error .ant-select-selection:hover {
  border-color: #f5222d;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #f5222d;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #f5222d;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #f5222d;
}
.has-error .ant-transfer-list {
  border-color: #f5222d;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #335889;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 5px;
}
.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row + .ant-row::before {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #335889;
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  vertical-align: 0;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-clear-icon + i {
  margin-left: 6px;
}
.ant-input-textarea-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
}
.ant-input-textarea-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-textarea-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-textarea-clear-icon + i {
  margin-left: 6px;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #507196;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 14px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 14px 14px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 14px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 14px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 14px 14px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 14px 0 0 14px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}
.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #335889;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 14px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mentions-wrapper .ant-mentions-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5;
}
.ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-wrapper .ant-mentions-editor:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-mentions-wrapper .ant-mentions-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mentions-wrapper .ant-mentions-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-wrapper .ant-mentions-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mentions-wrapper .ant-mentions-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mentions-wrapper .ant-mentions-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mentions-wrapper.ant-mentions-active:not(.disabled) .ant-mentions-editor {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-mentions-wrapper.disabled .ant-mentions-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper.disabled .ant-mentions-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mentions-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mentions-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item .anticon-loading {
  display: block;
  color: #335889;
  text-align: center;
}
.ant-mentions-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-mentions-dropdown-item:hover {
  background-color: #bdc5c9;
}
.ant-mentions-dropdown-item.focus,
.ant-mentions-dropdown-item-active {
  background-color: #bdc5c9;
}
.ant-mentions-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-selected,
.ant-mentions-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-mentions:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
       tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:read-only {
  cursor: default;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #bdc5c9;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 14px 14px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 14px 14px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #bdc5c9;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #335889;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #bdc5c9;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > a:hover {
  color: #335889;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge > a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > .ant-badge > a:hover {
  color: #335889;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #335889;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #335889;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #335889;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #bdc5c9;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 14px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 14px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background: rgba(0, 0, 0, 0.65) \9;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #335889, #335889);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #335889;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #335889;
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #335889;
  border-bottom: 2px solid #335889;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #335889;
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #335889;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: ' ';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #335889;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #335889;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 14px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 14px 14px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  float: left;
  margin: 8px 0;
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #335889;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #507196;
}
.ant-page-header-back-button:active {
  color: #203b63;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  width: 100%;
  overflow: hidden;
}
.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.ant-page-header-heading .ant-avatar {
  float: left;
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  float: left;
  margin: 5px 0;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-page-header-heading-tags {
  float: left;
  margin: 4px 0;
}
.ant-page-header-heading-extra {
  float: right;
}
.ant-page-header-heading-extra > * {
  margin-left: 8px;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
  overflow: hidden;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px;
  font-size: 16px;
}
@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: block;
    float: unset;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;
  }
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #335889;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #335889;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #335889;
}
.ant-pagination-item-active a {
  color: #335889;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #507196;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #507196;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  color: #335889;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 14px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #507196;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #335889;
  border-color: #335889;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #335889;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 1px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(0, 0, 0, 0.45);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #335889;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(51, 88, 137, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #335889;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #335889;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #335889;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 38px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 14px 0 0 14px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 14px 14px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 14px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #335889;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(51, 88, 137, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #335889;
  background: #fff;
  border-color: #335889;
  box-shadow: -1px 0 0 0 #335889;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #335889 !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #335889;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #507196;
  border-color: #507196;
  box-shadow: -1px 0 0 0 #507196;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #203b63;
  border-color: #203b63;
  box-shadow: -1px 0 0 0 #203b63;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(51, 88, 137, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #335889;
  border-color: #335889;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #507196;
  border-color: #507196;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #203b63;
  border-color: #203b63;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(51, 88, 137, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin-top: 32px;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 14px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 14px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
  padding: 1px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding: 4px 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #bdc5c9;
}
.ant-select-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #bdc5c9;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  transform: translateY(-50%);
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #335889;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #96a4b0;
  border-radius: 14px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #96a4b0;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #5c79a1;
  outline: none;
  box-shadow: 0 0 0 5px rgba(51, 88, 137, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #335889;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #718aa3;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #718aa3;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #99acc4;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #335889;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #335889;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #335889;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #335889;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #335889;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #335889;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #335889;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #335889;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #335889;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #335889;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #335889;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #335889;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #f5222d;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #335889;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #335889;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #335889;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #335889;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 3px;
  background-color: #335889;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none;
  }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    transform: rotate(135deg);
  }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:not(.ant-switch-disabled):active::before,
.ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #335889;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  transform: scale(0.66667);
}
.ant-switch-checked {
  background-color: #335889;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 14px 14px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #335889;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #335889;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 14px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 14px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #bdc5c9;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 14px 14px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 14px 14px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 14px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 14px 14px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 14px 14px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #335889;
  font-weight: bold;
  text-shadow: 0 0 2px #b1b8bd;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #335889;
}
.ant-table-filter-dropdown-link:hover {
  color: #507196;
}
.ant-table-filter-dropdown-link:active {
  color: #203b63;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  color: #335889;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #507196;
}
.ant-table-row-expand-icon:active {
  color: #203b63;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
  color: transparent;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) > * {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
  min-width: unset;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  min-width: inherit;
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 14px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 14px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-content > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 14px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-content > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer {
  background-color: transparent;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer::before {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: transparent;
  border-radius: 14px 14px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th.ant-table-row-cell-last,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 14px 14px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 14px 14px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #335889;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #335889;
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 45px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #335889;
  border-color: #335889;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
  line-height: 56px;
}
.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
  line-height: 37px;
}
.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: 100%;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 14px 0 0 14px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 14px 14px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #335889;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 2px;
  background-color: #335889;
  transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  -webkit-filter: none;
          filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: ' ';
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 2px solid transparent;
  border-radius: 14px 14px 0 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #507196;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #203b63;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #335889;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs-content::before {
  display: block;
  overflow: hidden;
  content: '';
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  opacity: 1;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #335889;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #335889;
}
.ant-tag-checkable:active {
  background-color: #203b63;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  width: 56px;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:focus {
  color: #335889;
  font-weight: 600;
  outline: none;
}
.ant-time-picker-panel-select li:hover {
  background: #bdc5c9;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
li.ant-time-picker-panel-select-option-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  font-weight: inherit;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
  border-color: #507196;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #507196;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(51, 88, 137, 0.2);
}
.ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.5;
    }
  }
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #335889;
  border-color: #335889;
}
.ant-timeline-item-head-red {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-transfer-customize-list {
  display: flex;
}
.ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 24px 0 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 12px 9px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 14px 14px 0 0;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-header .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
.ant-transfer-list-body-with-search {
  padding-top: 56px;
}
.ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content > .LazyLoad {
  -webkit-animation: transferHighlightIn 1s;
          animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item-text {
  padding-left: 8px;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #bdc5c9;
  cursor: pointer;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  transform: translateY(-50%);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 16px;
}
.ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 14px 14px;
}
.ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@-webkit-keyframes transferHighlightIn {
  0% {
    background: #b1b8bd;
  }
  100% {
    background: transparent;
  }
}
@keyframes transferHighlightIn {
  0% {
    background: #b1b8bd;
  }
  100% {
    background: transparent;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #bdc5c9;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #335889;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #335889;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #335889;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #335889;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #335889;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #335889;
  border-color: #335889;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #335889;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #335889;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #335889;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #335889;
}
.ant-tree li.filter-node > span {
  color: #f5222d !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #335889;
  font-size: 14px;
  transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #bdc5c9;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #b1b8bd;
}
.ant-tree li span.ant-tree-checkbox {
  top: auto;
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-iconEle:empty {
  display: none;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(100% - 22px);
  margin: 22px 0 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #335889;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #335889;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #335889;
  border-color: #335889;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #335889;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #bdc5c9;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #b1b8bd;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #335889;
  font-size: 14px;
  transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #335889;
  font-size: 14px;
  transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-typography {
  color: rgba(0, 0, 0, 0.65);
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #f5222d;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4 {
  margin-top: 1.2em;
}
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a {
  color: #335889;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-typography a:focus,
.ant-typography a:hover {
  color: #507196;
}
.ant-typography a:active {
  color: #203b63;
}
.ant-typography a:active,
.ant-typography a:hover {
  text-decoration: none;
}
.ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #335889;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #507196;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #203b63;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul li {
  list-style-type: circle;
}
.ant-typography ul li li {
  list-style-type: disc;
}
.ant-typography ol li {
  list-style-type: decimal;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #335889;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 14px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #203b63;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #507196;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #507196;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
  opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
  top: 25px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #bdc5c9;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #f5222d;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card-container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
          animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
          animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300.76b56857.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/static/media/open-sans-v15-latin-300.76b56857.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300.60c86674.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300.521d17bc.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300.177cc92d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300.505d96bd.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/open-sans-v15-latin-300italic.687596af.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(/static/media/open-sans-v15-latin-300italic.687596af.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-300italic.06bbd318.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-300italic.8a648ff3.woff) format('woff'),  url(/static/media/open-sans-v15-latin-300italic.c03f38da.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-300italic.b5065b4f.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/static/media/open-sans-v15-latin-regular.9dce7f01.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-regular.cffb686d.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-regular.bf2d0783.woff) format('woff'),  url(/static/media/open-sans-v15-latin-regular.c045b73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-regular.ddaaf490.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/static/media/open-sans-v15-latin-italic.9b695c7d.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-italic.987032ea.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-italic.db70d0b9.woff) format('woff'),  url(/static/media/open-sans-v15-latin-italic.f42df73d.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-italic.42762880.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600.7728dd9f.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(/static/media/open-sans-v15-latin-600.7728dd9f.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600.c062d7d1.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600.b1249dd5.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(/static/media/open-sans-v15-latin-600italic.ab4479a4.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-600italic.4950a720.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-600italic.318ea1ad.woff) format('woff'),  url(/static/media/open-sans-v15-latin-600italic.b5d6ad58.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-600italic.adf9d003.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700.148a6749.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/static/media/open-sans-v15-latin-700.148a6749.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700.d08c09f2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700.623e3205.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700.7e08cc65.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700.9f5a7745.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/open-sans-v15-latin-700italic.437e5048.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/static/media/open-sans-v15-latin-700italic.437e5048.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-700italic.c02f5da6.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-700italic.72e19cbb.woff) format('woff'),  url(/static/media/open-sans-v15-latin-700italic.cffddf70.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-700italic.e8ebd85a.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800.dba530a6.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(/static/media/open-sans-v15-latin-800.dba530a6.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800.aaeffaf2.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800.c6aa0c4a.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800.04ee5b60.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800.45c95bf9.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(/static/media/open-sans-v15-latin-800italic.3e6791ec.eot?#iefix) format('embedded-opentype'),  url(/static/media/open-sans-v15-latin-800italic.6b3973ff.woff2) format('woff2'),  url(/static/media/open-sans-v15-latin-800italic.79b58175.woff) format('woff'),  url(/static/media/open-sans-v15-latin-800italic.9855a8fd.ttf) format('truetype'),  url(/static/media/open-sans-v15-latin-800italic.130bf843.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/static/media/lato-v14-latin-100.b859abc8.eot);
  /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'), url(/static/media/lato-v14-latin-100.b859abc8.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-100.f8028e61.woff2) format('woff2'),  url(/static/media/lato-v14-latin-100.752c2022.woff) format('woff'),  url(/static/media/lato-v14-latin-100.4d89307c.ttf) format('truetype'),  url(/static/media/lato-v14-latin-100.66c26530.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/lato-v14-latin-300.fc1bb181.eot);
  /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'), url(/static/media/lato-v14-latin-300.fc1bb181.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-300.bbbd26ae.woff2) format('woff2'),  url(/static/media/lato-v14-latin-300.ba2452bd.woff) format('woff'),  url(/static/media/lato-v14-latin-300.49d84f82.ttf) format('truetype'),  url(/static/media/lato-v14-latin-300.a3fb8786.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/lato-v14-latin-700.5bfc01b7.eot);
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'), url(/static/media/lato-v14-latin-700.5bfc01b7.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-700.7fbbfd16.woff2) format('woff2'),  url(/static/media/lato-v14-latin-700.96759e32.woff) format('woff'),  url(/static/media/lato-v14-latin-700.f467f2d0.ttf) format('truetype'),  url(/static/media/lato-v14-latin-700.7470c61a.svg#Lato) format('svg');
  /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/lato-v14-latin-900.dec6ce78.eot);
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'), url(/static/media/lato-v14-latin-900.dec6ce78.eot?#iefix) format('embedded-opentype'),  url(/static/media/lato-v14-latin-900.f2de2c6e.woff2) format('woff2'),  url(/static/media/lato-v14-latin-900.d79dd066.woff) format('woff'),  url(/static/media/lato-v14-latin-900.281e5e87.ttf) format('truetype'),  url(/static/media/lato-v14-latin-900.c4c24d2a.svg#Lato) format('svg');
  /* Legacy iOS */
}
.openSans-300-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}
.openSans-400-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.openSans-600-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.openSans-700-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}
.openSans-800-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
}
.openSans-900-n {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
.lato-400-n {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.lato-900-n {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
}
.lightGrayBtn {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
}
html,
body {
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 767px) {
  html,
  body {
    overflow-y: scroll;
  }
}
#root {
  height: 100%;
}
#root #ref {
  height: 100%;
}
body {
  font-family: Open Sans, Verdana;
  background: linear-gradient(to right, #f9fbfd, #fafcfd);
  overflow-y: hidden;
}
.ant-tooltip {
  max-width: 600px;
}
.ant-tooltip .ant-tooltip-inner {
  max-width: 600px;
}
.profilePage .ant-tag {
  text-transform: none;
}
p:focus,
div:focus {
  outline: none;
}
@media (max-width: 767px) {
  p,
  div {
    font-size: 14px !important;
  }
}
.containerTags .ant-row {
  margin-bottom: 0;
}
.containerTags .ant-tag {
  white-space: normal;
  /*display: flex;
    flex-direction: column;
    justify-content: space-between;*/
}
@media (max-width: 767px) {
  .profile_custom_tooltip {
    display: none;
  }
}
.checkedTag {
  transition: all 0.5s;
  border: none !important;
  background: #335889 !important;
  color: #f4f6f7 !important;
  padding: 10px 16px !important;
  cursor: pointer !important;
  text-transform: none;
}
.uncheckedTag {
  padding: 10px 16px !important;
  color: #003459 !important;
  border: none !important;
  transition: all 0.5s;
  background: #f4f6f7 !important;
  text-transform: none;
  cursor: pointer !important;
}
.notAdded svg {
  transform: rotateZ(0deg);
  transition: all 1s;
}
.added svg {
  transform: rotateZ(135deg);
  transition: all 0.5s;
  color: #f4f6f7 !important;
}
button {
  text-transform: uppercase;
}
h1 {
  color: #43425d26;
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0px;
}
.logo {
  margin: 24px;
  font-feature-settings: "pcap", "c2pc";
  font-variant-caps: all-petite-caps;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 4px;
  font-weight: normal;
  font-size: large;
  color: white;
}
@media (max-width: 767px) {
  /*
  On small screens, the nav menu spans the full width of the screen.
  Leave a space for it by increasing the padding-top value below.
  */
  body {
    padding-top: 0px;
  }
  body #accountForm div.customCardContainer {
    width: 100%;
  }
  body #accountForm div.customCardContainer .wrapaccount {
    display: block;
  }
  body #accountForm div.customCardContainer .wrapaccount .infoAccount {
    width: 100%;
    margin-top: 0;
  }
  .ant-popover-inner {
    max-width: 400px;
  }
}
@media (max-width: 414px) {
  max-width: 300px;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 !important;
}
.tooltipError .ant-tooltip-inner {
  background-color: #e54d4c;
}
.ant-tooltip-inner {
  min-height: 40px;
  min-width: 144px;
  max-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  text-align: left;
}
.tooltipError .ant-tooltip-arrow {
  border-right-color: #e54d4c !important;
}
.account-form .ant-form-explain,
.change-pwd-form .ant-form-explain,
.login-form .ant-form-explain,
.change-form .ant-form-explain,
.forgot-form .ant-form-explain,
.signup-form .ant-form-explain {
  display: none;
}
.account-form .ant-form-item-with-help,
.change-pwd-form .ant-form-item-with-help,
.login-form .ant-form-item-with-help,
.change-form .ant-form-item-with-help,
.forgot-form .ant-form-item-with-help,
.signup-form .ant-form-item-with-help {
  margin-bottom: 24px !important;
}
.login-form .ant-form-item-with-help:last-child,
.login-form .ant-form-item:nth-child(2) {
  margin-bottom: 0px !important;
}
.forgot-form {
  margin-top: 20px;
}
.addMetricForm {
  position: fixed;
  z-index: 1;
  right: 20px;
  top: 40%;
  cursor: auto;
  cursor: initial;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}
.profilePage .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-select-dropdown-menu-item-disabled {
  color: #8898aa !important;
  font-size: 12px !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  text-transform: uppercase;
}
.ant-select-dropdown-menu-item-disabled:hover {
  cursor: unset !important;
}
.ant-select-dropdown-menu-item {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border-radius: 14px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoUserSelect .ant-select-dropdown-menu-item {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
}
.autocompleteSymptomChecker ul,
.autocompleteEncyclopedia ul {
  max-height: calc(100vh - 255px);
  overflow-y: scroll;
}
@media print {
  button,
  aside,
  .ant-layout-sider {
    display: none !important;
  }
  .ant-layout {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
    page-break-after: always !important;
  }
}
.ant-tag {
  background-color: transparent;
  border-color: #003459;
  color: #003459;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  /*text-transform: capitalize;*/
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .ant-tag {
    font-size: 14px;
  }
}
.ant-tag svg {
  font-size: 16px;
  color: #003459;
  margin-left: 5px;
}
.ant-select-selection-selected-value {
  font-weight: 600;
}
.ant-select {
  width: 100%;
}
.ant-select svg {
  font-weight: 600;
  color: #003459;
}
.ant-drawer-mask {
  opacity: 0.8 !important;
  background-color: #000000;
  -webkit-animation: customAntdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
          animation: customAntdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
}
@-webkit-keyframes customAntdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes customAntdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
.dropDownShareMenu {
  padding: 0;
}
.dropDownShareMenu li:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dropDownShareMenu li:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sisuWrapper label,
.profilePage label,
.wrapperModalChangePwd label {
  color: #003459 !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 767px) {
  .sisuWrapper label,
  .profilePage label,
  .wrapperModalChangePwd label {
    font-size: 14px;
  }
}
.sisuWrapper input,
.profilePage input,
.wrapperModalChangePwd input {
  height: 50px !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459 !important;
}
@media (max-width: 767px) {
  .sisuWrapper input,
  .profilePage input,
  .wrapperModalChangePwd input {
    height: 40px !important;
    font-size: 14px !important;
  }
}
@media (max-width: 767px) {
  .sisuWrapper .ant-form-item,
  .profilePage .ant-form-item,
  .wrapperModalChangePwd .ant-form-item {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .sisuWrapper .ant-select-selection-selected-value,
  .profilePage .ant-select-selection-selected-value,
  .wrapperModalChangePwd .ant-select-selection-selected-value {
    font-size: 14px !important;
  }
}
.sisuWrapper input {
  margin-top: -3px;
}
@media (min-width: 767px) {
  .sisuWrapper input {
    height: 58px !important;
  }
}
@media (max-width: 767px) {
  .ant-select-dropdown-menu-item {
    font-size: 14px !important;
    height: 44px;
  }
}
#profileBasicInformationForm .ant-input-number {
  height: 50px !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459 !important;
}
#profileBasicInformationForm label .ant-form-item-label {
  line-height: 1.2;
  margin-bottom: 10px;
}
#accountForm .ant-select-selection--single,
.profileBasicInformationForm .ant-select-selection--single {
  height: 50px;
  padding-top: 10px;
}
@media (max-width: 767px) {
  #accountForm .ant-select-selection--single,
  .profileBasicInformationForm .ant-select-selection--single {
    height: 40px;
    padding-top: 5px;
  }
}
.profilePage .therapiesCollapse input {
  height: 27px !important;
  font-size: 20px !important;
  padding: 0 !important;
  border: none;
  border-radius: 7px;
  max-width: 225px;
}
.profilePage .therapiesCollapse .doseMed input {
  font-size: 16px !important;
}
.profilePage .therapiesCollapse .inputNewCondition {
  font-size: 16px !important;
}
.colorPickerDropdown ul {
  max-height: 702px;
}
.colorPickerDropdown li {
  border-radius: 0;
}
.colorPickerSelect {
  height: 50px;
}
@media (max-width: 767px) {
  .colorPickerSelect {
    height: 40px;
  }
}
.colorPickerSelect > div {
  height: 50px;
  background: transparent;
  border: unset;
}
@media (max-width: 767px) {
  .colorPickerSelect > div {
    height: 40px;
  }
}
.colorPickerSelect div {
  color: white;
}
.colorPickerSelect .ant-select-selection-selected-value i {
  display: none;
}
.ant-calendar-input-wrap {
  display: none;
}
.ant-calendar {
  margin-left: -10px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
@media (max-heigth: 767px) {
  .colorPickerDropdown ul {
    max-height: 400px !important;
  }
}
.metricInfo > div {
  height: 40px;
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 40px 10px 0;
  border-radius: 14px;
  align-items: center;
  display: inline-flex;
  background-color: #f4f6f7;
  border: none;
  width: auto;
  min-width: 230px;
  width: 100%;
}
.metricInfo > div > div {
  width: 100%;
}
.metricInfo > div > div > div {
  display: flex !important;
  align-items: center;
  max-width: 100%;
}
.metricInfo > div > div > div span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 19px);
}
.metricInfoDropdown .ant-select-dropdown-menu-item {
  justify-content: flex-start;
}
.tabsChart.ant-tabs.ant-tabs-top .ant-tabs-top-bar .ant-tabs-tab-active {
  font-weight: 700 !important;
}
.tabsChart.ant-tabs.ant-tabs-card .ant-tabs-nav {
  width: 100%;
}
.tabsChart.ant-tabs.ant-tabs-top .ant-tabs-top-bar .ant-tabs-tab {
  width: calc(50% - 2px);
  text-align: center;
  font-size: 14px;
}
.recharts-legend-item {
  display: flex !important;
  align-items: center;
}
.recharts-legend-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 19px);
}
.profilePage .customCardContainer {
  box-shadow: none;
}
.profilePage .ProfileBasicInformation > .customCardContainer,
.profilePage .ProfileSettings > .customCardContainer,
.profilePage .ProfileGenetics > .customCardContainer,
.profilePage .ProfileConditionsAndTherapies > .customCardContainer,
.profilePage .ProfileFamilyHistory > .customCardContainer,
.profilePage .ProfileChronicConditions > .customCardContainer,
.profilePage .ProfileTherapyAndVaccines > .customCardContainer,
.profilePage .Activity > .customCardContainer {
  width: 100%;
  background: #f0f2f5 !important;
  border: none !important;
  padding: 0 !important;
}
.profilePage .ProfileBasicInformation > .customCardContainer .customCardContainer,
.profilePage .ProfileSettings > .customCardContainer .customCardContainer,
.profilePage .ProfileGenetics > .customCardContainer .customCardContainer,
.profilePage .ProfileConditionsAndTherapies > .customCardContainer .customCardContainer,
.profilePage .ProfileFamilyHistory > .customCardContainer .customCardContainer,
.profilePage .ProfileChronicConditions > .customCardContainer .customCardContainer,
.profilePage .ProfileTherapyAndVaccines > .customCardContainer .customCardContainer,
.profilePage .Activity > .customCardContainer .customCardContainer {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: 25px;
}
.profilePage .ProfileBasicInformation > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileSettings > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileGenetics > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileConditionsAndTherapies > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileFamilyHistory > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileChronicConditions > .customCardContainer .customCardContainer:last-child,
.profilePage .ProfileTherapyAndVaccines > .customCardContainer .customCardContainer:last-child,
.profilePage .Activity > .customCardContainer .customCardContainer:last-child {
  margin-bottom: 0;
}
.profilePage .ProfileBasicInformation > .customCardContainer > div,
.profilePage .ProfileSettings > .customCardContainer > div,
.profilePage .ProfileGenetics > .customCardContainer > div,
.profilePage .ProfileConditionsAndTherapies > .customCardContainer > div,
.profilePage .ProfileFamilyHistory > .customCardContainer > div,
.profilePage .ProfileChronicConditions > .customCardContainer > div,
.profilePage .ProfileTherapyAndVaccines > .customCardContainer > div,
.profilePage .Activity > .customCardContainer > div {
  padding: 0;
}
.profilePage .ProfileBasicInformation .wrappAccountActivity,
.profilePage .ProfileSettings .wrappAccountActivity,
.profilePage .ProfileGenetics .wrappAccountActivity,
.profilePage .ProfileConditionsAndTherapies .wrappAccountActivity,
.profilePage .ProfileFamilyHistory .wrappAccountActivity,
.profilePage .ProfileChronicConditions .wrappAccountActivity,
.profilePage .ProfileTherapyAndVaccines .wrappAccountActivity,
.profilePage .Activity .wrappAccountActivity {
  width: 100%;
  padding: 0;
}
.profilePage .ant-tabs-tab {
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8898aa !important;
}
.profilePage .ant-tabs-tab-active.ant-tabs-tab {
  color: #003459 !important;
}
.profilePage .ant-select-selection i.anticon {
  margin-right: 10px;
}
.languages-select .ant-select-dropdown-menu-item {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: left;
}
.languages-select .ant-select-dropdown-menu-item:first-child:after {
  content: "";
  width: 97%;
  height: 1px;
  background: #d9d9d9;
  position: absolute;
  left: 10px;
  bottom: 0;
}
.languages-select .ant-select-dropdown-menu-item i {
  margin-right: 12px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .mainProfileBasicInformation .monthInput {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .mainProfileBasicInformation .monthInput .ant-select-dropdown {
    width: 140px !important;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .mainProfileBasicInformation .ageDayInput {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .mainProfileBasicInformation .ageDayInput .ant-select-dropdown {
    width: 55px !important;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .mainProfileBasicInformation .YearInput {
    width: 50%;
  }
}
.mainProfileBasicInformation .YearInput .ant-select-dropdown {
  left: auto !important;
  right: 0;
}
@media (max-width: 767px) {
  .mainProfileBasicInformation .ant-select-arrow {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .mainProfileBasicInformation .ant-select-selection__rendered {
    margin-left: 9px;
  }
}
.metricsTimeLineSection .ant-empty-normal {
  margin: 15px 0;
}
.metricsTimeLineSection .no-data-table.detailViewTable .ant-table-scroll {
  overflow: auto !important;
  overflow-x: hidden !important;
}
.metricsTimeLineSection .no-data-table.detailViewTable .ant-table-header {
  overflow: auto !important;
}
.metricsTimeLineSection .no-data-table.detailViewTable thead tr th {
  padding: 5px 15px;
}
.profileAvatarIcon {
  background-color: #f4f6f7;
  border-radius: 50%;
  max-width: 150px;
  width: 100%;
  height: 150px;
  background-size: cover;
  margin: 0 auto;
}
@media (max-width: 1250px) {
  .profileAvatarIcon {
    max-width: 140px;
    height: 140px;
  }
}
@media (max-width: 767px) {
  .profileAvatarIcon {
    max-width: 100px;
    height: 100px;
  }
}
.profileAvatarIcon svg {
  max-width: 150px !important;
  width: 100%;
  height: 100% !important;
}
@media (max-width: 767px) {
  .profileAvatarIcon svg {
    max-width: 100px important;
  }
}
.ant-upload-drag-icon {
  margin-bottom: 20px;
  max-width: 150px;
  max-height: 150px;
  margin: auto;
}
/* admin tool upload */
.adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: flex;
  align-items: center;
  padding: 13.5px 20px;
}
@media (max-width: 500px) {
  .adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container {
    padding: 13.5px 15px;
    display: block;
  }
}
.adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container i.anticon-plus {
  width: 84px;
  height: 105px;
  background: #fff;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #F4F6F7;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 500px) {
  .adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container i.anticon-plus {
    margin: auto;
  }
}
.adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container i.anticon-plus svg {
  font-size: 24px;
  color: #75838B;
  margin-bottom: 0;
}
.adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-drag-icon.mainadminToolItemImage {
  width: auto;
  margin: 0;
}
@media (max-width: 500px) {
  .adminTooluploadBlock .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-drag-icon.mainadminToolItemImage {
    margin: auto;
  }
}
.ant-upload-drag-container .ant-progress-text {
  display: none;
}
.ant-upload-list-item .ant-progress-text,
.ant-upload-list-item i {
  display: none !important;
}
.ant-upload-list-item .ant-upload-list-item-info {
  display: none !important;
}
.ant-upload-list-item .ant-upload-list-item-progress {
  padding-left: 0 !important;
}
.ant-upload-list-item .ant-upload-list-item-progress .ant-progress-bg {
  background-color: #52c41a !important;
}
.ant-upload-list-item.ant-upload-list-item-error {
  display: none !important;
}
#accountForm div.customCardContainer {
  width: 100%;
}
#accountForm div.customCardContainer .wrapaccount {
  display: flex;
}
#accountForm div.customCardContainer .wrapaccount .infoAccount {
  width: 70%;
  margin-top: -15px;
}
.cardLabelProfileSetting {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.cardHeaderProfile {
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.has-error .ant-form-explain {
  padding-left: 5px;
}
.therapiesCollapse .ant-collapse-item-disabled > div {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: default !important;
}
.conditionsSelect > div > div {
  border: none;
  padding-left: 10px;
}
.conditionsSelect > div > div > div {
  margin: 0;
}
.ReactCrop__crop-selection {
  border-radius: 50%;
}
.adminToolCropper .ReactCrop__crop-selection {
  border-radius: 0%;
}
.profile-cropper .ReactCrop__image {
  max-height: 300px !important;
}
.profile-cropper .ReactCrop {
  border-radius: 14px;
}
.wrapperModal .ant-modal-header .ant-modal-title {
  color: #003459;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  /*text-transform: capitalize;*/
}
.wrapperModalChangePwd .ant-modal-body {
  padding: 0;
}
.change-pwd-form {
  display: block;
  padding: 0 !important;
}
.change-pwd-form > div:first-child {
  padding: 24px !important;
}
.change-pwd-form > div:nth-child(2) {
  width: 100% !important;
}
.infoUserSelect {
  width: auto !important;
}
.infoUserSelect .ant-select-dropdown-menu {
  max-height: unset;
}
/* sss */
/*new design*/
.uploadSection .ant-upload-drag {
  height: auto;
  background-color: transparent;
  background: transparent;
}
.uploadSection .ant-upload-drag span.ant-upload-btn {
  padding: 0;
}
.uploadSection .ant-upload-list .ant-upload-list-item {
  height: auto;
  padding: 0 0;
}
.uploadSection .ant-upload-list .ant-upload-list-item .ant-upload-list-item-info {
  display: block !important;
  padding: 9px 10px;
}
.uploadSection .ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-list-item-name {
  font-size: 14px;
}
.uploadSection .ant-upload-list .ant-upload-list-item i {
  display: block !important;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}
.uploadSection .ant-upload-list .ant-upload-list-item i.anticon-close {
  right: 10px;
}
.uploadSection .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #335889;
}
.uploadSection .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-name {
  color: #fff;
}
.uploadSection .ant-upload-list .ant-upload-list-item:hover i {
  color: #fff;
}
.uploadSection .ant-upload > input:focus + .uploadContent {
  border-color: #335889;
}
.mainUploadSection {
  display: flex;
}
.mainUploadSection .uploadBox {
  width: 100%;
}
@media (max-width: 767px) {
  .mainUploadSection {
    display: block;
  }
}
.mainUploadSection .uploadContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  /*border: 1px dashed #d9d9d9;
        border-radius: 14px;*/
  padding: 13.5px 20px;
  position: relative;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
}
.mainUploadSection .uploadContent .ant-btn {
  width: 84px;
  height: 105px;
  background: #fff;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #F4F6F7;
  padding: 0;
  display: block;
}
.mainUploadSection .uploadContent .ant-btn i {
  font-size: 24px;
  color: #75838B;
  margin-bottom: 0;
  display: block;
}
.mainUploadSection .uploadContent .ant-btn i + span {
  display: none;
}
.mainUploadSection .uploadContent .ant-btn .uploadText {
  font-size: 14px;
  color: #75838B;
  font-weight: 100;
  /*text-transform: capitalize;*/
}
.mainUploadSection .uploadContent p {
  padding: 0 30px;
  margin: 0 0;
  line-height: normal;
  font-weight: 100;
  color: #75838B;
  width: calc(100% - 84px);
}
@media (max-width: 1199px) {
  .mainUploadSection .uploadContent p {
    padding: 0 15px 0 30px;
  }
}
@media (max-width: 992px) {
  .mainUploadSection .uploadContent p {
    padding: 0 0 0 15px;
  }
}
.mainUploadSection .generateBtn {
  width: auto;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .mainUploadSection .generateBtn {
    margin-left: 0;
    margin-top: 15px;
  }
}
.mainUploadSection .generateBtn button.ant-btn.ant-btn-primary {
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 15px;
}
@media (max-width: 767px) {
  .mainUploadSection .generateBtn button.ant-btn.ant-btn-primary {
    height: 35px;
    font-size: 12px;
  }
}
.adminToolMobileView {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.adminToolMobileView .adminToolMobileView_head {
  position: relative;
  margin-bottom: 10px;
}
.adminToolMobileView .adminToolMobileView_head label span.ant-checkbox.ant-checkbox-indeterminate span.ant-checkbox-inner:after {
  content: "";
  left: 51%;
  display: block;
}
.adminToolMobileView .adminToolMobileView_head label .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.adminToolMobileView .adminToolMobileView_head label .ant-checkbox-inner:after {
  left: 31%;
  display: block;
}
.adminToolMobileView .adminToolMobileView_head > span {
  color: #75838b;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .adminToolMobileView .adminToolMobileView_head > span {
    margin-left: 0;
  }
}
.adminToolMobileView .adminToolMobileView_head i {
  font-size: 26px;
  transform: rotate(90deg);
  color: #75838b;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.adminToolMobileView h4 {
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.adminToolMobileView p {
  color: #75838b;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  word-break: break-all;
}
.adminToolMobileView p.responsiveLanguage {
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #003459;
}
.adminToolMobileView p.responsiveLanguage svg {
  width: 20px;
}
.documentDetails {
  padding-top: 60px;
}
@media (max-width: 767px) {
  .documentDetails {
    padding-top: 30px;
  }
}
.documentDetails .adminToolsdocumentTabs .documentTabs .ant-spin-container {
  justify-content: space-between;
}
@media (max-width: 414px) {
  .documentDetails .adminToolsdocumentTabs .documentTabs li.ant-list-item {
    margin: 0 5px;
  }
}
@media (max-width: 414px) {
  .documentDetails .adminToolsdocumentTabs .documentTabs li.ant-list-item span {
    font-size: 12px;
  }
}
.documentDetails .documentTabs .ant-spin-container {
  display: flex;
}
.documentDetails .documentTabs li.ant-list-item {
  border: none !important;
  display: inline-block;
  margin: 0 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .documentDetails .documentTabs li.ant-list-item {
    margin: 0 10px;
  }
}
.documentDetails .documentTabs li.ant-list-item span {
  font-weight: 700;
  font-size: 16px;
  /*color: #6D7278;*/
  color: #75838B;
}
@media (max-width: 767px) {
  .documentDetails .documentTabs li.ant-list-item span {
    font-size: 14px;
  }
}
.documentDetails .documentTabs li.ant-list-item span.active {
  color: #003459;
}
.documentDetails .searchBox {
  margin: 10px 0;
}
@media (max-width: 767px) {
  .documentDetails .searchBox {
    margin: 10px 0 20px;
  }
}
.documentDetails .searchBox input {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.documentDetails .searchBox input.ant-input {
  width: 100%;
  height: 54px;
  background: #fff;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.0956465);
  border: none;
  padding: 0 15px 0 40px;
}
@media (max-width: 767px) {
  .documentDetails .searchBox input.ant-input {
    height: 44px;
    font-size: 14px !important;
  }
}
.documentDetails .searchBox input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.documentDetails .searchBox input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.documentDetails .searchBox input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.documentDetails .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
  font-size: 20px;
  color: #8898aa !important;
}
@media (max-width: 767px) {
  .documentDetails .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
    font-size: 18px;
  }
}
.documentDetails .searchBox .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: unset;
}
.documentDetails .userDetailsTable {
  overflow-x: auto;
  padding: 0 2.5px;
}
.documentDetails .userDetailsTable table {
  padding: 0 5px;
}
.documentDetails .userDetailsTable span.ant-checkbox.ant-checkbox-indeterminate span.ant-checkbox-inner:after {
  content: "";
  left: 51%;
  display: block;
}
.documentDetails .userDetailsTable .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.documentDetails .userDetailsTable .ant-checkbox-inner:after {
  left: 31%;
  display: block;
}
.documentDetails .userDetailsTable .ant-table-tbody > tr td .date {
  white-space: nowrap;
}
.documentDetails .userDetailsTable .ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fff;
}
.documentDetails .userDetailsTable .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fff;
}
.documentDetails .userDetailsTable table {
  border-collapse: separate;
  border-spacing: 0px 20px;
}
.documentDetails .userDetailsTable thead tr th {
  background: transparent;
  padding: 0 15px;
  border: none;
}
.documentDetails .userDetailsTable thead tr th span {
  font-size: 12px;
  color: #8898AA;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
.documentDetails .userDetailsTable thead tr th span.ant-checkbox {
  width: 24px;
  height: 24px;
}
.documentDetails .userDetailsTable thead tr th span i.anticon {
  color: #003459;
  margin-left: 4px;
}
.documentDetails .userDetailsTable tbody tr {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
}
.documentDetails .userDetailsTable tbody tr td {
  background: #fff;
  padding: 15px;
  border: none;
  color: #75838B;
  font-size: 14px;
  position: relative;
  font-weight: 400;
}
.documentDetails .userDetailsTable tbody tr td:first-child {
  border-radius: 14px 0 0 14px;
}
.documentDetails .userDetailsTable tbody tr td:last-child {
  border-radius: 0 14px 14px 0;
}
.documentDetails .userDetailsTable tbody tr td span.nameContent {
  display: inline-block;
  padding: 0 40px 0 0;
}
.documentDetails .userDetailsTable tbody tr td span h4 {
  color: #003459;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}
.documentDetails .userDetailsTable tbody tr td span h4.no-cursor {
  cursor: auto;
  cursor: initial;
}
@media (max-width: 767px) {
  .documentDetails .userDetailsTable tbody tr td span h4 {
    font-size: 16px;
  }
}
.documentDetails .userDetailsTable tbody tr td span p {
  color: #75838B;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.documentDetails .userDetailsTable tbody tr td span i.anticon-more {
  font-size: 26px;
  transform: rotate(90deg);
  color: #75838B;
  cursor: pointer;
}
.documentDetails .userDetailsTable tbody tr td .view_delete_popup {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
/* admin tool */
.tabsection .ant-tabs {
  overflow: visible;
  overflow: initial;
}
.adminToolDetails {
  padding-top: 24px;
}
.adminToolDetails .adminToolcardHeader {
  /* background: #fff; */
  padding: 10px 15px;
  margin: 0;
  border-radius: 4px;
  color: #8898aa;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.adminToolDetails .userDetailsTable thead tr th span {
  white-space: nowrap;
}
.adminToolDetails .userDetailsTable tbody tr td .title_description {
  display: inline-block;
  padding: 0 45px 0 0;
}
.adminToolDetails .userDetailsTable tbody tr td span.date_time {
  white-space: nowrap;
}
.adminToolDetails .userDetailsTable tbody tr td span.language {
  text-transform: uppercase;
  color: #003459;
  display: flex;
  height: 15px;
  align-items: center;
}
.adminToolDetails .userDetailsTable tbody tr td span.language svg {
  width: 20px;
}
.adminToolDetails .userDetailsTable tbody tr td span.title {
  color: #003459;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  white-space: normal;
}
@media (max-width: 1199px) {
  .adminToolDetails .userDetailsTable tbody tr td span.title {
    white-space: normal;
  }
}
.adminToolDetails .userDetailsTable tbody tr td span.content_description {
  display: block;
  color: #75838b;
  font-size: 14px;
}
.adminToolDetails .documentTabs .ant-spin-container {
  display: flex;
}
.adminToolDetails .documentTabs li.ant-list-item {
  border: none !important;
  display: inline-block;
  margin: 0 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .adminToolDetails .documentTabs li.ant-list-item {
    margin: 0 10px;
  }
}
.adminToolDetails .documentTabs li.ant-list-item span {
  font-weight: 700;
  font-size: 16px;
  /*color: #6D7278;*/
  color: #75838B;
}
@media (max-width: 767px) {
  .adminToolDetails .documentTabs li.ant-list-item span {
    font-size: 14px;
  }
}
.adminToolDetails .documentTabs li.ant-list-item span.active {
  color: #003459;
}
.adminToolDetails .searchBox {
  margin: 10px 0;
}
@media (max-width: 767px) {
  .adminToolDetails .searchBox {
    margin: 10px 0 20px;
  }
}
.adminToolDetails .searchBox input {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.adminToolDetails .searchBox input.ant-input {
  width: 100%;
  height: 54px;
  background: #fff;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.0956465);
  border: none;
  padding: 0 15px 0 40px;
}
@media (max-width: 767px) {
  .adminToolDetails .searchBox input.ant-input {
    height: 44px;
    font-size: 14px !important;
  }
}
.adminToolDetails .searchBox input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.adminToolDetails .searchBox input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.adminToolDetails .searchBox input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.adminToolDetails .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
  font-size: 20px;
  color: #8898aa !important;
}
@media (max-width: 767px) {
  .adminToolDetails .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
    font-size: 18px;
  }
}
.adminToolDetails .searchBox .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: unset;
}
.adminToolDetails .userDetailsTable span.ant-checkbox.ant-checkbox-indeterminate span.ant-checkbox-inner:after {
  content: "";
  left: 51%;
  display: block;
}
.adminToolDetails .userDetailsTable .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.adminToolDetails .userDetailsTable .ant-checkbox-inner:after {
  left: 31%;
  display: block;
}
.adminToolDetails .userDetailsTable .ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fff;
}
.adminToolDetails .userDetailsTable .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fff;
}
.adminToolDetails .userDetailsTable table {
  border-collapse: separate;
  border-spacing: 0px 20px;
}
@media (max-width: 479px) {
  .adminToolDetails .userDetailsTable table {
    overflow: hidden;
  }
}
.adminToolDetails .userDetailsTable thead tr th {
  background: transparent;
  padding: 0 15px;
  border: none;
}
.adminToolDetails .userDetailsTable thead tr th.head_title_description {
  width: 100%;
}
.adminToolDetails .userDetailsTable thead tr th span {
  font-size: 12px;
  color: #8898AA;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
.adminToolDetails .userDetailsTable thead tr th span.ant-checkbox {
  width: 24px;
  height: 24px;
}
.adminToolDetails .userDetailsTable thead tr th span i.anticon {
  color: #003459;
  margin-left: 4px;
}
.adminToolDetails .userDetailsTable tbody tr {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
}
.adminToolDetails .userDetailsTable tbody tr td {
  background: #fff;
  padding: 15px;
  border: none;
  color: #75838B;
  font-size: 14px;
  position: relative;
  font-weight: 400;
}
.adminToolDetails .userDetailsTable tbody tr td:first-child {
  border-radius: 14px 0 0 14px;
}
.adminToolDetails .userDetailsTable tbody tr td:last-child {
  border-radius: 0 14px 14px 0;
}
.adminToolDetails .userDetailsTable tbody tr td span.nameContent {
  display: inline-block;
  padding: 0 40px 0 0;
}
.adminToolDetails .userDetailsTable tbody tr td span h4 {
  color: #003459;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}
.adminToolDetails .userDetailsTable tbody tr td span h4.no-cursor {
  cursor: auto;
  cursor: initial;
}
@media (max-width: 767px) {
  .adminToolDetails .userDetailsTable tbody tr td span h4 {
    font-size: 16px;
  }
}
.adminToolDetails .userDetailsTable tbody tr td span p {
  color: #75838B;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.adminToolDetails .userDetailsTable tbody tr td span i.anticon-more {
  font-size: 26px;
  transform: rotate(90deg);
  color: #75838B;
  cursor: pointer;
}
.adminToolDetails .userDetailsTable tbody tr td .delete_popup {
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}
/* sss */
.ant-dropdown .viewDeleteDropDown.adminToolDropDown li {
  border-radius: 10px !important;
}
/*Document view edit drop-down css*/
.ant-dropdown .viewDeleteDropDown {
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-dropdown .viewDeleteDropDown li {
  padding: 5px 20px;
  border-radius: 10px;
  margin: 0;
}
.viewDeleteDropDown li:hover {
  background-color: #335889;
  color: #fff;
}
.viewDeleteDropDown li:hover a,
.viewDeleteDropDown li:hover span,
.viewDeleteDropDown li:hover i {
  color: #fff;
}
.viewDeleteDropDown li a,
.viewDeleteDropDown li span {
  color: #003459;
}
.viewDeleteDropDown li a i,
.viewDeleteDropDown li span i {
  padding: 0 5px 0 0;
  color: #003459;
}
.ant-dropdown .viewDeleteDropDown li:first-child {
  border-radius: 10px 10px 0px 0px;
  margin: 0;
  height: auto;
  line-height: 2;
}
.ant-dropdown .viewDeleteDropDown li:last-child {
  border-radius: 0px 0px 10px 10px;
  margin: 0;
  height: auto;
  line-height: 2;
}
.uploadDocumentModalForm .ant-select-selection__placeholder,
.uploadDocumentModalForm .ant-select-selection-selected-value,
.uploadDocumentModalForm input,
.uploadDocumentModalForm textarea,
.uploadDocumentModalForm label {
  font-size: 16px !important;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  .uploadDocumentModalForm .ant-select-selection__placeholder,
  .uploadDocumentModalForm .ant-select-selection-selected-value,
  .uploadDocumentModalForm input,
  .uploadDocumentModalForm textarea,
  .uploadDocumentModalForm label {
    font-size: 14px !important;
  }
}
.uploadDocumentModalForm input,
.uploadDocumentModalForm .ant-select-selection--single {
  height: 50px;
  background-color: transparent;
  box-shadow: none;
}
@media (max-width: 767px) {
  .uploadDocumentModalForm input,
  .uploadDocumentModalForm .ant-select-selection--single {
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
.uploadDocumentModalForm .ant-select {
  border-radius: 15px;
}
.uploadDocumentModalForm p.fileSize-text {
  text-align: right;
  padding: 0 10px 0 0;
  margin: -20px 0 0;
  font-size: 12px !important;
  color: #75838B;
}
.uploadDocumentModalForm .ant-select-selection__rendered {
  line-height: 48px;
}
@media (max-width: 767px) {
  .uploadDocumentModalForm .ant-select-selection__rendered {
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  .uploadDocumentModalForm .ant-form-item {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .uploadDocumentModalForm .ant-form-item.name_feild {
    margin-bottom: 22px;
  }
}
.uploadDocumentModalForm textarea {
  color: #003459;
}
.uploadDocumentModalForm .ant-calendar-picker {
  width: 100%;
}
.selectItem {
  border-radius: 0;
}
@media (max-width: 767px) {
  .ant-btn {
    height: 32px;
    font-size: 12px;
  }
}
.read-more-collapse .ant-collapse {
  border: none;
  background: none;
}
.read-more-collapse .ant-collapse .ant-collapse-item {
  border-radius: 0;
  border: none;
}
.read-more-collapse .ant-collapse .ant-collapse-item .ant-collapse-header {
  border-radius: 0;
  padding: 0 0 0 20px;
  color: #68BE68;
}
.read-more-collapse .ant-collapse .ant-collapse-item .ant-collapse-header i {
  left: 0;
}
.read-more-collapse .ant-collapse .ant-collapse-item .ant-collapse-content {
  border-radius: 0;
  border: none;
  padding-top: 10px;
}
.read-more-collapse .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
li.ant-select-dropdown-menu-item {
  white-space: inherit;
}
.newsAndAlertsPage .ant-tabs {
  overflow: visible;
}
.newsAndAlertsPage .ant-tabs .ant-tabs-tab {
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8898aa !important;
  padding: 0 !important;
  min-width: 80px;
}
.newsAndAlertsPage .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  color: #003459 !important;
}
.newsAndAlertsPage .count {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #335889;
  color: #fff;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  margin-left: 8px;
}
@media (max-width: 767px) {
  .newsAndAlertsPage .count {
    width: 26px;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
  }
}
.newsAndAlertsPage .searchBox {
  margin: 10px 0;
}
.newsAndAlertsPage .searchBox input {
  color: #003459 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}
.newsAndAlertsPage .searchBox input.ant-input {
  width: 100%;
  height: 54px;
  background: #fff;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);*/
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.0956465);
  border: none;
  padding: 0 15px 0 40px;
}
@media (max-width: 767px) {
  .newsAndAlertsPage .searchBox input.ant-input {
    height: 44px;
    font-size: 14px !important;
  }
}
.newsAndAlertsPage .searchBox input:focus {
  box-shadow: 0 0 0 1.5px rgba(51, 88, 137, 0.7);
}
.newsAndAlertsPage .searchBox input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.newsAndAlertsPage .searchBox input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8898aa !important;
}
.newsAndAlertsPage .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
  font-size: 20px;
  color: #8898aa !important;
}
@media (max-width: 767px) {
  .newsAndAlertsPage .searchBox i.anticon.anticon-search.ant-input-search-icon svg {
    font-size: 18px;
  }
}
.newsAndAlertsPage .searchBox .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: unset;
}
@media (max-width: 767px) {
  .metricsTimeLineChartSection {
    margin-top: 15px;
    font-size: 16px !important;
  }
}
.metricsTimeLineChartSection .ant-card-body {
  padding: 24px;
  zoom: 1;
}
@media (max-width: 767px) {
  .metricsTimeLineChartSection .ant-card-body {
    padding: 24px 10px;
  }
}
.detailViewTable {
  /* metrics table scroll css */
}
.detailViewTable .ant-table-placeholder {
  background-color: transparent;
  border: 0;
}
.detailViewTable .ant-table {
  overflow: auto !important;
}
.detailViewTable .ant-table-scroll {
  overflow: unset !important;
  overflow-x: unset !important;
}
.detailViewTable .ant-table-header {
  overflow: unset !important;
}
.detailViewTable .ant-table-body {
  overflow: unset !important;
  margin: 0;
}
.no-data-table.detailViewTable .ant-table-placeholder {
  background-color: transparent;
}
.no-data-table.detailViewTable .ant-table-header {
  overflow: auto !important;
}
.no-data-table.detailViewTable thead tr th {
  padding: 0 15px 0;
}
.metricsTimeLineSection {
  margin-top: -24px;
}
.metricsTimeLineSection .detailViewTable .ant-table-fixed-header .ant-table-content .ant-table-scroll .ant-table-body {
  background: transparent;
  position: relative;
}
.metricsTimeLineSection .detailViewTable span.ant-checkbox.ant-checkbox-indeterminate span.ant-checkbox-inner:after {
  content: "";
  left: 51%;
  display: block;
}
.metricsTimeLineSection .detailViewTable .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.metricsTimeLineSection .detailViewTable .ant-checkbox-inner:after {
  left: 31%;
  display: block;
}
.metricsTimeLineSection .detailViewTable .ant-table-body {
  margin-top: 15px;
  /*@media (max-width: 767px) {
        margin-top: 0;
      }*/
}
.metricsTimeLineSection .detailViewTable .ant-table-row:hover .editable-row {
  display: none;
  background-color: transparent;
}
.metricsTimeLineSection .detailViewTable .ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background-color: transparent;
  padding: 0px 15px;
}
.metricsTimeLineSection .detailViewTable .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: transparent;
}
.metricsTimeLineSection .detailViewTable table {
  border-collapse: separate;
  border-spacing: 0;
  padding: 0 5px;
}
.metricsTimeLineSection .detailViewTable thead tr th {
  padding: 3.5px 15px 3.5px;
  border: none;
}
.metricsTimeLineSection .detailViewTable thead tr th span {
  font-size: 12px;
  color: #8898AA;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
.metricsTimeLineSection .detailViewTable thead tr th span.ant-checkbox {
  width: 24px;
  height: 24px;
}
.metricsTimeLineSection .detailViewTable thead tr th span i.anticon {
  color: #003459;
  margin-left: 4px;
}
.metricsTimeLineSection .detailViewTable thead tr th:last-child {
  width: 60px;
}
@media (max-width: 767px) {
  .metricsTimeLineSection .detailViewTable tbody tr {
    box-shadow: none;
  }
}
.metricsTimeLineSection .detailViewTable tbody tr td {
  padding: 5px 15px;
  border: none;
  color: #75838B;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  white-space: normal;
  vertical-align: top;
}
@media (max-width: 767px) {
  .metricsTimeLineSection .detailViewTable tbody tr td:first-child {
    border-radius: 0;
  }
}
.metricsTimeLineSection .detailViewTable tbody tr td:last-child {
  width: 60px;
}
@media (max-width: 1400px) {
  .metricsTimeLineSection .detailViewTable tbody tr td:last-child {
    /*display: inline-block;*/
    padding: 9px 0;
  }
}
.metricsTimeLineSection .detailViewTable tbody tr td span.nameContent {
  display: inline-block;
  padding: 0 35px 0 0;
}
.metricsTimeLineSection .detailViewTable tbody tr td span h4 {
  color: #003459;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}
.metricsTimeLineSection .detailViewTable tbody tr td span h4.no-cursor {
  cursor: auto;
  cursor: initial;
}
.metricsTimeLineSection .detailViewTable tbody tr td span p {
  color: #75838B;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.metricsTimeLineSection .detailViewTable tbody tr td span i.anticon-more {
  position: absolute;
  right: 20px;
  height: 20px;
  top: calc(125px - 62.5px);
  font-size: 26px;
  transform: rotate(90deg);
  color: #75838B;
  cursor: pointer;
}
.metricsTimeLineSection .detailViewTable .editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
.metricsTimeLineSection .detailViewTable .editable-cell-value-wrap {
  cursor: pointer;
  /*display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;*/
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.metricsTimeLineSection .detailViewTable .editable-row:hover .editable-cell-value-wrap {
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 30px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border: 1px solid #507196;
  border-radius: 14px;
  transition: all 0.3s;
}
.metricsTimeLineSection .detailViewTable .ant-row::before {
  display: table;
  content: none;
}
.metricsTimeLineSection .detailViewTable .ant-row::after {
  display: table;
  content: none;
}
.metricsTimeLineSection .detailViewTable .ant-form-item-control::before {
  display: table;
  content: none;
}
.metricsTimeLineSection .detailViewTable .ant-form-item-control::after {
  display: table;
  content: none;
}
.metricsTimeLineSection .action-btn {
  border-radius: 12px;
  background-color: #f4f6f7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
  border: none;
  height: 40px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  padding: 0;
  box-shadow: unset;
  background-color: transparent;
}
.metricsChartSection .ant-card-body {
  padding: 24px;
  zoom: 1;
}
@media (max-width: 767px) {
  .metricsChartSection .ant-card-body {
    padding: 24px 10px;
  }
}
.metricsTabs .ant-tabs {
  overflow: visible;
}
.metricsTabs .ant-tabs .ant-tabs-tab {
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8898aa !important;
  padding: 0 16px !important;
  min-width: 80px;
}
.metricsTabs .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  color: #003459 !important;
}
.metrics-ant-tooltip-inner {
  max-width: 600px;
  display: flex;
  justify-content: left;
  align-items: left;
  border-radius: 6px;
  text-align: left;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}
.metrics-ant-tooltip-inner p {
  color: #fff;
}
.metrics-ant-tooltip-inner div {
  color: #fff;
}
.autocompleteEncyclopedia {
  max-width: 900px;
  border-radius: 14px;
  overflow: hidden;
  max-height: calc(100vh - 255px);
}
@media (max-width: 1270px) {
  .autocompleteEncyclopedia {
    max-width: calc(100% - 365px);
  }
}
.autocompleteEncyclopedia .ant-select-dropdown-menu-item > span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.autocompleteEncyclopedia .ant-select-dropdown-menu-item:first-child {
  border-radius: 14px 14px 0 0;
}
.symptomInfoBlock .ant-row {
  width: 100%;
}
.metricsNewChartDrawer .ant-drawer-content-wrapper,
.metricsNewEntryDrawer .ant-drawer-content-wrapper,
.drawerComponentSymptomChecker .ant-drawer-content-wrapper,
.metricsNewEntryDrawer .ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: 485px;
}
@media (max-width: 767px) {
  .metricsNewChartDrawer .ant-drawer-content-wrapper,
  .metricsNewEntryDrawer .ant-drawer-content-wrapper,
  .drawerComponentSymptomChecker .ant-drawer-content-wrapper,
  .metricsNewEntryDrawer .ant-drawer-content-wrapper {
    max-width: 320px;
  }
}
.metricSetting .ant-drawer-wrapper-body {
  overflow: hidden !important;
}
.metricSetting .ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: 400px;
}
@media (max-width: 767px) {
  .metricSetting .ant-drawer-content-wrapper {
    max-width: 320px;
  }
}
@media (max-width: 767px) {
  .metricsNewEntryDrawer .MainDatePickerDateContainer {
    display: block;
  }
}
@media (max-width: 767px) {
  .metricsNewEntryDrawer .MainDatePickerDateContainer .InnerDatePickerDateContainer {
    width: 100%;
  }
}
.metricsNewEntryDrawer span.punctuation_mark {
  margin: 0 5px;
}
@media (max-width: 767px) {
  .nameRemovemetricInfoBlock {
    display: block;
  }
}
@media (max-width: 767px) {
  .profilePage .ant-tabs-tab {
    font-size: 14px;
    padding: 0 10px !important;
  }
}
@media (max-width: 767px) {
  .responsiveswitcherContainer {
    margin-right: 35px;
  }
}
@media (max-width: 345px) {
  .responsiveswitcherContainer {
    margin-right: 25px;
  }
}
.MetricsTimeLineChartSection,
.customCardContainer {
  /*.ant-card-head-title {
    @media (max-width: 767px) { 
      width: 100%;
      display: block;
    }
  }
  .ant-card-extra {
    @media (max-width: 767px) { 
      width: 100%;
      display: block;
    }
  }*/
  /*.recharts-legend-wrapper {
    @media (max-width: 767px) { 
      left: 0;
    }
  }*/
}
@media (max-width: 767px) {
  .MetricsTimeLineChartSection .ant-card-extra,
  .customCardContainer .ant-card-extra {
    margin-top: 30px;
  }
}
.MetricsTimeLineChartSection .ant-card-extra .cardHeaderTitle,
.customCardContainer .ant-card-extra .cardHeaderTitle {
  max-width: calc(100% - 28px) !important;
  font-size: 12px !important;
}
@media (max-width: 767px) {
  .MetricsTimeLineChartSection .ant-card-head,
  .customCardContainer .ant-card-head {
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .MetricsTimeLineChartSection .ant-card-head-wrapper li,
  .customCardContainer .ant-card-head-wrapper li {
    margin-right: 5px !important;
  }
}
.MetricsTimeLineChartSection .ant-card-head-wrapper li .ant-menu-submenu-title,
.customCardContainer .ant-card-head-wrapper li .ant-menu-submenu-title {
  padding: 0 5px 0 0 !important;
}
@media (max-width: 767px) {
  .responsiveHeader.ant-layout-header {
    background: #003459 !important;
    border-bottom: 1px solid #e8e8e8;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .responsiveHeader .innerResponsiveHeader {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 5px 15px;
  }
}
@media (max-width: 767px) {
  .responsiveHeader .innerResponsiveHeader h1 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .responsiveHeader .innerResponsiveHeader a {
    display: flex;
  }
}
@media (max-width: 767px) {
  .responsiveFooter.ant-layout-footer {
    background: #f6f9fc !important;
    border-top: 1px solid #e8e8e8;
    padding: 0;
    height: 64px;
    line-height: 64px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
}
@media (max-width: 767px) {
  .responsiveFooter .innerResponsiveFooter {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .responsiveFooter .innerResponsiveFooter a {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }
}
@media (max-width: 767px) {
  .responsiveFooter .innerResponsiveFooter a i {
    font-size: 26px;
    height: 26px;
    opacity: 0.6;
  }
}
.ant-message {
  z-index: 999999 !important;
}
.ant-dropdown.ant-dropdown-placement-bottomCenter {
  z-index: 999;
}
@media (max-width: 767px) {
  .ant-dropdown.ant-dropdown-placement-bottomRight {
    z-index: 999;
  }
}
@media (max-width: 767px) {
  .ant-calendar-picker-container-placement-bottomLeft {
    left: 32px !important;
  }
}
.main_layout_main_inner {
  height: 100vh;
}
@media (max-width: 767px) {
  .main_layout_main_inner {
    overflow: auto;
    height: calc(100% - 128px);
    padding: 24px 20px 70px;
    -webkit-overflow-scrolling: touch;
  }
}
.video-embed-field-responsive-video iframe {
  width: 100%;
  height: 25vw;
  max-width: 44.479vw;
}
@media (max-width: 767px) {
  .video-embed-field-responsive-video iframe {
    width: 100%;
    height: 50vw;
    max-width: 100%;
  }
}
.maincardWrapperNewMetric {
  height: calc(100vh - 150px);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 24px;
}
@media (max-width: 767px) {
  .maincardWrapperNewMetric {
    -webkit-overflow-scrolling: touch;
  }
}
.color_mertic_setting {
  height: calc(100vh - 150px);
  overflow: auto;
  padding: 0 15px;
  -webkit-overflow-scrolling: touch;
}
.drawerComponentSymptomChecker .ant-drawer-wrapper-body {
  overflow: hidden !important;
}
@media (max-width: 767px) {
  .drawerComponentSymptomChecker .ant-drawer-body {
    padding: 0;
  }
}
.accordion-header-secondary {
  outline: none;
}
.documentMobileView {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.documentMobileView .documentMobileView_head {
  position: relative;
  margin-bottom: 10px;
}
.documentMobileView .documentMobileView_head label span.ant-checkbox.ant-checkbox-indeterminate span.ant-checkbox-inner:after {
  content: "";
  left: 51%;
  display: block;
}
.documentMobileView .documentMobileView_head label .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.documentMobileView .documentMobileView_head label .ant-checkbox-inner:after {
  left: 31%;
  display: block;
}
.documentMobileView .documentMobileView_head > span {
  color: #75838b;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .documentMobileView .documentMobileView_head > span {
    margin-left: 0;
  }
}
.documentMobileView .documentMobileView_head i {
  font-size: 26px;
  transform: rotate(90deg);
  color: #75838b;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.documentMobileView .documentMobileView_content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.documentMobileView .documentMobileView_content .typeBox {
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  width: 84px;
  padding: 7px;
  height: 115px;
  position: relative;
  margin-right: 15px;
  text-align: center;
}
.documentMobileView .documentMobileView_content .typeBox svg {
  width: 60px;
  height: 60px;
  margin-top: 7px;
}
.documentMobileView .documentMobileView_content .typeBox span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 7px 7px;
  margin: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-weight: 500;
  line-height: 1;
  min-height: 22px;
}
.documentMobileView .fileText {
  width: calc(100% - 104px);
  word-break: break-all;
}
.documentMobileView .fileText h4 {
  color: #003459;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
  display: inline-block;
  word-break: break-all;
}
.documentMobileView .fileText p {
  color: #75838b;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.documentMobileView .fileText p.responsiveLanguage {
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #003459;
}
/* responsive add entry button */
.desktopViewMenuDash {
  display: inline-block !important;
}
@media (max-width: 767px) {
  .desktopViewMenuDash {
    display: none !important;
  }
}
.mobileViewMenuDash {
  display: none !important;
}
@media (max-width: 767px) {
  .mobileViewMenuDash {
    display: inline-block !important;
  }
}
.mainselectedBlock {
  padding-bottom: 0 !important;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
@media (max-width: 767px) {
  .HideMobileTitle {
    display: none;
  }
}
.mainHomeSection {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .mainHomeSection {
    display: block;
  }
}
.mainHomeSection.notLogin .MainhomeRight {
  display: none;
}
.mainHomeSection .MainhomeRight {
  width: 371px;
  max-width: 371px;
}
@media (max-width: 1320px) {
  .mainHomeSection .MainhomeRight {
    width: 100%;
    max-width: 235px;
  }
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight .homeRight {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-left: 20px;
}
@media (max-width: 1320px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow {
    padding: 0;
    justify-content: flex-start;
  }
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
  padding: 0 10px;
  margin-bottom: 20px;
  width: 33.33%;
}
@media (min-width: 1920px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
    width: 25%;
    max-width: 256px;
  }
}
@media (max-width: 1610px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
    width: 50%;
  }
}
@media (max-width: 1450px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
    width: 33.33%;
  }
}
@media (max-width: 480px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid {
    width: 50%;
  }
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid .customCardContainer {
  width: 100%;
  height: auto;
  padding: 10px 10px 2px 10px;
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid .customCardContainer .ant-card-head {
  padding: 0;
  min-height: 0px;
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid .customCardContainer .ant-card-head .ant-card-head-title {
  padding: 0;
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid .customCardContainer .ant-card-head .ant-card-head-title h1 {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 16px;
  color: #003459;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainHomeSection .MainhomeRight .homeRight .homeRightRow .HomeGraphGrid .customCardContainer .ant-card-body {
  padding: 0;
}
.mainHomeSection .MainhomeRight .homeRight .homeRightButton {
  padding: 0 25px;
}
@media (max-width: 1320px) {
  .mainHomeSection .MainhomeRight .homeRight .homeRightButton {
    text-align: center;
  }
}
.mainHomeSection .MainhomeLeft {
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeLeft .searchBox {
    display: none;
  }
}
.EncyclopediaEntryCustomCard .ant-card-body {
  padding: 10px 25px;
}
.AboutCustomCard .ant-tabs-bar {
  border: none;
}
.AboutCustomCard .ant-tabs-bar .ant-tabs-nav-container {
  height: 40px;
}
.AboutCustomCard .ant-tabs-nav-wrap {
  margin: 0;
}
.AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-tab {
  margin: 0 2px 0 0;
  padding: 0 16px;
  height: 40px;
  line-height: 38px;
}
@media (max-width: 767px) {
  .AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-tab {
    padding: 0 10px;
  }
}
.AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-tab span {
  margin-bottom: 0;
}
.AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-tab span a {
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8898aa !important;
}
.AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-tab span.tabActive a {
  color: #003459 !important;
}
.AboutCustomCard .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  display: none !important;
}
.AboutCustomCard .ant-menu {
  margin: 0 0 16px 0;
  border-bottom: none;
  overflow: hidden;
  background: transparent;
  line-height: 39px;
  display: flex;
  height: auto;
  border: none;
  flex-wrap: wrap;
}
.AboutCustomCard .ant-menu li {
  border: none !important;
  padding: 0 16px !important;
  top: 0;
  margin-top: 0;
  width: auto;
  background: transparent !important;
  white-space: normal;
  display: inline-block;
  height: auto;
  line-height: 39px;
  margin-bottom: 0;
}
.AboutCustomCard .ant-menu li:after {
  display: none;
}
.AboutCustomCard .ant-menu li a {
  font-weight: 700;
  color: #8898aa;
  font-size: 16px;
}
@media (max-width: 767px) {
  .AboutCustomCard .ant-menu li a {
    font-size: 14px;
  }
}
.AboutCustomCard .ant-menu li a span {
  margin-bottom: 0;
}
.AboutCustomCard .ant-menu li:hover a {
  color: #8898aa;
}
.AboutCustomCard .ant-menu .ant-menu-item-selected {
  border: none !important;
  margin-right: 2px;
  margin-bottom: 0;
}
.AboutCustomCard .ant-menu .ant-menu-item-selected a {
  color: #003459;
}
.AboutCustomCard .ant-menu .ant-menu-item-selected:hover a {
  color: #003459;
}
.AboutCustomCard .ant-card-body {
  padding: 10px 25px;
}
.AboutCustomCard h1 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
  color: #003459;
}
@media (max-width: 767px) {
  .AboutCustomCard h1 {
    margin-top: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}
.AboutCustomCard h2 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.AboutCustomCard h3 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #1b88d6;
}
.AboutCustomCard h4 {
  font-family: Open Sans, Verdana;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #8898aa;
}
.AboutCustomCard p {
  font-family: Open Sans, Verdana;
  font-size: 14px !important;
  margin-top: 0;
  margin-bottom: 10px;
}
.AboutCustomCard li {
  font-family: Open Sans, Verdana;
  font-size: 14px;
  margin-top: 0;
}
.AboutCustomCard table caption {
  color: #888;
  font-size: smaller;
  font-style: italic;
  padding: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.AboutCustomCard table,
.AboutCustomCard th,
.AboutCustomCard td {
  border: 1px solid #ddd;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
}
.AboutCustomCard table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.AboutCustomCard table tr:hover {
  background-color: #ddd;
}
.AboutCustomCard table thead th {
  padding-top: 12px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #003459;
  color: white;
}
.AboutCustomCard table tfoot th {
  text-align: left;
  border-top: 4px solid #ddd;
  background-color: #fff;
}
.adminToolItemImage {
  width: 125px;
}
@media (max-width: 500px) {
  .adminToolItemImage {
    margin: auto;
  }
}
.adminToolItemImage img {
  width: 100%;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}
.maindragWrapper + .ant-upload-list.ant-upload-list-text {
  display: none;
}
@media (max-width: 767px) {
  .ant-upload-hint.desktopViewLabel {
    display: none;
  }
}
.react-loading-center {
  margin: 0 auto;
}
.adminToolForm textarea {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #003459;
}
.home-container .ant-btn-primary:focus {
  background-color: transparent;
  border-color: transparent;
  color: #003459;
}
.home-container .ant-btn-primary:hover {
  color: #fff;
  background-color: #507196;
  border-color: #507196;
}
.emailError {
  background-color: #e54d4c;
  color: #fff;
  border-radius: 6px;
  padding: 6px 8px;
  margin: -17px 0 24px 0;
}
@media (max-width: 767px) {
  .emailError {
    margin: -12px 0 15px 0;
  }
}
.ant-tooltip.tooltipError.ant-tooltip-placement-bottom {
  left: 0 !important;
  right: 0 !important;
  margin: 30px auto 0;
  max-width: 485px;
  padding-top: 18px;
}
@media (max-width: 767px) {
  .ant-tooltip.tooltipError.ant-tooltip-placement-bottom {
    left: 15px !important;
    right: 15px !important;
  }
}
.ant-tooltip.tooltipError.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 15px;
  top: 5.07106781px;
}
.tooltipError .ant-tooltip-inner span {
  white-space: pre-line;
}
.ant-select-dropdown {
  overflow: hidden;
}
.withoutLogin {
  opacity: 0.5;
}
.AdminToolsAnalyticsSection {
  display: flex;
}
@media (max-width: 767px) {
  .AdminToolsAnalyticsSection {
    flex-wrap: wrap;
  }
}
.AdminToolsAnalyticsSection .AdminToolsAnalyticsLeft {
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .AdminToolsAnalyticsSection .AdminToolsAnalyticsLeft {
    order: 1;
  }
}
.AdminToolsAnalyticsSection .mainChart {
  width: 100%;
  max-width: 199px;
  margin: 0 0 0 20px;
}
@media (max-width: 1320px) {
  .AdminToolsAnalyticsSection .mainChart {
    width: 100%;
    max-width: 200px;
  }
}
@media (max-width: 767px) {
  .AdminToolsAnalyticsSection .mainChart {
    max-width: 100%;
    order: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px;
    justify-content: space-between;
  }
}
.AdminToolsAnalyticsSection .mainChart .customCardContainer {
  padding: 0 0 0 !important;
  margin-bottom: 20px;
  width: 100%;
  margin: 0 0 20px;
  border-radius: 14px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.0956465);
  border: 1px solid #e8e8e8 !important;
}
@media (max-width: 767px) {
  .AdminToolsAnalyticsSection .mainChart .customCardContainer {
    width: 31.33%;
    margin: 0 0 20px;
    padding: 0 10px !important;
  }
}
@media (max-width: 480px) {
  .AdminToolsAnalyticsSection .mainChart .customCardContainer {
    width: 48%;
  }
}
@media (max-width: 360px) {
  .AdminToolsAnalyticsSection .mainChart .customCardContainer {
    width: 100%;
  }
}
.AdminToolsAnalyticsSection .mainChart .customCardContainer .ant-card-head {
  margin-bottom: 0;
  padding: 0px 10px;
  min-height: auto;
}
.AdminToolsAnalyticsSection .mainChart .customCardContainer .ant-card-head .ant-card-head-title {
  padding: 10px 0 0;
}
.AdminToolsAnalyticsSection .mainChart .customCardContainer .ant-card-head h1 {
  color: #003459;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.AdminToolsAnalyticsSection .mainChart .customCardContainer .ant-card-body {
  padding: 0px 10px 0;
}
.adminToolTags span.ant-form-item-children {
  display: flex;
  flex-wrap: wrap;
}
.adminToolTags span.ant-form-item-children .ant-tag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 767px) {
  .adminToolTags span.ant-form-item-children .ant-tag {
    width: 230px;
  }
}
@media (max-width: 767px) {
  .adminToolTags span.ant-form-item-children .ant-tag {
    width: 100%;
  }
}
.BasicInfoSecRow > div:nth-child(4) {
  width: 190px;
  display: inline-block;
}
@media (max-width: 767px) {
  .BasicInfoSecRow > div:nth-child(4) {
    width: 50%;
  }
}
.BasicInfoSecRow > div:nth-child(5) {
  width: 190px;
  display: inline-block;
}
@media (max-width: 767px) {
  .BasicInfoSecRow > div:nth-child(5) {
    width: 50%;
  }
}
.BasicInfoSecRow > div:nth-child(5) li.ant-select-dropdown-menu-item {
  word-break: break-all;
}
.bloodPressureDiastolicInput-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.bloodPressureDiastolicInput-main .bloodPressureDiastolicInput {
  width: 47%;
}
.bloodPressureDiastolicInput-main .bloodPressureDiastolicInput input {
  background-color: #f4f6f7;
  height: 90px;
  border-radius: 14px;
  font-weight: bold;
  margin-top: 20px;
  font-size: 40px;
  border: none;
}
.bloodPressureDiastolicInput-main.active {
  display: block;
}
.bloodPressureDiastolicInput-main.active .bloodPressureDiastolicInput {
  width: 100%;
}
.bloodPressureDiastolicInput-main.active .bloodPressureDiastolicInput input {
  margin-top: 0;
}
.ant-statistic-title {
  display: inline-block;
}
.customCardContainer {
  margin-bottom: 30px;
}
.languageDivider {
  margin-left: 4px;
  height: 25px;
  top: -4px;
  background: #d9d9d9;
}
.stress_buttons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .stress_buttons {
    margin-top: 18px;
  }
}
.stress_buttons .stress_button {
  padding: 0 3px;
  border-radius: 0;
  box-shadow: none;
  border: none;
  width: 20%;
}
.stress_buttons .stress_button:after {
  display: none;
}
.stress_buttons .stress_button img {
  max-width: 100%;
  display: block;
  margin: auto;
}
.stress_buttons .stress_button img.active {
  display: none;
}
.stress_buttons .stress_button:hover img,
.stress_buttons .stress_button.active img {
  display: none;
}
.stress_buttons .stress_button:hover img.active,
.stress_buttons .stress_button.active img.active {
  display: block;
}
.weight_units {
  padding-left: 20px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .weight_units:first-child {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .weight_units {
    width: 50%;
    padding: 0 5px;
    margin-bottom: 10px;
  }
}
.weight_units h2 {
  font-size: 22px;
  color: #003459;
  font-weight: 700;
  margin: 0;
}
.weight_units .weight_units_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px 0;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row {
    margin: 0;
  }
}
.weight_units .weight_units_row .weight_units_col {
  width: 100%;
  padding: 0 15px;
}
@media (max-width: 1580px) {
  .weight_units .weight_units_row .weight_units_col {
    width: 100%;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .weight_units .weight_units_row .weight_units_col {
    margin-bottom: 0;
    padding: 0;
  }
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  padding: 10px;
  max-width: 100%;
  height: 155px;
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.0956465);
}
@media (max-width: 767px) {
  .weight_units .weight_units_row .weight_units_col .weight_initial_state {
    max-width: 100%;
    height: 135px;
  }
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer {
  margin-bottom: 0;
  border: none;
  background: #fff !important;
  box-shadow: none;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-title {
  padding: 0;
  min-height: auto;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-title h1 {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #003459;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head {
  padding: 0;
  min-height: auto;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-body {
  padding: 0;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-body .weight_form_button {
  float: right;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-body .weight_form_button {
    margin-top: 2px;
    height: 30px;
    font-size: 12px;
    padding: 0 10px;
  }
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state h4 {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #003459;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state p {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  margin: 0;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form {
  display: block;
  margin-top: 25px;
  text-align: right;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 15px;
  }
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form .ant-input-affix-wrapper {
  display: flex;
  align-items: center;
  background: #f4f6f7;
  border-radius: 14px;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form .ant-input {
  background: transparent;
  border: none;
  border-radius: 14px;
  padding: 2px 45px 2px 10px;
  height: 40px;
  border: 1px solid #d9d9d9;
  color: inherit !important;
  z-index: 12;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form .ant-input:focus {
  border-color: inherit;
  box-shadow: none;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form .ant-input-suffix {
  right: 10px;
  font-size: 16px;
  color: #d9d9d9;
  font-weight: bold;
  z-index: 1;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form_button {
  margin-top: 10px;
  margin-left: auto;
  font-size: 15px;
  font-weight: 600;
}
.weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form_button:disabled {
  color: #fff !important;
  background-color: #E1E6E9 !important;
  cursor: not-allowed !important;
  border-color: #E1E6E9 !important;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row .weight_units_col .weight_initial_state .weight_form_button {
    height: 30px;
    padding: 0 10px;
    margin-top: 10px;
  }
}
.weight_units .weight_units_row .weight_units_col.heartrate_col .weight_initial_state .customCardContainer .ant-card-body .weight_form_button {
  background-color: #E54D4C;
  border-color: #E54D4C;
}
.weight_units .weight_units_row .weight_units_col.heartrate_col .weight_initial_state .customCardContainer .ant-card-head-title h1 {
  color: #E54D4C;
}
.weight_units .weight_units_row .weight_units_col.heartrate_col .weight_initial_state h4 {
  color: #E54D4C;
}
.weight_units .weight_units_row .weight_units_col.heartrate_col .weight_initial_state .weight_form .weight_form_button:enabled {
  background-color: #E54D4C;
  border-color: #E54D4C;
}
@media (max-width: 767px) {
  .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall,
  .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall .recharts-wrapper,
  .mainHomeSection .MainhomeRight .homeRight #ChartAreaContainer__ChartAreaSmall .recharts-wrapper svg {
    height: 90px !important;
  }
}
.covidChartContaint {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .covidChartContaint {
    margin-top: 10px;
  }
}
.covidChart {
  width: 70%;
  height: 70px;
}
.covidChartDetail {
  width: 30%;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state {
  height: 225px;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state {
    height: 220px;
  }
}
.covidChartDetail .covidChartDetailBox span {
  font-size: 12px;
  line-height: 1;
}
.covidChartDetail .covidChartDetailBox h2 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: inherit;
}
.covidChartDetail .covidChartDetailBox:nth-child(1) {
  color: #E54E4C;
}
.covidChartDetail .covidChartDetailBox:nth-child(2) {
  color: #68BE68;
}
.covidChartDetail .covidChartDetailBox:nth-child(3) {
  color: #595959;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper {
  flex-wrap: wrap;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra {
  width: 50%;
  padding: 0;
}
@media (max-width: 767px) {
  .weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra {
    margin: 0;
  }
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-head-title {
  width: 50%;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  border-radius: 0;
  border: none;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection .ant-select-selection-selected-value {
  font-size: 14px;
  line-height: 1;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection .ant-select-selection__rendered {
  line-height: 1;
}
.weight_units .weight_units_row.covid_row .weight_units_col .weight_initial_state .customCardContainer .ant-card-head-wrapper .ant-card-extra .ant-select-selection:focus {
  box-shadow: none;
}
.covidChartContainer .covidChartInput input.ant-input {
  background: #f4f6f7 !important;
  border-radius: 0px;
  padding: 2px 30px 2px 10px!important;
  height: 40px !important;
  border: none;
  color: inherit !important;
  z-index: 12;
  box-shadow: none;
  font-size: 14px !important;
}
.covidChartContainer .covidChartInput .ant-input-affix-wrapper {
  display: flex;
  align-items: center;
  border-radius: 14px;
}
.covidChartContainer .covidChartInput .ant-input-suffix {
  z-index: 123;
  right: 10px;
}
.covidChart svg {
  width: 135%;
  height: 135%;
  margin: -11% 0% 0% -15%;
}
@media (min-width: 1920px) {
  .covidChart svg {
    width: 125%;
    height: 125%;
  }
}
@media (max-width: 767px) {
  .covidChart svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
.itemState {
  pointer-events: none;
  color: gray;
}
.coronaCountryDropDown ul li:first-child {
  border-bottom: 1px dashed #003459;
}
.coronaCountryDropDown ul {
  width: calc(100% - -15px);
}
.autocompleteEncyclopedia ul li .stateName .itemState {
  position: relative;
  padding-left: 6px;
  margin-left: 3px;
}
.autocompleteEncyclopedia ul li .stateName .itemState:before {
  content: "";
  width: 3px;
  height: 3px;
  background: gray;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}
.covidChartInput {
  background: #f4f6f7 !important;
  border-radius: 14px;
  height: 40px !important;
  border: 1px solid #d9d9d9;
  color: inherit !important;
  z-index: 12;
  box-shadow: none;
  font-size: 14px !important;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
}
.covidChartInput svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 123;
}
.coronaCountryDropDown > div {
  border: 1px solid #003459;
  border-radius: 14px;
  overflow: hidden !important;
}
.coronaCountryDropDown {
  max-width: 100% !important;
}
.covidChartInput .selectedInput {
  background: #f4f6f7;
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  z-index: 123;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}
.covidChartInput .selectedInput.active {
  display: flex;
}
.covidChartInput .selectedInput span {
  position: relative;
  padding-left: 6px;
  margin-left: 3px;
  color: gray;
}
.covidChartInput .selectedInput span:before {
  content: "";
  width: 3px;
  height: 3px;
  background: gray;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}
.covidChartInput .selectedInput.active + .ant-select input.ant-input {
  opacity: 0;
}

