@import '../../styles/_variables.less';

.header {
    font-size: 32px;
    color: @textPrimary;
    margin-bottom: 24px;
    margin-top: 24px;
    /*text-transform: capitalize;*/
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    @media (max-width: 767px) { 
        font-size: 24px;
        margin-bottom: 8px;
        margin-top: 0;
        display: none;
    }
}

.alertList {
    max-width: 100%;
    .alertListItem {
        border: 1px solid #000;
        padding: 25px;
        border-radius: 14px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 15px;
        @media (max-width: 767px) { 
            display: block;
        }
        .alertImg {
            max-width: 242px;
            margin-right: 24px;
            @media (max-width: 767px) { 
                max-width: 100%;
                margin-right: 0;
                margin-top: 15px;
            }
            img {
                max-width: 100%;
                width: 242px;
                height: 234px;
                object-fit: cover;
                border-radius: 14px;
                @media (max-width: 767px) { 
                    width: 100%;
                    height: auto;
                }
            }
        }
        .alertContent {
            flex: 1;
            @media (max-width: 767px) { 
                margin-top: 15px;
            }
            .closeBtn {
                padding: 0;
                line-height: 1;
                height: auto;
                border: none;
                background: transparent;
                box-shadow: none;
                color: #707070;
                position: absolute;
                right: 20px;
                top: 20px;
                .closeBtnIcon {
                    display: block;
                }
            }
            > span {
                    display: block;
                    font-size: 14px;
                    line-height: 19px;
                    color: #75838B;
                    margin-bottom: 5px;
            }
            .title {
                display: block;
                font-size: 22px;
                line-height: 32px;
                font-weight: bold;
                margin-bottom: 17px;
                @media (max-width: 767px) { 
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }
            }
            .dec {
                margin-bottom: 14px;
                font-size: 16px;
                line-height: 26px;
                color: #335889;
            }
            .scheduleBtn {
                display: block;
                font-size: 12px;
                line-height: 20px;
                color: #fff;
                &:hover {
                    opacity: 0.9;
                }
                @media (max-width: 767px) { 
                    height: 35px;
                }
            }
        }
        &.blueAlert {
            background: #EBF3FF;
            border-color: #335889;
            .alertContent {
                .title {
                        color: #335889;
                }
                .scheduleBtn {
                        background: #335889;
                        border-color: #335889;
                }
            }
        }
        &.redAlert {
            border-color: #E54D4C;
            background-color: #F7EDED;
            .alertContent {
                .title {
                        color: #E54D4C;
                }
                .scheduleBtn {
                        background: #E54D4C;
                        border-color: #E54D4C;
                }
            }
        }
    }
}
@card-radius: 16px;@primary-color: #335889;@border-radius-base: 14px;@btn-font-weight: 700;@btn-height-base: 40px;